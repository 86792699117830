/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminAddMember = /* GraphQL */ `
  mutation AdminAddMember($organizationID: ID!, $sub: ID!) {
    adminAddMember(organizationID: $organizationID, sub: $sub)
  }
`;
export const adminRemoveMember = /* GraphQL */ `
  mutation AdminRemoveMember($organizationID: ID!, $sub: ID!) {
    adminRemoveMember(organizationID: $organizationID, sub: $sub)
  }
`;
export const createAccessRequest = /* GraphQL */ `
  mutation CreateAccessRequest(
    $input: CreateAccessRequestInput!
    $condition: ModelAccessRequestConditionInput
  ) {
    createAccessRequest(input: $input, condition: $condition) {
      id
      itemId
      organizationID
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateAccessRequest = /* GraphQL */ `
  mutation UpdateAccessRequest(
    $input: UpdateAccessRequestInput!
    $condition: ModelAccessRequestConditionInput
  ) {
    updateAccessRequest(input: $input, condition: $condition) {
      id
      itemId
      organizationID
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccessRequest = /* GraphQL */ `
  mutation DeleteAccessRequest(
    $input: DeleteAccessRequestInput!
    $condition: ModelAccessRequestConditionInput
  ) {
    deleteAccessRequest(input: $input, condition: $condition) {
      id
      itemId
      organizationID
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) {
      emailAddresses
      id
      groups
      name
      templateID
      integrationTypeID
      integrationType {
        id
        metadata {
          logo
          logoSquare
          name
        }
        status
        type
      }
      memoq_apiKey
      memoq_creatorUserID
      memoq_webServiceAPIAccessPoint
      memsource_accessToken
      memsource_clientId
      memsource_domain
      memsource_secretToken
      vendorID
      organizationID
      trados_accountId
      trados_languageCloudAccountId
      trados_clientId
      trados_clientSecret
      trados_webhookSecret
      textmaster_apiKey
      textmaster_apiSecret
      plunet_baseUrl
      plunet_username
      plunet_password
      protemos_apiKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateIntegration = /* GraphQL */ `
  mutation UpdateIntegration(
    $input: UpdateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    updateIntegration(input: $input, condition: $condition) {
      emailAddresses
      id
      groups
      name
      templateID
      integrationTypeID
      integrationType {
        id
        metadata {
          logo
          logoSquare
          name
        }
        status
        type
      }
      memoq_apiKey
      memoq_creatorUserID
      memoq_webServiceAPIAccessPoint
      memsource_accessToken
      memsource_clientId
      memsource_domain
      memsource_secretToken
      vendorID
      organizationID
      trados_accountId
      trados_languageCloudAccountId
      trados_clientId
      trados_clientSecret
      trados_webhookSecret
      textmaster_apiKey
      textmaster_apiSecret
      protemos_apiKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
      groups
      name
      templateID
      integrationTypeID
      integrationType {
        id
        status
        type
        createdAt
        updatedAt
      }
      memoq_apiKey
      memoq_creatorUserID
      memoq_webServiceAPIAccessPoint
      memsource_accessToken
      memsource_clientId
      memsource_domain
      memsource_secretToken
      vendorID
      organizationID
      trados_accountId
      trados_languageCloudAccountId
      trados_clientId
      trados_clientSecret
      trados_webhookSecret
      textmaster_apiKey
      textmaster_apiSecret
      protemos_apiKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      connectors {
        azurerepos {
          refreshToken
        }
        bitbucket {
          clientKey
        }
        contentful {
          environmentIDs
          spaceID
          spaceName
        }
        github {
          installationIDs
        }
        gitlab {
          accessToken
        }
        googledocs {
          refreshToken
          userId
        }
        googlesheets {
          refreshToken
          userId
        }
        hubspotcms {
          hubDomain
          hubId
          refreshToken
        }
        intercom {
          accessToken
          workspaceId
        }
        salesforceknowledge {
          accessToken
          instanceUrl
          refreshToken
        }
        shopify {
          accessToken
          storeId
        }
        mailchimp {
          accessToken
          accountId
          accountName
          serverId
        }
        zendesk {
          accessToken
          domain
        }
      }
      customerID
      groups
      id
      integrationID
      name
      onboarding {
        isCompleted
        stage
        type
      }
      owner
      type
      integrationTemplateID
      vendorID
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      connectors {
        azurerepos {
          refreshToken
        }
        bitbucket {
          clientKey
        }
        contentful {
          environmentIDs
          spaceID
          spaceName
        }
        github {
          installationIDs
        }
        gitlab {
          accessToken
        }
        googledocs {
          refreshToken
          userId
        }
        googlesheets {
          refreshToken
          userId
        }
        hubspotcms {
          hubDomain
          hubId
          refreshToken
        }
        intercom {
          accessToken
          workspaceId
        }
        salesforceknowledge {
          accessToken
          instanceUrl
          refreshToken
        }
        shopify {
          accessToken
          storeId
        }
        mailchimp {
          accessToken
          accountId
          accountName
          serverId
        }
        zendesk {
          accessToken
          domain
        }
      }
      customerID
      groups
      id
      integrationID
      name
      onboarding {
        isCompleted
        stage
        type
      }
      owner
      type
      integrationTemplateID
      vendorID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      connectors {
        azurerepos {
          refreshToken
        }
        bitbucket {
          clientKey
        }
        contentful {
          environmentIDs
          spaceID
          spaceName
        }
        github {
          installationIDs
        }
        gitlab {
          accessToken
        }
        googledocs {
          refreshToken
          userId
        }
        googlesheets {
          refreshToken
          userId
        }
        hubspotcms {
          hubDomain
          hubId
          refreshToken
        }
        intercom {
          accessToken
          workspaceId
        }
        salesforceknowledge {
          accessToken
          instanceUrl
          refreshToken
        }
        shopify {
          accessToken
          storeId
        }
        mailchimp {
          accessToken
          accountId
          accountName
          serverId
        }
        zendesk {
          accessToken
          domain
        }
      }
      customerID
      groups
      id
      integrationID
      name
      onboarding {
        isCompleted
        stage
        type
      }
      owner
      type
      integrationTemplateID
      vendorID
      createdAt
      updatedAt
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      autoUpdateFrequency
      connector {
        isCuisto
        branch
        mode
        organization
        project
        repository
        type
        url
        userId
        webhookURL
      }
      cuistoConfig
      resourcesExcluded
      format {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      formatID
      groups
      id
      isActive
      liveApiKey
      locales {
        items {
          audit {
            completion
            duration
            missingSegments
            missingWords
            price
            segments
            words
          }
          code
          groups
          id
          inProgress
          isActive
          isSource
          locale {
            id
            language
            region
            tag
            createdAt
            updatedAt
          }
          localeID
          organizationID
          owner
          project {
            resourcesExcluded
            formatID
            groups
            id
            isActive
            liveApiKey
            name
            organizationID
            owner
            providerID
            integrationTemplateID
            translationBrief
            testApiKey
            createdAt
            updatedAt
          }
          projectID
          stateUpdatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      organization {
        connectors {
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
        createdAt
        updatedAt
      }
      organizationID
      owner
      providerID
      resourcePatterns {
        path
      }
      resources {
        path
        projectLocaleID
      }
      textmasterProjectSettings {
        autoLaunch
        expertiseId
        subExpertiseId
      }
      integrationTemplateID
      translationBrief
      testApiKey
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      connector {
        branch
        mode
        organization
        project
        repository
        type
        url
        webhookURL
      }
      resourcesExcluded
      format {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      formatID
      groups
      id
      isActive
      liveApiKey
      locales {
        items {
          audit {
            completion
            duration
            missingSegments
            missingWords
            price
            segments
            words
          }
          code
          groups
          id
          inProgress
          isActive
          isSource
          locale {
            id
            language
            region
            tag
            createdAt
            updatedAt
          }
          localeID
          organizationID
          owner
          project {
            resourcesExcluded
            formatID
            groups
            id
            isActive
            liveApiKey
            name
            organizationID
            owner
            providerID
            integrationTemplateID
            translationBrief
            testApiKey
            createdAt
            updatedAt
          }
          projectID
          stateUpdatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      organization {
        connectors {
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
        createdAt
        updatedAt
      }
      organizationID
      owner
      providerID
      autoUpdateFrequency
      resourcePatterns {
        path
      }
      resources {
        path
        projectLocaleID
      }
      textmasterProjectSettings {
        autoLaunch
        expertiseId
        subExpertiseId
      }
      integrationTemplateID
      translationBrief
      testApiKey
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      connector {
        branch
        mode
        organization
        project
        repository
        type
        url
        webhookURL
      }
      resourcesExcluded
      format {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      formatID
      groups
      id
      isActive
      liveApiKey
      locales {
        items {
          audit {
            completion
            duration
            missingSegments
            missingWords
            price
            segments
            words
          }
          code
          groups
          id
          inProgress
          isActive
          isSource
          locale {
            id
            language
            region
            tag
            createdAt
            updatedAt
          }
          localeID
          organizationID
          owner
          project {
            resourcesExcluded
            formatID
            groups
            id
            isActive
            liveApiKey
            name
            organizationID
            owner
            providerID
            integrationTemplateID
            translationBrief
            testApiKey
            createdAt
            updatedAt
          }
          projectID
          stateUpdatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      organization {
        connectors {
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
        createdAt
        updatedAt
      }
      organizationID
      owner
      providerID
      resourcePatterns {
        path
      }
      resources {
        path
        projectLocaleID
      }
      textmasterProjectSettings {
        autoLaunch
        expertiseId
        subExpertiseId
      }
      integrationTemplateID
      translationBrief
      testApiKey
      createdAt
      updatedAt
    }
  }
`;
export const createProjectLocale = /* GraphQL */ `
  mutation CreateProjectLocale(
    $input: CreateProjectLocaleInput!
    $condition: ModelProjectLocaleConditionInput
  ) {
    createProjectLocale(input: $input, condition: $condition) {
      audit {
        completion
        duration
        missingSegments
        missingWords
        price
        segments
        words
      }
      code
      groups
      id
      inProgress
      isActive
      isSource
      locale {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      localeID
      organizationID
      owner
      project {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      projectID
      stateUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectLocale = /* GraphQL */ `
  mutation UpdateProjectLocale(
    $input: UpdateProjectLocaleInput!
    $condition: ModelProjectLocaleConditionInput
  ) {
    updateProjectLocale(input: $input, condition: $condition) {
      audit {
        completion
        duration
        missingSegments
        missingWords
        price
        segments
        words
      }
      code
      groups
      id
      inProgress
      isActive
      isSource
      locale {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      localeID
      organizationID
      owner
      project {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      projectID
      stateUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectLocale = /* GraphQL */ `
  mutation DeleteProjectLocale(
    $input: DeleteProjectLocaleInput!
    $condition: ModelProjectLocaleConditionInput
  ) {
    deleteProjectLocale(input: $input, condition: $condition) {
      audit {
        completion
        duration
        missingSegments
        missingWords
        price
        segments
        words
      }
      code
      groups
      id
      inProgress
      isActive
      isSource
      locale {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      localeID
      organizationID
      owner
      project {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      projectID
      stateUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const createVendor = /* GraphQL */ `
  mutation CreateVendor(
    $input: CreateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    createVendor(input: $input, condition: $condition) {
      defaultIntegrationID
      defaultIntegration {
        id
        groups
        name
        templateID
        integrationTypeID
        integrationType {
          id
          status
          type
          createdAt
          updatedAt
        }
        memoq_apiKey
        memoq_creatorUserID
        memoq_webServiceAPIAccessPoint
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        protemos_apiKey
        createdAt
        updatedAt
        owner
      }
      groups
      id
      integrations {
        items {
          id
          groups
          name
          templateID
          integrationTypeID
          integrationType {
            id
            status
            type
            createdAt
            updatedAt
          }
          memoq_apiKey
          memoq_creatorUserID
          memoq_webServiceAPIAccessPoint
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          protemos_apiKey
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      integrationMode
      integrationTypeIDs
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $input: UpdateVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    updateVendor(input: $input, condition: $condition) {
      defaultIntegrationID
      defaultIntegration {
        id
        groups
        name
        templateID
        integrationTypeID
        integrationType {
          id
          status
          type
          createdAt
          updatedAt
        }
        memoq_apiKey
        memoq_creatorUserID
        memoq_webServiceAPIAccessPoint
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        protemos_apiKey
        createdAt
        updatedAt
        owner
      }
      groups
      id
      integrations {
        items {
          id
          groups
          name
          templateID
          integrationTypeID
          integrationType {
            id
            status
            type
            createdAt
            updatedAt
          }
          memoq_apiKey
          memoq_creatorUserID
          memoq_webServiceAPIAccessPoint
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          protemos_apiKey
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      integrationMode
      integrationTypeIDs
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor(
    $input: DeleteVendorInput!
    $condition: ModelVendorConditionInput
  ) {
    deleteVendor(input: $input, condition: $condition) {
      defaultIntegrationID
      defaultIntegration {
        id
        groups
        name
        templateID
        integrationTypeID
        integrationType {
          id
          status
          type
          createdAt
          updatedAt
        }
        memoq_apiKey
        memoq_creatorUserID
        memoq_webServiceAPIAccessPoint
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        protemos_apiKey
        createdAt
        updatedAt
        owner
      }
      groups
      id
      integrations {
        items {
          id
          groups
          name
          templateID
          integrationTypeID
          integrationType {
            id
            status
            type
            createdAt
            updatedAt
          }
          memoq_apiKey
          memoq_creatorUserID
          memoq_webServiceAPIAccessPoint
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          protemos_apiKey
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      integrationMode
      integrationTypeIDs
      name
      createdAt
      updatedAt
    }
  }
`;
