import { action, thunk, computed } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listProjectLocales } from "../../graphql/locale-queries"
import {
  createProjectLocale,
  updateProjectLocale,
  deleteProjectLocale,
} from "../../graphql/locale-mutations"

export default {
  items: [],

  // computed
  byId: computed(state => id =>
    state.items.find(locale => String(locale.id) === String(id))
  ),

  source: computed(state => state.items.find(locale => locale.isSource)),

  targets: computed(state => state.items.filter(locale => !locale.isSource)),

  // thunks
  fetch: thunk(async (actions, projectID) => {
    try {
      const filter = {
        projectID: { eq: projectID }
      }
      const { data } = await API.graphql(graphqlOperation(listProjectLocales, { filter }))
      const projectLocales = data.listProjectLocales.items
      actions.set(projectLocales)
      return projectLocales
    } catch (e) {
      console.error(e)
    }
  }),

  create: thunk(async (actions, payload) => {
    const input = { ...payload }
    const { data } = await API.graphql(
      graphqlOperation(createProjectLocale, { input })
    )
    actions.push(data.createProjectLocale)
    return data.createProjectLocale
  }),

  update: thunk(async (actions, payload) => {
    const input = { ...payload }
    const condition = { id: payload.localeID }
    const { data } = await API.graphql(
      graphqlOperation(updateProjectLocale, { input, ...condition })
    )
    actions.updateProjectLocale(data.updateProjectLocale)
  }),

  delete: thunk(async (actions, projectLocale) => {
    const input = {
      id: projectLocale.id,
    }
    const { data } = await API.graphql(
      graphqlOperation(deleteProjectLocale, { input })
    )
    actions.remove(data.deleteProjectLocale)
  }),

  deleteProjectLocales: thunk(async (actions, projectLocales) => {
    projectLocales.forEach(projectLocale => {
      const input = {
        id: projectLocale.id,
      }
      API.graphql(graphqlOperation(deleteProjectLocale, { input }))
    })
  }),

  // actions
  set: action((state, projectLocales) => {
    state.items = projectLocales
  }),

  push: action((state, projectLocale) => {
    state.items = state.items.concat([projectLocale])
  }),

  updateProjectLocale: action((state, projectLocale) => {
    state.items = state.items.map(l =>
      l.id === projectLocale.id ? projectLocale : l
    )
  }),

  remove: action((state, projectLocale) => {
    state.items = state.items.filter(l => l.id !== projectLocale.id)
  }),
}
