import { useStoreActions, useStoreState } from 'easy-peasy';
import { useIntl } from 'gatsby-plugin-intl';
import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { CONNECTORS } from '../../../store/models/connectors';
import { fieldRequired } from '../../../utils/validations';
import Form from '../../forms/Form';
import ControlFooter from './ControlFooter';

const LokaliseForm = () => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false)
  const formValues = useStoreState(state => state.projectWizard.formValues)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const options = useStoreState(state => state.lokalise.items)
  const teams = useStoreState(state => state.lokalise.teams)
  const teamUsers = useStoreState(state => state.lokalise.teamUsers)

  const { fetch: fetchProjects, fetchTeams, fetchTeamUsers, addWebhook } = useStoreActions(actions => actions.lokalise)

  const setFormField = useStoreActions(
    actions => actions.projectWizard.setFormField
  )

  const { getValues, handleSubmit, errors, control } = useForm({ mode: 'onChange' });

  const fields = [
    {
      name: "project[lokaliseProject]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.lokaliseProjects",
        defaultMessage: "Select Project",
      })}`,
      options,
      isLoading,
      validations: { ...fieldRequired }
    },
    {
      type: 'divider',
    },
    {
      name: "project[lokaliseTeam]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.lokaliseTeams",
        defaultMessage: "Select Team",
      })}`,
      options: teams,
      isLoading,
      validations: { ...fieldRequired }
    },
    {
      name: "project[lokaliseTeamUser]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.lokaliseTeamUser",
        defaultMessage: "Select Team User",
      })}`,
      options: teamUsers,
      isLoading,
      validations: { ...fieldRequired }
    },
  ]

  const currentSelectedTeam = useWatch({
    control,
    name: "project[lokaliseTeam]",
  })

  const onSubmit = async (values) => {
    const { project_id: projectID, name } = values.project.lokaliseProject
    const { value: userId } = values.project.lokaliseTeamUser
    const liveApiKey = await addWebhook({
      projectID,
      organizationID: currentOrg.id
    })

    const project = {
      ...formValues?.project,
      ...{ connector: { ...formValues?.project?.connector, url: `https://app.lokalise.com/project/${projectID}/`, userId } },
      ...values.project,
      liveApiKey,
      owner: currentOrg.owner,
      organizationID: currentOrg.id,
      providerID: `${CONNECTORS.lokalise}#${projectID}`,
      name,
    }

    setFormField({
      project,
      type: CONNECTORS.lokalise,
    })
  }

  useEffect(() => {
    if (!currentOrg?.id) {
      return
    }

    setIsLoading(true)
    fetchTeams({ organizationID: currentOrg.id }).finally(() => setIsLoading(false))
    fetchProjects({ organizationID: currentOrg.id }).finally(() => setIsLoading(false))
  }, [currentOrg, fetchProjects])

  useEffect(() => {
    if (!currentSelectedTeam) {
      return
    }

    fetchTeamUsers({ organizationID: currentOrg.id, teamID: currentSelectedTeam.team_id });
  }, [currentSelectedTeam])

  return <Form
    errors={errors}
    fields={fields}
    control={control}
    className="project-form"
  >
    <ControlFooter
      errors={errors}
      withBack
      handleSubmit={handleSubmit}
      onNext={onSubmit}
    />
  </Form>;
};

export default LokaliseForm;
