import * as Sentry from "@sentry/gatsby"
import { action, computed, thunk } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { getIntegration } from "../../graphql/locale-queries"
import { updateOrganization } from "../../graphql/locale-mutations"
import { ORG_TYPES } from "../../models/organization"
import { partnerConfig } from "../../helpers/partner"
import { INTEGRATION_MODES } from "../../models/integrationModes"

export default {
  plans: [],
  isDefaultSet: false,
  isLoaded: false,

  item: {},
  hasPaidSubscriptions: computed(state => state.plans.length > 0),

  partnerConfig: computed(
    [state => state.item, (_, storeState) => storeState.vendor.item],
    (currentOrg, vendorData) =>
      partnerConfig(currentOrg.type, vendorData)?.config || {}
  ),
  partnerText: computed(
    [state => state.item, (_, storeState) => storeState.vendor.item],
    (currentOrg, vendorData) =>
      partnerConfig(currentOrg.type, vendorData)?.text || {}
  ),
  workspaceType: computed(state => state.item.type),
  isLanguageTeam: computed(
    state => state.item?.type && state.item.type === ORG_TYPES.locale
  ),
  isPartner: computed(
    state => state.item?.type && state.item.type !== ORG_TYPES.locale
  ),
  isRWS: computed(state => state.item.type === ORG_TYPES.rws),

  isSharedMode: computed(state => state.item?.vendor?.integrationMode === INTEGRATION_MODES.shared),
  isDedicatedMode: computed(state => state.item?.vendor?.integrationMode === INTEGRATION_MODES.dedicated),

  // thunks
  fetchPaidSubscription: thunk(async (actions, { id: organizationID }) => {
    if (!organizationID) return;
    try {
      const { plans } = await API.get("nepal", "/chargebee/subscriptions", {
        queryStringParameters: { organizationID }
      });

      actions.setPlans(plans);
    } catch (err) {
      console.error(err);
    }
  }),

  fetch: thunk(async (actions, orgId, helpers) => {
    let currOrg = null

    try {
      await helpers.getStoreActions().organizations.fetch();
      actions.setIsDefaultSet(false);
      const organizations = helpers.getStoreState().organizations.items

      const orgById = orgId && organizations.find(org => orgId === org.id)

      if (orgById) {
        currOrg = orgById
      } else if (helpers.getStoreState().currentOrg.item?.id) {
        currOrg = organizations.find(
          org => helpers.getStoreState().currentOrg.item.id === org.id
        )
        actions.setIsDefaultSet(false);
      } else {
        currOrg = organizations[0];
        actions.setIsDefaultSet(true);
      }
      currOrg = currOrg || {}
      actions.setIsLoaded(true);
      actions.fetchPaidSubscription(currOrg)
      helpers.getStoreActions().currentOrg.set(currOrg);
      helpers.getStoreActions().onboarding.setAttrs({ ...currOrg.onboarding, organization: currOrg })
    } catch (e) {
      console.error(e)
    }

    try {
      actions.setIntegration({});
      if(currOrg && ( currOrg.vendor?.integrationMode === INTEGRATION_MODES.dedicated || !helpers.getStoreState().currentOrg.isPartner ) && currOrg.integrationID) {
        const { data } = await API.graphql(
          graphqlOperation(getIntegration, { id: currOrg.integrationID }),
        );
        actions.setIntegration(data.getIntegration);
      }
    } catch(e) {
      console.error(e)
    }
  }),


  updateDefaultIntegration: thunk(async (actions, { id, integration }) => {
    const input = {
      id,
      integrationID: integration.id,
    };

    actions.updateProperty({
      integration,
      integrationID: integration.id,
    })

    await API.graphql(graphqlOperation(updateOrganization, { input }))
  }),

  updateStatus: thunk(async (actions, input, helpers) => {
    actions.updateProperty(input)
    helpers.getStoreActions().organizations.updateOrgById(input)
    return API.patch("nepal", `/organizations/${input.id}/status`, {
      body: {
        status: input.status
      },
    })
  }),

  update: thunk(async (actions, input, helpers) => {
    actions.updateProperty(input)
    helpers.getStoreActions().organizations.updateOrgById(input)
    return API.graphql(
      graphqlOperation(updateOrganization, { input })
    )
  }),

  updateConnectors: thunk(async (_, { connectorID, connectorAuth, organizationID }) => {
    API.put("nepal", `/organizations/${organizationID}/connectors/${connectorID}`, { body: connectorAuth })
  }),

  updateProperty: action((state, input) => {
    state.item = {
      ...state.item,
      ...input,
    }
  }),

  reset: action(state => {
    state.item = {}
    state.hasPaidSubscriptions = false
  }),

  // actions
  set: action((state, org) => {
    Sentry.setContext("organization", {
      id: org.id,
      name: org.name,
      owner: org.owner,
      onboarding: org.onboarding,
      createdAt: org.createdAt,
    });
    state.item = org
  }),

  setIntegration: action((state, integration) => {
    state.item.integration = integration;
    if (integration.id) {
      state.item.integration.integrationType.configuration = integration.integrationType.configuration
        ? JSON.parse(state.item.integration.integrationType.configuration) : {}
    }
  }),

  setPlans: action((state, plans) => {
    state.plans = plans
  }),

  setIsDefaultSet: action((state, isDefault) => {
    state.isDefaultSet = isDefault;
  }),

  setIsLoaded: action((state, isLoaded) => {
    state.isLoaded = isLoaded;
  })
}
