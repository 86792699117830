import cryptoRandomString from "crypto-random-string"

export function stateGenerator(data) {
  let key = cryptoRandomString({ length: 10 })
  if (typeof window !== "undefined") {
    window.localStorage.setItem(key, JSON.stringify(data))
  }
  return key
}

export function formatPercentage(value, locale) {
  return new Intl.NumberFormat(locale, {
    unit: "percent",
    style: "percent",
  }).format(value / 100)
}

export function variantClassName(type) {
  switch (type) {
    case "error":
      return "bg-red-800-to-900"
    case "success":
      return "bg-green-800-to-900"
    case "warning":
      return "bg-orange-800-to-900"
    case "info":
    default:
      return "bg-gray-800-to-900"
  }
}

export function calculateColor(string) {
  let stringUniqueHash = [...string].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc);
  }, 0);

  return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
}
