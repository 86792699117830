import { path } from "../config/routes"
import OnboardingUpdater from "../service/onboardingUpdater"
import { INTEGRATION_MODES } from "./integrationModes"
import { parse } from "query-string"

export const STAGES = {
  checkout: "checkout",
  checkoutSuccess: "checkoutSuccess",
  connector: "connector",
  connector_install:"connector-install",
  congratulations: "congratulations",
  integration: "integration",
  integrations: "integrations",
  newClient: "newClient",
  partnerIntegration: "partnerIntegration",
  plans: "plans",
  profile: "profile",
  project: "project",
  scheduleOnboarding: "scheduleOnboarding",
  team: "team",
  welcome: "welcome",
  workspace: "workspace",
}

export const OnboardingRoutes = {
  CHECKOUT: {
    path: "/checkout/:item_price_id",
    root: "/checkout",
    stage: STAGES.checkout,
    required: true,
  },
  CHECKOUT_SUCCESS: {
    path: "/checkout-success",
    root: "/checkout-success",
    stage: STAGES.checkoutSuccess,
    required: true,
  },
  INTEGRATION: {
    path: "/integration",
    root: "/integration",
    stage: STAGES.integration,
    required: true,
  },
  INTEGRATIONS: {
    path: "/integrations",
    root: "/integrations",
    stage: STAGES.integrations,
    required: true,
  },
  NEW_PROJECT: {
    path: path("NEW_PROJECT"),
    root: "/new-project",
    stage: STAGES.project,
    absolute: true,
  },
  PARTNER_INTEGRATION: {
    path: "/partner-integration",
    root: "/partner-integration",
    stage: STAGES.partnerIntegration,
    required: true,
  },
  PLANS: {
    path: "/plans",
    root: "/plans",
    stage: STAGES.plans,
    required: true,
  },
  PROFILE: {
    path: "/profile",
    root: "/profile",
    stage: STAGES.profile,
    required: true,
  },
  SCHEDULE_ONBOARDING: {
    path: "/schedule-onboarding",
    root: "/schedule-onboarding",
    stage: STAGES.scheduleOnboarding,
    required: true,
  },
  TEAM: {
    path: "/team",
    root: "/team",
    stage: STAGES.team,
    required: true
  },
  WORKSPACE: {
    path: "/workspace",
    root: "/workspace",
    stage: STAGES.workspace,
    required: true,
  },
  CONGRATULATIONS: {
    path: "/congratulations",
    root: "/congratulations",
    stage: STAGES.congratulations,
    required: true,
  },
  CONNECTOR_INSTALL: {
    path: path("CONNECTOR_INSTALLER", { connectorId: parse(typeof location !== "undefined" ? location.search : "")?.connector }),
    root: "/connector-installer",
    stage: STAGES.connector_install,
    absolute: true,
  },
}

export const TYPES = {
  addClient: "addClient",
  addWorkspace: "addWorkspace",
  github: "github",
  newUser: "newUser",
  partnerVendor: "partnerVendor",
  languageTeam: "languageTeam",
}

export const ADD_WORKSPACE_PATH = [
  STAGES.workspace,
  STAGES.team,
  STAGES.project,
]

export const DEFAULT_PATH = [
  STAGES.profile,
  STAGES.workspace,
  STAGES.team,
  STAGES.project,
]

export const VENDOR_DEFAULT_PATH = [
  STAGES.workspace,
  STAGES.team,
  STAGES.project,
]

export const INTEGRATION_PATH = [
  STAGES.profile,
  STAGES.workspace,
  STAGES.integration,
  STAGES.team,
  STAGES.project,
]

export const VENDOR_INTEGRATION_PATH = [
  STAGES.workspace,
  STAGES.integration,
  STAGES.team,
  STAGES.project,
]

export const CONNECTOR_SIGNUP_PATH = [
  STAGES.profile,
  STAGES.workspace,
  STAGES.team,
  STAGES.connector_install,
];

export const LANGUAGE_TEAM_PATH = [
  STAGES.profile,
  STAGES.workspace,
  STAGES.team,
  STAGES.project,
];

export const PARTNER_VENDOR = [
  STAGES.workspace,
  STAGES.team,
  STAGES.partnerIntegration,
  STAGES.plans,
  STAGES.checkout,
  STAGES.scheduleOnboarding,
  STAGES.congratulations
]

export const GITHUB_PATH = [STAGES.welcome, STAGES.project]

export default class Onboarding {
  constructor(attrs) {
    attrs = attrs || {}
    this.organization = attrs.organization || {}
    this.type = attrs.type || TYPES.newUser
    this.pathExclude = attrs.pathExclude || []
    this.stage = attrs.stage || this.path[0]
    this.isCompleted = attrs.isCompleted || false
    this.integrationMode = this.organization?.vendor?.integrationMode || attrs.integrationMode;
    this.vendor = attrs.vendor || {}
    this.partner = attrs.partner
  }

  get currentStage() {
    return this.stage
  }

  get currentStepNumber() {
    return this.path.indexOf(this.currentStage) + 1
  }

  get length() {
    return this.path.length
  }

  skipStep(step) {
    this.pathExclude.push(step)

    return new Onboarding(this)
  }

  getPathOfStep() {
    const route = Object.values(OnboardingRoutes).find(
      route => route.stage === this.stage
    )

    return {
      path: route?.path,
      absolute: !!route?.absolute,
      required: !!route?.required,
      newTab: !!route?.newTab,
    }
  }

  static getRouteFromLocation(location) {
    return Object.values(OnboardingRoutes).find(
      (route) => route.root === location
    )
  }

  getStepFromLocation(location) {
    const route = Onboarding.getRouteFromLocation(location)

    return this.path.findIndex((path) => path === route?.stage) + 1
  }

  markAsCompleted()  {
    OnboardingUpdater.call({
      onboarding: this,
      isCompleted: true,
    })

    return new Onboarding({ ...this, isCompleted: true })
  }

  setStage(stage, isCompleted) {
    OnboardingUpdater.call({
      onboarding: this,
      stage,
      isCompleted: isCompleted || false,
    })

    return new Onboarding({ ...this, stage, isCompleted: isCompleted || false })
  }

  moveToNextStage() {
    const route = Object.values(OnboardingRoutes).find(
      route => route.stage === this.nextStage
    )

    return this.setStage(route.stage, !route?.required)
  }

  moveToBackStage() {
    return this.setStage(this.backStage, this.isCompleted)
  }


  get nextStage() {
    const nextStep = this.path.indexOf(this.currentStage) + 1
    return this.path[nextStep]
  }

  get backStage() {
    const backStep = this.path.indexOf(this.currentStage) - 1
    return this.path[backStep]
  }

  getOnboarding(options = {}) {
    const { createWorkspace, addClient, partnerVendor, languageTeam } = options

    if(parse(location.search)?.connector){
      return CONNECTOR_SIGNUP_PATH;
    }

    if (partnerVendor) {
      return PARTNER_VENDOR;
    }

    if (languageTeam) {
      return LANGUAGE_TEAM_PATH;
    }

    if (createWorkspace || addClient) {
      return this.integrationMode ===  INTEGRATION_MODES.dedicated ?  VENDOR_INTEGRATION_PATH : VENDOR_DEFAULT_PATH;
    }

    return this.integrationMode === INTEGRATION_MODES.dedicated ? INTEGRATION_PATH : DEFAULT_PATH;
  }

  get path() {
    let currentPath
    switch (this.type) {
      case TYPES.github:
        currentPath = GITHUB_PATH
        break
      case TYPES.addWorkspace:
        currentPath = this.getOnboarding({ createWorkspace: true })
        break
      case TYPES.addClient:
        currentPath = this.getOnboarding({ addClient: true })
        break
      case TYPES.partnerVendor:
        currentPath = this.getOnboarding({ partnerVendor: true })
        break;
      case TYPES.languageTeam:
        currentPath = this.getOnboarding({ languageTeam: true })
        break;
      default:
        currentPath = this.getOnboarding()
    }

    return currentPath.filter(
      path => !this.pathExclude.find(excluded => excluded === path)
    )
  }

  toJSON() {
    return {
      stage: this.stage,
      isCompleted: this.isCompleted,
      type: this.type,
      partner: this.partner,
    }
  }
}
