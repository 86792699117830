import { action, thunk, computed } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listConnectors, getConnector } from "../../graphql/locale-queries"
import { byCategory } from "../../utils/sort"
import { connectorsByCategory } from "../../helpers/connector"

export const CONNECTORS = {
  adobeCommerce:"adobe-commerce",
  akeneo: "akeneo",
  api: "api",
  awsses: "awsses",
  azurerepos: "azurerepos",
  bitbucket: "bitbucket",
  campaignMonitor: "campaignmonitor",
  commercetools: "commercetools",
  contentful: "contentful",
  dropbox: "dropbox",
  facebookleadads: "facebookleadads",
  figma: "figma",
  github: "github",
  gitlab: "gitlab",
  googleDocs: "googledocs",
  googleSheets: "googlesheets",
  helpScout: "helpscout",
  hubspotcms: "hubspotcms",
  intercom: "intercom",
  lokalise: "lokalise",
  mailchimp: "mailchimp",
  microsoftTeams: "teams",
  salesforceknowledge: "salesforceknowledge",
  shopify: "shopify",
  tawk: "tawk",
  wordpress: "wordpress",
  wpml: "wpml",
  zapier: "zapier",
  zendesk: "zendesk",
}

export const CONNECTOR_CATEGORIES = {
  cms: "cms",
  code: "code",
  customerSuccess: "customerSuccess",
  support: "support"
}

export const API_CONNECTORS = [
  CONNECTORS.api,
  CONNECTORS.figma,
  CONNECTORS.wordpress,
  CONNECTORS.wpml,
  CONNECTORS.zapier,
]

export const GIT_CONNECTORS = [
  CONNECTORS.azurerepos,
  CONNECTORS.bitbucket,
  CONNECTORS.github,
  CONNECTORS.gitlab,
]

export const CONNECTOR_STATUSES = {
  beta: "beta",
  preview: "preview",
  private: "private",
  public: "public",
}

export const CONNECTOR_TYPES = {
  cms: "cms",
  cuisto: "cuisto",
  git: "git",
  mock: "mock",
  zapier: "zapier",
  zeroconf: "zeroconf",
}

export const CONNECTOR_AUTO_UPDATE_FREQUENCIES = {
  realtime: [
    {
      id: "realtime",
      name: "Real-Time",
      description: "Push content as soon as the source is updated",
      isDefault: false
    },
  ],
  schedule: [
    {
      id: "hourly",
      name: "Every hour",
      description: "Push content every hour, on the hour",
      isDefault: false
    },
    {
      id: "daily",
      name: "Every day",
      description: "Push content at midnight (UTC) every day",
      isDefault: false
    },
    {
      id: "weekly",
      name: "Every week",
      description: "Push content every Monday at midnight (UTC)",
      isDefault: false
    },
    {
      id: "monthly",
      name: "Every month",
      description: "Push content on the 1st of the month at midnight (UTC)",
      isDefault: false
    },
  ],
};

export default {
  items: [],
  public: [],

  // computed
  byId: computed(state => id =>
    state.items.find(connector => connector.id === id)
  ),

  byCategory: computed(state => ({ ldFlags }) => {
    return connectorsByCategory(state.items, ldFlags)
  }),

  byType: computed(state => type =>
    state.items.filter(connector => connector.type === type)
  ),

  byStatus: computed(state => status =>
    state.items.filter(connector => connector.status === status)
  ),
  getConnector: computed(state => id => {
    return state.items.find(connector => connector.id === id)
  }),

  getConnectorByID: thunk(async (_, id) => {
    const { data } = await API.graphql(graphqlOperation(getConnector, { id }))
    const result = data?.getConnector || {};
    return {
      ...result,
      ...{
        name : result?.metadata?.name || result.name,
        logo : result?.metadata?.logo,
        category : result?.metadata?.category || result.category,
        rank : result?.metadata?.rank || result.rank
      }
    }
  }),

  mock: computed(state => state.byType(CONNECTOR_TYPES.mock)),
  zeroconf: computed(state => state.byType(CONNECTOR_TYPES.zeroconf)),

  // thunks
  fetch: thunk(async actions => {
    try {
      const connectors = []
      let nextToken
      do {
        const { data } = await API.graphql(
          graphqlOperation(listConnectors, { nextToken })
        )
        nextToken = data.listConnectors.nextToken
        connectors.push(...data.listConnectors.items)
      } while (nextToken)
      connectors.forEach(connector => {
        connector.name = connector.metadata?.name || connector.name
        connector.logo = connector.metadata?.logo
        connector.category = connector.metadata?.category || connector.category
        connector.rank = connector.metadata?.rank || connector.rank
      })
      actions.set(connectors)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  set: action((state, connectors) => {
    state.items = connectors.sort(byCategory)
  }),

  setPublic: action((state, connectors) => {
    const data = connectors.filter(({ status }) => [
      CONNECTOR_STATUSES.public,
      CONNECTOR_STATUSES.preview,
      CONNECTOR_STATUSES.beta
    ].includes(status))
    const publicConnectorsFirst = data.sort((a, b) => {
      if (a.status === CONNECTOR_STATUSES.public) return -1
      if (b.status === CONNECTOR_STATUSES.public) return 1
    })
    state.public = publicConnectorsFirst
  })
}
