import store from "../store/index"

export default class ProjectResourcesUpdater {
  constructor(projectResources) {
    this._projectResources = projectResources
  }

  static async call(args) {
    return new ProjectResourcesUpdater(args).call()
  }

  async call() {
    try {
      await this.updateProjectResources()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  updateProjectResources() {
    return store
      .getActions()
      .projects
      .editResource(this.projectResources())
  }

  projectResources() {
    return this._projectResources
  }
}
