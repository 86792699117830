import store from "../store/index"

export default class ProjectResourceValidator {
  constructor({ projectID, resources }) {
    this._projectID = projectID
    this._resources = resources
  }

  static async call(args) {
    return new ProjectResourceValidator(args).call()
  }

  async call() {
    return this.newPaths().some(path => this.existingPaths().includes(path))
  }

  // PRIVATE METHODS
  existingPaths() {
    return this.existingProjectResources().flatMap(resourceArray =>
      resourceArray.map(resourceObject => resourceObject.path)
    )
  }

  newPaths() {
    return this.resourcesToBeAdded().flatMap(resourceArray =>
      resourceArray.map(resourceObject => resourceObject.path)
    )
  }

  existingProjectResources() {
    return store.getState().projects.byId(this.projectID()).resources || []
  }

  resourcesToBeAdded() {
    return this._resources
  }

  projectID() {
    return this._projectID
  }
}
