import React from "react"
import { Button } from "react-bootstrap"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import Spinner from "../../common/Spinner"
import { useStoreActions, useStoreState } from "easy-peasy"
import { WIZARD_ROOT } from "../../../containers/projects/NewProject"

const ControlFooter = ({
  handleSubmit,
  errors,
  onBack,
  withBack,
  withNext,
  onNext,
  onSubmit,
  loading,
}) => {
  const { previousPath, nextPath } = useStoreState(state => state.projectWizard)
  const { previousStep, nextStep } = useStoreActions(
    actions => actions.projectWizard
  )

  return (
    <div className="d-flex align-items-center">
      {(onBack || withBack) && (
        <Button
          className="btn-outline--secondary"
          disabled={errors?.length > 0}
          onClick={() => {
            onBack && onBack()

            navigate(`${WIZARD_ROOT}${previousPath}`, {
              replace: true,
            })
            previousStep()
          }}
        >
          <FormattedMessage
            id="components.projects.backButton"
            defaultMessage="Back"
          />
        </Button>
      )}
      {(withNext || onNext) && (
        <Button
          onClick={handleSubmit(values => {
            onNext && onNext(values)
            
            navigate(`${WIZARD_ROOT}${nextPath}`)
            nextStep()
          })}
          className="btn btn--primary ml-4"
          disabled={errors?.length > 0}
        >
          <FormattedMessage
            id="components.projects.nextButton"
            defaultMessage="Next"
          />
        </Button>
      )}
      {onSubmit && (
        <Button
          onClick={handleSubmit(onSubmit)}
          className="btn btn--primary h-100 ml-4"
          disabled={errors?.length > 0 || loading}
        >
          {loading ? (
            <div className="d-flex align-items-center">
              <span>Setting up your project</span>
              <Spinner className="ml-2" isSmall />
            </div>
          ) : (
            <FormattedMessage
              id="components.projects.form.formSubmitLabel"
              defaultMessage="Add Project"
            />
          )}
        </Button>
      )}
    </div>
  )
}

export default ControlFooter
