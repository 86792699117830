import { action, createStore, persist } from "easy-peasy"

import accessRequests from "./models/accessRequests"
import azureBranches from "./models/azure/branches"
import azureRepos from "./models/azure/repos"
import billing from "./models/billing"
import bitbucketBranches from "./models/bitbucket/branches"
import bitbucketRepos from "./models/bitbucket/repos"
import bms from "./models/bms"
import connectors from "./models/connectors"
import contentResources from "./models/contentResources"
import currentOrg from "./models/currentOrg"
import currentUser from "./models/currentUser"
import currentVendor from "./models/currentVendor"
import expertises from "./models/expertises"
import formats from "./models/formats"
import githubBranches from "./models/github/branches"
import githubRepos from "./models/github/repos"
import gitlabBranches from "./models/gitlab/branches"
import gitlabRepos from "./models/gitlab/repos"
import googledocs from "./models/googledocs"
import googlesheets from "./models/googlesheets"
import hubspotcms from "./models/hubspotcms"
import hoursUsage from "./models/hoursUsage"
import integrations from "./models/integrations"
import integrationTypes from "./models/integrationTypes"
import locales from "./models/locales"
import lokalise from "./models/lokalise"
import mailchimp from "./models/mailchimp"
import onboarding from "./models/onboarding"
import organizations from "./models/organizations"
import project from "./models/project"
import projectLocales from "./models/projectLocales"
import projects from "./models/projects"
import projectTemplates from "./models/projectTemplates"
import projectWizard from "./models/projectWizard"
import quote from "./models/quote"
import staticLocales from "./models/staticLocales"
import vendor from "./models/vendor"
import team from "./models/team"
import clients from "./models/clients"
import appMode from "./models/appMode"
import vendorTeam from "./models/vendorTeam"

let initialState = {}

export const models = {
  accessRequests,
  appMode,
  azureBranches,
  azureRepos,
  bitbucketBranches,
  bitbucketRepos,
  bms,
  billing,
  clients,
  connectors,
  contentResources,
  currentOrg: persist(currentOrg),
  currentUser,
  currentVendor,
  expertises,
  formats,
  githubBranches,
  githubRepos,
  gitlabBranches,
  gitlabRepos,
  googledocs,
  googlesheets,
  hoursUsage,
  hubspotcms,
  integrations,
  integrationTypes,
  mailchimp,
  locales,
  lokalise,
  onboarding,
  organizations,
  project,
  projectLocales,
  projects,
  projectTemplates,
  projectWizard,
  quote,
  staticLocales,
  team,
  vendor,
  vendorTeam,
}

const store = createStore({
  ...models,
  reset: action(() => ({
    ...initialState,
    appMode: {
      asClient: false,
    },
  })),
})
initialState = store.getState()

export default store
