import { action, thunk, computed } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listFormats } from "../../graphql/locale-queries"
import { byName } from "../../utils/sort"

export const LOCALE_PATTERN = /\$\{localeCode\}/g

export const API_ID = "b4667b3a-8e4c-4522-98cc-7092cdfc819f"
export const CONTENTFUL_ID = "fc901848-6c50-4806-95b0-d6dbec72dbc9"
export const CUSITO_ID = "1aabc230-5132-4a81-aa68-e3bc167b75af"
export const GOOGLEDOCS = "d19aa09d-e581-4763-8b8d-09357c7f1104"
export const GOOGLESHEETS = "8fffc724-032b-49f6-a595-f984392c3db8"
export const INTERCOM_ID = "4e8ac297-b98b-4317-abc1-db387a493561"
export const HUBSPOT_ID = "a8967732-cd5c-4eb4-b729-9f8567df1643"
export const LOKALISE_ID = "b99831ce-30e7-4d3f-9e07-897911553692"
export const MAILCHIMP_ID = "c0351adf-5e55-46e2-b597-5a1b45264fc5"
export const MOCK_ID = "mock"
export const SALESFORCE_KNOWLEDGE_ID = "c0351adf-5e55-46e2-b597-5a1b45264fc6"
export const SHOPIFY_ID = "990ba8f9-d97a-45eb-87cb-193ef15732f5"
export const ZENDESK_ID = "b54afda1-7e80-4c2b-8916-5fc219757c6a"
export default {
  items: [],

  // computed
  api: computed(state => state.items.find(format => format.id === API_ID)),
  byId: computed(state => id => state.items.find(format => format.id === id)),
  contentful: computed(state => state.items.find(format => format.id === CONTENTFUL_ID)),
  cuisto: computed(state => state.items.find(format => format.id === CUSITO_ID)),
  hubspot: computed(state => state.items.find(format => format.id === HUBSPOT_ID)),
  googledocs: computed(state => state.items.find(format => format.id === GOOGLEDOCS)),
  googlesheets: computed(state => state.items.find(format => format.id === GOOGLESHEETS)),
  intercom: computed(state => state.items.find(format => format.id === INTERCOM_ID)),
  mailchimp: computed(state => state.items.find(format => format.id === MAILCHIMP_ID)),
  listable: computed(state => state.items.filter(format => format.isVisible)),
  lokalise: computed(state => state.items.find(format => format.id === LOKALISE_ID)),
  mock: computed(state => state.items.find(format => format.id === MOCK_ID)),
  salesforceknowledge: computed(state => state.items.find(format => format.id === SALESFORCE_KNOWLEDGE_ID)),
  shopify: computed(state => state.items.find(format => format.id === SHOPIFY_ID)),
  zendesk: computed(state => state.items.find(format => format.id === ZENDESK_ID)),

  // thunks
  fetch: thunk(async actions => {
    try {
      const formats = []
      let nextToken
      do {
        const { data } = await API.graphql(
          graphqlOperation(listFormats, { nextToken })
        )
        nextToken = data.listFormats.nextToken
        formats.push(...data.listFormats.items)
      } while (nextToken)

      actions.set(formats)

    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  set: action((state, formats) => {
    state.items = formats.sort(byName)
  }),
}
