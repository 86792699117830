import { action, computed, persist } from "easy-peasy"
import Onboarding from "../../models/onboarding"

export default persist({
  attrs: null,
  isLoading: false,

  item: computed(state => state.attrs ? new Onboarding(state.attrs) : null),

  setAttrs: action((state, attrs) => {
    state.attrs = attrs
  }),

  setLoading: action((state, isLoading) => {
    state.isLoading = isLoading
  })
})
