import React, { useEffect, useState } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { useLocation } from "@reach/router"

import { CONNECTORS, CONNECTOR_TYPES } from "../../../store/models/connectors"
import AzureForm from "./AzureForm"
import BitbucketForm from "./BitbucketForm"
import DriveFilesForm from "./DriveFilesForm"
import GithubForm from "./GithubForm"
import GitlabForm from "./GitlabForm"
import GoogleDocs from "./GoogleDocs"
import HubspotForm from "./HubspotForm"
import ApiForm from "./ApiForm"
import { navigate } from "gatsby-plugin-intl"
import { v4 as uuid } from "uuid"
import { WIZARD_ROOT } from "../../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../../store/models/projectWizard"
import { getStepsByConnector } from "../../../store/models/projectWizard"
import HubspotAccountsForm from "./HubspotAccountsForm"
import ContentfulSpaceForm from "./ContentfulSpaceForm"
import LokaliseForm from "./LokaliseForm"
import MailchimpAccountsForm from "./MailchimpAccountsForm"
import MailchimpCampaignFolders from "./MailchimpCampaignFolders"
import { ORG_TYPES } from "../../../models/organization"
import CuistoConnectorForm from "./CuistoConnectorForm"

const ConnectorForms = ({ connector: connectorId }) => {
  const location = useLocation()
  const [component, setComponent] = useState(null);
  const { setFormField, resetProjectWizard } = useStoreActions(actions => actions.projectWizard)
  const { api, contentful, googledocs, googlesheets, hubspot, intercom, items: formats, lokalise, salesforceknowledge, shopify, mailchimp, zendesk, cuisto } = useStoreState(state => state.formats)
  const { item: currentOrg, isDedicatedMode, isPartner } = useStoreState(state => state.currentOrg)
  const workspaceType = useStoreState(state => state.currentOrg.workspaceType)
  const initialFormValues = useStoreState(state => state.projectWizard.formValues)
  const connector = useStoreState(state => state.connectors.byId(connectorId))

  useEffect(() => {
    if (!connector || !currentOrg || formats.length === 0) return
    setComponent(componentForConnector())
  }, [
    connector,
    currentOrg,
    formats,
  ])

  const initForm = (initOptions) => {
    const {
      apiKey,
      cuistoConfig,
      generateKeys,
      format,
      providerID,
      providerName,
      providerUrl,
      resourcePatterns = [],
      fromCallback
    } = initOptions || {}
    let liveApiKey = apiKey;
    let testApiKey;
    if (generateKeys) [liveApiKey, testApiKey] = [ apiKey ?? `live_${uuid()}`, `test_${uuid()}`]
    let previousProviderID = initialFormValues?.project?.providerID
    if (previousProviderID) {
      const providerIDFragments = previousProviderID.split('#')
      previousProviderID = `${providerIDFragments[0]}#${providerIDFragments[1]}`
    }

    const textmasterProjectSettings = workspaceType === ORG_TYPES.textmaster ? { autoLaunch: true } : undefined;
    const providerIDValue = providerID || previousProviderID
    resetProjectWizard({ connector: connectorId, fromCallback })
    setFormField({
      project: {
        connector: {
          ...connector,
          url: providerUrl,
        },
        cuistoConfig,
        format,
        liveApiKey,
        name: providerName || connector.name,
        organizationID: currentOrg.id,
        owner: currentOrg.owner,
        providerID: providerIDValue,
        resourcePatterns,
        testApiKey,
        textmasterProjectSettings,
      },
    })
  }

  const moveNext = () => {
    const firstStep = getStepsByConnector(connector.id, location?.state?.fromCallback, { item: currentOrg, isDedicatedMode, isPartner })[0]
    if (firstStep.key === WIZARD_ROUTES.CONNECTOR.key) return
    navigate(`${WIZARD_ROOT}${firstStep.path(connector.id)}`, {
      replace: true,
      state: {
        ...location.state,
        skipped: true,
      },
    })
  }

  const initHubspotComponent= () => {
    const { connectors: { hubspotcms } } = currentOrg
    let providerID = location?.state?.providerID
    if (providerID) {
      initForm(
        {
          format: hubspot,
          providerID,
          resourcePatterns: { path: hubspot?.path },
          fromCallback: location?.state?.fromCallback
        }
      )
      return <HubspotForm />
    }
    if (hubspotcms) {
      providerID = (hubspotcms.length === 1)
        ? `hubspotcms#${hubspotcms[0].hubId}`
        : providerID
    }
    initForm({ format: hubspot, providerID, resourcePatterns: { path: hubspot?.path }, fromCallback: location?.state?.fromCallback })
    return (hubspotcms?.length === 1) ? <HubspotForm /> : <HubspotAccountsForm />
  }

  const initContentfulComponent= () => {
    const { connectors: { contentful: contentfulInstallations } } = currentOrg
    const formOptions =  {
      format: contentful,
      resourcePatterns: { path: contentful?.path },
      providerID: "contentful"
    };
    if(contentfulInstallations.length === 1){
      const { spaceID, environmentIDs } = contentfulInstallations[0];
      if(environmentIDs.length === 1) {
        initForm({
          ...formOptions,
          providerID: `contentful#${spaceID}:${environmentIDs[0]}`,
        })
        moveNext();
        return null;
      }
    }
    initForm(formOptions)
    return <ContentfulSpaceForm/>;
  }

  const initMailchimpComponent = () => {
    const { connectors: { mailchimp: mailchimpInstallations } } = currentOrg
    let providerID = location?.state?.providerID
    let providerName = connector.name;
    if (mailchimpInstallations) {
      providerID = (mailchimpInstallations.length === 1)
        ? `mailchimp#${mailchimpInstallations[0].accountId}`
        : providerID
      providerName = (mailchimpInstallations.length === 1)
        ? mailchimpInstallations[0].accountName
        : providerName
    }
    if (providerID) {
      providerName = mailchimpInstallations.find(item => item.accountId === providerID.split("#")[1]).accountName;
      initForm(
        {
          format: mailchimp,
          providerID,
          providerName,
          resourcePatterns: { path: mailchimp?.path },
          fromCallback: location?.state?.fromCallback
        }
      )
      return <MailchimpCampaignFolders />
    }
    initForm({ format: mailchimp, providerID,providerName, resourcePatterns: { path: mailchimp?.path }, fromCallback: location?.state?.fromCallback });
    return mailchimpInstallations?.length === 1 ? <MailchimpCampaignFolders/> : <MailchimpAccountsForm />
  }
  const componentForConnector = () => {
    if (connector.type === CONNECTOR_TYPES.cuisto) {
      initForm({ format: cuisto, resourcePatterns: { path: cuisto?.path }, cuistoConfig: location?.state?.cuistoConfig, providerUrl: location?.state?.providerUrl });
      return <CuistoConnectorForm connector={connector}/>
    }

    switch (connector.id) {
      case CONNECTORS.api:
        initForm({ format: api, generateKeys: true, resourcePatterns: { path: api?.path }})
        return <ApiForm />
      case CONNECTORS.azurerepos:
        initForm()
        return <AzureForm />
      case CONNECTORS.bitbucket:
        initForm()
        return <BitbucketForm />
      case CONNECTORS.contentful:
        return initContentfulComponent()
      case CONNECTORS.figma:
        initForm({ format: api, generateKeys: true, resourcePatterns: { path: api?.path }});
        moveNext();
        return null;
      case CONNECTORS.github:
        initForm()
        return <GithubForm />
      case CONNECTORS.gitlab:
        initForm()
        return <GitlabForm />
      case CONNECTORS.googleDocs:
        initForm({ format: googledocs, providerID: location?.state?.providerID, providerName: location?.state?.providerName, providerUrl: location?.state?.providerUrl, resourcePatterns: { path: googledocs?.path } })
        return <DriveFilesForm />
      case CONNECTORS.googleSheets:
        initForm({ format: googlesheets, providerID: location?.state?.providerID, providerName: location?.state?.providerName, providerUrl: location?.state?.providerUrl, resourcePatterns: { path: googlesheets?.path } })
        return <DriveFilesForm />
      case CONNECTORS.hubspotcms:
        return initHubspotComponent()
      case CONNECTORS.intercom:
        initForm({ format: intercom, providerID: location?.state?.providerID, providerName: location?.state?.providerName, providerUrl: location?.state?.providerUrl, resourcePatterns: { path: intercom?.path } })
        moveNext()
        return null
      case CONNECTORS.lokalise:
        initForm({ format: lokalise, resourcePatterns: { path: lokalise?.path } })
        return <LokaliseForm />
      case CONNECTORS.mailchimp:
        return initMailchimpComponent()
      case CONNECTORS.salesforceknowledge:
        initForm({ format: salesforceknowledge, providerID: location?.state?.providerID, providerName: location?.state?.providerName, providerUrl: location?.state?.providerUrl, resourcePatterns: { path: salesforceknowledge?.path } })
        moveNext()
        return null
      case CONNECTORS.shopify:
        initForm({ format: shopify, providerID: location?.state?.providerID, providerName: location?.state?.providerName, providerUrl: location?.state?.providerUrl, resourcePatterns: { path: shopify?.path } })
        moveNext()
        return null
      case CONNECTORS.wordpress:
        initForm({ format: api, generateKeys: true, resourcePatterns: { path: api?.path }});
        moveNext();
        return null;
      case CONNECTORS.wpml:
        initForm({ format: api, apiKey: location?.state?.apiKey, generateKeys: false, resourcePatterns: { path: api?.path }});
        moveNext();
        return null;
      case CONNECTORS.zapier:
        initForm({ format: api, generateKeys: true })
        moveNext()
        return null
      case CONNECTORS.zendesk:
        initForm({ format: zendesk, providerID: location?.state?.providerID, providerName: location?.state?.providerName, providerUrl: location?.state?.providerUrl, resourcePatterns: { path: zendesk?.path } })
        moveNext()
        return null
      default:
        return null
    }
  }

  return component
}

export default ConnectorForms
