import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { WIZARD_ROOT } from "../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../store/models/projectWizard"
import { stateGenerator } from "../../helpers/application"

const Lokalise = ({ connector, name, redirectURI, scopes }) => {
  const connectorRoute = `${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(connector)}`
  const state = stateGenerator({
    redirectURL: connectorRoute
  });

  const redirectTo = `${process.env.LOKALISE_OAUTH_URL}?state=${state}&redirect_uri=${redirectURI}&client_id=${process.env.LOKALISE_CLIENT_ID}&scope=${encodeURIComponent(scopes)}`;

  return (
    <div className="mt-4">
      <h2>
        <FormattedMessage
          id="components.lokalise.title"
          defaultMessage={`Connect your {name} account with Locale`}
          values={{ name }}
        />
      </h2>
      <FormattedMessage
          id="components.lokalise.description"
          defaultMessage={`Click the <b>Connect With {name}</b> button below.
          You will be redirected to {name} to authorize Locale on your Account.
          After accepting the permissions, you are brought back to Locale to continue setup.`}
          values={{ b: msg => <b>{msg}</b>, name }}
      />
      <div className="text-center mt-3">
        <a href={redirectTo} class="btn btn-primary btn-sm">
          <span class="buttonText">Connect With {name}</span>
        </a>
      </div>
    </div>
  );
}

export default Lokalise
