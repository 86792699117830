import React, { useEffect } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { connectorLogoURL } from "../../../helpers/assets"
import { Image, ProgressBar } from "react-bootstrap"
import { useMatch } from "@reach/router"
import { useStoreState } from "easy-peasy"
import { WIZARD_ROOT } from "../../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../../store/models/projectWizard"

export const Setup = ({ children }) => {
  const connectorMatch = useMatch(`/:locale${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path()}/*`)
  const rootMatch = useMatch(`/:locale${WIZARD_ROOT}`)
  const successMatch = useMatch(`/:locale${WIZARD_ROOT}${WIZARD_ROUTES.SUCCESS.path()}/*`)
  const {
    stepIdx,
    totalSteps,
  } = useStoreState(state => state.projectWizard)
  const connectorId = connectorMatch?.connector
  const connector = useStoreState(state => state.connectors.byId(connectorId))

  return (
    <div className="p-2">
      <div className="d-flex align-items-center mb-5">
        {connector && (
          <Image
            src={(connector?.logo) 
              ? connector.logo
              : connectorLogoURL()
            }
            alt="connector-logo"
            className="connector-card--image connector-card--image-sm mr-3"
          />
        )}
        <div className="d-flex flex-column">
          {!successMatch && (
            <ProgressBar
              className="progress-bar--custom mb-3"
              now={rootMatch ? 0 : ((stepIdx + 1) / (totalSteps + 1)) * 100}
            />
          )}
          <h1 className="main-heading m-0">
            {rootMatch ? (
              <FormattedMessage
                id="components.projects.new.heading"
                defaultMessage="Setup a new project"
              />
            ) : (
              !successMatch &&
                connector && (
                <FormattedMessage
                  id="components.projects.new.heading.projecttype"
                  defaultMessage="Add your {connector} Project"
                  values={{
                    connector: (
                      <span className="text-capitalize">
                        {connector.name}
                      </span>
                    ),
                  }}
                />
              )
            )}
          </h1>
        </div>
      </div>
      <div className="onboarding-form">
        <div className={`${rootMatch ? "" : "w-60"}`}>{children}</div>
      </div>
    </div>
  )
}
