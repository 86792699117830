import React from "react"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, navigate } from "gatsby-plugin-intl"
import { Button, Image } from "react-bootstrap"

import Icon from "../Icon"
import { connectorLogoURL } from "../../helpers/assets"
import { stateGenerator } from "../../helpers/application"
import { CONNECTORS, CONNECTOR_TYPES, CONNECTOR_STATUSES } from "../../store/models/connectors"
import ConfigureModal from "./ConfigureModal"
import { WIZARD_ROOT } from "../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../store/models/projectWizard"
import { generateRedirectUrl } from "../../helpers/connector"

const canCreateWithoutConnection = (type) => ([CONNECTOR_TYPES.zeroconf, CONNECTOR_TYPES.cuisto].includes(type))

const PublicConnector = ({ createProject, connector }) => {
  const { id, name, type, status, logo } = connector
  const currentOrg = useStoreState(state => state.currentOrg.item)

  const connectorConnected = currentOrg?.connectors?.[id]
  const buttonText = () => {
    if (
      createProject &&
      (connectorConnected || canCreateWithoutConnection(type))
    )
      return (
        <FormattedMessage
          id="components.connectors.connector.button.createProject"
          defaultMessage="Create Project"
        />
      )

    if (connectorConnected)
      return (
        <FormattedMessage
          id="components.connectors.connector.button.settings"
          defaultMessage="Settings"
        />
      )

    return (
      <FormattedMessage
        id="components.connectors.connector.button"
        defaultMessage="Connect"
      />
    )
  }

  const Configuration = () => {
    const connectorRoute = `${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(id)}`
    const modalConfiguration = [
      CONNECTORS.akeneo,
      CONNECTORS.contentful,
      CONNECTORS.gitlab,
      CONNECTORS.googleDocs,
      CONNECTORS.googleSheets,
      CONNECTORS.lokalise,
      CONNECTORS.shopify,
      CONNECTORS.zendesk,
    ].includes(id)

    const modalOnlyWhenNotConnected = [
      // CONNECTORS.shopify
    ].includes(id)

    const modalEveryCreation = [
      CONNECTORS.akeneo,
      CONNECTORS.commercetools,
      CONNECTORS.googleDocs,
      CONNECTORS.googleSheets,
      CONNECTORS.zendesk
    ].includes(id)

    const requiresConfiguration = [
      CONNECTORS.dropbox,
      CONNECTORS.intercom,
      CONNECTORS.salesforceknowledge,
      CONNECTORS.shopify,
      CONNECTORS.googleSheets,
      CONNECTORS.googleDocs,
      CONNECTORS.lokalise,
    ].includes(id)

    let redirectURL = generateRedirectUrl(id);
    if(redirectURL) {
      redirectURL = new URL(redirectURL);
      redirectURL.searchParams.append("state", stateGenerator({ redirectURL: connectorRoute }))
    }

    const redirectTo = redirectURL?.toString();

    const createProjectButton = (
      <Button
        onClick={async () => {
          if (!currentOrg?.onboarding.isCompleted)
            await currentOrg?.onboarding.markAsCompleted()

          navigate(connectorRoute)
        }}
        className="py-1 btn btn-block btn--primary"
      >
        {buttonText()}
      </Button>
    )

    const redirectToButton = (
      <Button
        href={redirectTo}
        className={`py-1 btn btn-block ${
          !currentOrg?.connectors?.[id] || createProject
            ? "btn--primary"
            : "btn-outline--secondary"
        }`}
      >
        {buttonText()}
      </Button>
    )

    const openModalButton = (
      <ConfigureModal
        createProject={createProject}
        connector={connector}
        buttonText={buttonText}
      />
    )

    if (createProject) {
      if (
         modalEveryCreation || (!connectorConnected &&
        (modalConfiguration || modalOnlyWhenNotConnected))
      )
       return openModalButton
      if (
        redirectTo &&
        (!connectorConnected || requiresConfiguration)
      )
        return redirectToButton
      if (connectorConnected || canCreateWithoutConnection(type))
        return createProjectButton
    } else {
      if (
        modalConfiguration ||
        (!connectorConnected && modalOnlyWhenNotConnected)
      )
        return openModalButton
      if (redirectTo) return redirectToButton
      if (type === CONNECTOR_TYPES.zeroconf) return createProjectButton
    }

    return null
  }

  return (
    <div className="connector-card">
      <header className="d-flex align-items-center mt-2">
        <Image
          className="connector-card--image"
          src={(logo) ? logo : connectorLogoURL()}
          onError={e => {
            if (e.target.src !== connectorLogoURL())
              e.target.src = connectorLogoURL()
          }}
        />
        <div className="d-flex flex-column ml-3">
          <h4 className="inline-block mb-0 font-bold text-gray-800">{name}</h4>
          {status === CONNECTOR_STATUSES.preview && (
            <div className="connector-card__badge d-flex align-items-center">
              <Icon className="mr-2" name="eye" />
              <span className="font-weight-bold mb-0">
                <FormattedMessage
                  id="components.connectors.publicConnector.preview"
                  defaultMessage="Preview"
                />
              </span>
            </div>
          )}
        </div>
      </header>
      <div className="mt-3">
        <Configuration />
      </div>
    </div>
  )
}

export default PublicConnector
