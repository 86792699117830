import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { useStoreActions, useStoreState } from "easy-peasy"
import Form from "../../forms/Form"
import { CONNECTORS } from "../../../store/models/connectors"
import ControlFooter from "./ControlFooter"
import commonFields, { gitFields } from "./commonFields"
import { defaultBranchOption } from "../../../helpers/git"

const GitlabForm = ({ location }) => {
  const intl = useIntl()

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
  })

  const fetchGitlabRepos = useStoreActions(actions => actions.gitlabRepos.fetch)
  const fetchGitlabBranches = useStoreActions(
    actions => actions.gitlabBranches.fetch
  )

  const gitlabRepos = useStoreState(state => state.gitlabRepos.items)
  const gitlabBranches = useStoreState(state => state.gitlabBranches.items)
  const setBranches = useStoreActions(actions => actions.gitlabBranches.set)

  const currentOrg = useStoreState(state => state.currentOrg.item)

  const setFormField = useStoreActions(
    actions => actions.projectWizard.setFormField
  )
  const formValues = useStoreState(state => state.projectWizard.formValues)

  const fetchConnectors = useStoreActions(actions => actions.connectors.fetch)
  const connectors = useStoreState(state => state.connectors.items)

  const repoSelect = useWatch({
    control,
    name: "project[repository]",
  })

  useEffect(() => {
    if (currentOrg) fetchGitlabRepos({ organizationID: currentOrg.id })
  }, [currentOrg, fetchGitlabRepos])

  const formRepoId = formValues?.project?.repository?.id
  const repoId = repoSelect?.id
  const repoName = repoSelect?.name

  useEffect(() => {
    if (!repoId || !currentOrg) return

    const name = repoName.split("/")[1]
    setValue("project[name]", name)

    if (formRepoId !== repoId) {
      setBranches([])
      fetchGitlabBranches({
        repoName: repoName,
        organizationID: currentOrg.id,
      })
    }
  }, [
    currentOrg,
    fetchGitlabBranches,
    formRepoId,
    repoId,
    repoName,
    setBranches,
    setValue,
  ])

  useEffect(() => {
    const connector = connectors.find(
      connector => connector.id === CONNECTORS.gitlab
    )

    if (connector) setValue("project[connector]", connector)
    else fetchConnectors()
  }, [connectors, fetchConnectors, setValue])

  useEffect(() => {
    if (!gitlabRepos.length) return

    if (!formValues?.project?.repository) {
      setValue("project[repository]", gitlabRepos[0])
    } else {
      setValue("project[repository]", formValues.project.repository)
    }
  }, [gitlabRepos, formValues?.project?.repository])

  useEffect(() => {
    if (!gitlabBranches.length) {
      setValue("project[branch]", null)
      return
    }

    const selectedAnotherRepo =
      formValues?.project?.repository?.id !== repoSelect?.id
    if (!formValues || selectedAnotherRepo) {
      setValue("project[branch]", defaultBranchOption(gitlabBranches))
    } else {
      setValue("project[branch]", formValues?.project?.branch)
    }
  }, [formValues, gitlabBranches, repoSelect])

  const gitlabFields = [
    ...commonFields,
    ...gitFields(
      intl,
      null,
      {
        data: gitlabRepos,
        isLoading: !gitlabRepos.length,
      },
      {
        data: gitlabBranches,
        isLoading: !gitlabBranches.length,
        isDisabled: !repoSelect,
      }
    ),
    { type: "divider" },
  ]

  const onSubmit = values => {
    const project = {
      ...formValues?.project,
      ...values.project,
      owner: currentOrg.owner,
      organizationID: currentOrg.id,
    }

    setFormField({
      project,
      type: CONNECTORS.gitlab,
    })
  }

  return (
    <Form
      title={intl.formatMessage({
        id: "components.projects.form.formTitle",
        defaultMessage: "Add a project for translation",
      })}
      errors={errors}
      fields={gitlabFields}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        onNext={onSubmit}
      />
    </Form>
  )
}

export default GitlabForm
