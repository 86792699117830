import { computed } from "easy-peasy"

export default {
  items: [
    { name: "Abkhazian", language: "ab", region: "", id: "ab" },
    { name: "Afar", language: "aa", region: "", id: "aa" },
    { name: "Afrikaans", language: "af", region: "", id: "af" },
    { name: "Albanian", language: "sq", region: "", id: "sq" },
    { name: "Amharic", language: "am", region: "", id: "am" },
    { name: "Arabic", language: "ar", region: "", id: "ar" },
    { name: "Armenian", language: "hy", region: "", id: "hy" },
    { name: "Assamese", language: "as", region: "", id: "as" },
    { name: "Aymara", language: "ay", region: "", id: "ay" },
    { name: "Azerbaijani", language: "az", region: "", id: "az" },
    { name: "Bashkir", language: "ba", region: "", id: "ba" },
    { name: "Basque", language: "eu", region: "", id: "eu" },
    { name: "Belarusian", language: "be", region: "", id: "be" },
    { name: "Bengali", language: "bn", region: "", id: "bn" },
    { name: "Bhutani", language: "dz", region: "", id: "dz" },
    { name: "Bihari", language: "bh", region: "", id: "bh" },
    { name: "Bislama", language: "bi", region: "", id: "bi" },
    { name: "Bosnian", language: "bos", region: "", id: "bos" },
    { name: "Breton", language: "br", region: "", id: "br" },
    { name: "Bulgarian", language: "bg", region: "", id: "bg" },
    { name: "Burmese", language: "bur", region: "", id: "bur" },
    { name: "Catalan", language: "ca", region: "", id: "ca" },
    { name: "Cebuano", language: "ceb", region: "", id: "ceb" },
    { name: "Chechen", language: "che", region: "", id: "che" },
    { name: "Chinese (Simplified)", language: "zh", region: "S", id: "zh-S" },
    { name: "Chinese (Traditional)", language: "zh", region: "T", id: "zh-T" },
    { name: "Corsican", language: "co", region: "", id: "co" },
    { name: "Croatian", language: "hr", region: "", id: "hr" },
    { name: "Czech", language: "cs", region: "", id: "cs" },
    { name: "Danish", language: "da", region: "", id: "da" },
    { name: "Dutch", language: "nl", region: "", id: "nl" },
    { name: "English", language: "en", region: "", id: "en" },
    { name: "English (Australia)", language: "en", region: "AU", id: "en-AU" },
    {
      name: "English (United Kingdom)",
      language: "en",
      region: "GB",
      id: "en-GB",
    },
    {
      name: "English (United States)",
      language: "en",
      region: "US",
      id: "en-US",
    },
    { name: "Esperanto", language: "eo", region: "", id: "eo" },
    { name: "Estonian", language: "et", region: "", id: "et" },
    { name: "Faroese", language: "fo", region: "", id: "fo" },
    { name: "Fiji", language: "fj", region: "", id: "fj" },
    { name: "Filipino", language: "fil", region: "", id: "fil" },
    { name: "Finnish", language: "fi", region: "", id: "fi" },
    { name: "Flemish", language: "fl", region: "", id: "fl" },
    { name: "French", language: "fr", region: "", id: "fr" },
    { name: "French (Belgium)", language: "fr", region: "NL", id: "fr-NL" },
    { name: "French (Canada)", language: "fr", region: "CA", id: "fr-CA" },
    { name: "Frisian", language: "fy", region: "", id: "fy" },
    { name: "Galician", language: "gl", region: "", id: "gl" },
    { name: "Georgian", language: "ka", region: "", id: "ka" },
    { name: "German", language: "de", region: "", id: "de" },
    { name: "German (Austria)", language: "de", region: "AT", id: "de-AT" },
    { name: "German (Swiss)", language: "de", region: "CH", id: "de-CH" },
    { name: "Greek", language: "el", region: "", id: "el" },
    { name: "Greenlandic", language: "kl", region: "", id: "kl" },
    { name: "Guarani", language: "gn", region: "", id: "gn" },
    { name: "Gujarati", language: "gu", region: "", id: "gu" },
    { name: "Hausa", language: "ha", region: "", id: "ha" },
    { name: "Hebrew", language: "he", region: "", id: "he" },
    { name: "Hindi", language: "hi", region: "", id: "hi" },
    { name: "Hmong", language: "hm", region: "", id: "hm" },
    { name: "Hungarian", language: "hu", region: "", id: "hu" },
    { name: "Icelandic", language: "is", region: "", id: "is" },
    { name: "Indonesian", language: "id", region: "", id: "id" },
    { name: "Interlingua", language: "ia", region: "", id: "ia" },
    { name: "Interlingue", language: "ie", region: "", id: "ie" },
    { name: "Inuktitut", language: "iu", region: "", id: "iu" },
    { name: "Inupiak", language: "ik", region: "", id: "ik" },
    { name: "Irish", language: "ga", region: "", id: "ga" },
    { name: "Italian", language: "it", region: "", id: "it" },
    { name: "Japanese", language: "ja", region: "", id: "ja" },
    { name: "Javanese", language: "jw", region: "", id: "jw" },
    { name: "Kannada", language: "kn", region: "", id: "kn" },
    { name: "Kashmiri", language: "ks", region: "", id: "ks" },
    { name: "Kazakh", language: "kk", region: "", id: "kk" },
    { name: "Khmer-Cambodian", language: "km", region: "", id: "km" },
    { name: "Kinyarwanda", language: "rw", region: "", id: "rw" },
    { name: "Kirghiz", language: "ky", region: "", id: "ky" },
    { name: "Kirundi", language: "rn", region: "", id: "rn" },
    { name: "Korean", language: "ko", region: "", id: "ko" },
    { name: "Kurdish", language: "ku", region: "", id: "ku" },
    { name: "Laothian", language: "lo", region: "", id: "lo" },
    { name: "Latin", language: "la", region: "", id: "la" },
    { name: "Latvian", language: "lv", region: "", id: "lv" },
    { name: "Lingala", language: "ln", region: "", id: "ln" },
    { name: "Lithuanian", language: "lt", region: "", id: "lt" },
    { name: "Macedonian", language: "mk", region: "", id: "mk" },
    { name: "Malagasy", language: "mg", region: "", id: "mg" },
    { name: "Malay", language: "ms", region: "", id: "ms" },
    { name: "Malayalam", language: "ml", region: "", id: "ml" },
    { name: "Maltese", language: "mt", region: "", id: "mt" },
    { name: "Maori", language: "mi", region: "", id: "mi" },
    { name: "Marathi", language: "mr", region: "", id: "mr" },
    { name: "Moldavian", language: "mo", region: "", id: "mo" },
    { name: "Mongolian", language: "mn", region: "", id: "mn" },
    { name: "Montenegrin", language: "sr", region: "ME", id: "sr-ME" },
    { name: "Nauru", language: "na", region: "", id: "na" },
    { name: "Nepali", language: "ne", region: "", id: "ne" },
    { name: "Norwegian (Bokmål)", language: "no", region: "", id: "no" },
    { name: "Norwegian (Nynorsk)", language: "nn", region: "", id: "nn" },
    { name: "Occitan", language: "oc", region: "", id: "oc" },
    { name: "Oriya", language: "or", region: "", id: "or" },
    { name: "Oromo", language: "om", region: "", id: "om" },
    { name: "Pashto", language: "ps", region: "", id: "ps" },
    { name: "Persian", language: "fa", region: "", id: "fa" },
    { name: "Polish", language: "pl", region: "", id: "pl" },
    { name: "Portuguese", language: "pt", region: "", id: "pt" },
    { name: "Portuguese (Brazil)", language: "pt", region: "BR", id: "pt-BR" },
    {
      name: "Portuguese (Latin America)",
      language: "pla",
      region: "",
      id: "pla",
    },
    { name: "Punjabi", language: "pa", region: "", id: "pa" },
    { name: "Quechua", language: "qu", region: "", id: "qu" },
    { name: "Rhaeto-Romance", language: "rm", region: "", id: "rm" },
    { name: "Romanian", language: "ro", region: "", id: "ro" },
    { name: "Russian", language: "ru", region: "", id: "ru" },
    { name: "Samoan", language: "sm", region: "", id: "sm" },
    { name: "Sangho", language: "sg", region: "", id: "sg" },
    { name: "Sanskrit", language: "sa", region: "", id: "sa" },
    { name: "Scots", language: "sco", region: "", id: "sco" },
    { name: "Serbian", language: "sr", region: "", id: "sr" },
    { name: "Sesotho", language: "st", region: "", id: "st" },
    { name: "Setswana", language: "tn", region: "", id: "tn" },
    { name: "Shona", language: "sn", region: "", id: "sn" },
    { name: "Sindhi", language: "sd", region: "", id: "sd" },
    { name: "Sinhalese", language: "si", region: "", id: "si" },
    { name: "Siswati", language: "ss", region: "", id: "ss" },
    { name: "Slovak", language: "sk", region: "", id: "sk" },
    { name: "Slovenian", language: "sl", region: "", id: "sl" },
    { name: "Somali", language: "so", region: "", id: "so" },
    { name: "Spanish", language: "es", region: "", id: "es" },
    {
      name: "Spanish (Latin America)",
      language: "es",
      region: "SLA",
      id: "es-SLA",
    },
    { name: "Spanish (Mexico)", language: "es", region: "MX", id: "es-MX" },
    {
      name: "Spanish (United States)",
      language: "es",
      region: "US",
      id: "es-US",
    },
    { name: "Sundanese", language: "su", region: "", id: "su" },
    { name: "Swahili", language: "sw", region: "", id: "sw" },
    { name: "Swedish", language: "sv", region: "", id: "sv" },
    { name: "Swedish (Finland)", language: "sv", region: "FI", id: "sv-FI" },
    { name: "Tagalog", language: "tl", region: "", id: "tl" },
    { name: "Tajik", language: "tg", region: "", id: "tg" },
    { name: "Tamil", language: "ta", region: "", id: "ta" },
    { name: "Tatar", language: "tt", region: "", id: "tt" },
    { name: "Telugu", language: "te", region: "", id: "te" },
    { name: "Thai", language: "th", region: "", id: "th" },
    { name: "Tibetan", language: "bo", region: "", id: "bo" },
    { name: "Tigrinya", language: "ti", region: "", id: "ti" },
    { name: "Tonga", language: "to", region: "", id: "to" },
    { name: "Tsonga", language: "ts", region: "", id: "ts" },
    { name: "Turkish", language: "tr", region: "", id: "tr" },
    { name: "Turkmen", language: "tk", region: "", id: "tk" },
    { name: "Twi", language: "tw", region: "", id: "tw" },
    { name: "Uighur", language: "ug", region: "", id: "ug" },
    { name: "Ukrainian", language: "uk", region: "", id: "uk" },
    { name: "Urdu", language: "ur", region: "", id: "ur" },
    { name: "Uzbek", language: "uz", region: "", id: "uz" },
    { name: "Vietnamese", language: "vi", region: "", id: "vi" },
    { name: "Volapuk", language: "vo", region: "", id: "vo" },
    { name: "Welsh", language: "cy", region: "", id: "cy" },
    { name: "Wolof", language: "wo", region: "", id: "wo" },
    { name: "Xhosa", language: "xh", region: "", id: "xh" },
    { name: "Yiddish", language: "yi", region: "", id: "yi" },
    { name: "Yoruba", language: "yo", region: "", id: "yo" },
    { name: "Zarma", language: "dje", region: "", id: "dje" },
    { name: "Zhuang", language: "za", region: "", id: "za" },
    { name: "Zulu", language: "zu", region: "", id: "zu" },
  ],

  // computed
  byId: computed(state => id => state.items.find(locale => locale.id === id)),
}
