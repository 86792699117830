// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akeneo-callback-js": () => import("./../../../src/pages/akeneo/callback.js" /* webpackChunkName: "component---src-pages-akeneo-callback-js" */),
  "component---src-pages-azure-callback-js": () => import("./../../../src/pages/azure/callback.js" /* webpackChunkName: "component---src-pages-azure-callback-js" */),
  "component---src-pages-bitbucket-callback-js": () => import("./../../../src/pages/bitbucket/callback.js" /* webpackChunkName: "component---src-pages-bitbucket-callback-js" */),
  "component---src-pages-dropbox-callback-js": () => import("./../../../src/pages/dropbox/callback.js" /* webpackChunkName: "component---src-pages-dropbox-callback-js" */),
  "component---src-pages-github-callback-js": () => import("./../../../src/pages/github/callback.js" /* webpackChunkName: "component---src-pages-github-callback-js" */),
  "component---src-pages-github-signup-js": () => import("./../../../src/pages/github/signup.js" /* webpackChunkName: "component---src-pages-github-signup-js" */),
  "component---src-pages-googledocs-callback-js": () => import("./../../../src/pages/googledocs/callback.js" /* webpackChunkName: "component---src-pages-googledocs-callback-js" */),
  "component---src-pages-googlesheets-callback-js": () => import("./../../../src/pages/googlesheets/callback.js" /* webpackChunkName: "component---src-pages-googlesheets-callback-js" */),
  "component---src-pages-hubspot-cms-callback-js": () => import("./../../../src/pages/hubspot-cms/callback.js" /* webpackChunkName: "component---src-pages-hubspot-cms-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intercom-callback-js": () => import("./../../../src/pages/intercom/callback.js" /* webpackChunkName: "component---src-pages-intercom-callback-js" */),
  "component---src-pages-lokalise-callback-js": () => import("./../../../src/pages/lokalise/callback.js" /* webpackChunkName: "component---src-pages-lokalise-callback-js" */),
  "component---src-pages-mailchimp-callback-js": () => import("./../../../src/pages/mailchimp/callback.js" /* webpackChunkName: "component---src-pages-mailchimp-callback-js" */),
  "component---src-pages-memsource-callback-js": () => import("./../../../src/pages/memsource/callback.js" /* webpackChunkName: "component---src-pages-memsource-callback-js" */),
  "component---src-pages-protemos-js": () => import("./../../../src/pages/protemos.js" /* webpackChunkName: "component---src-pages-protemos-js" */),
  "component---src-pages-salesforce-knowledge-callback-js": () => import("./../../../src/pages/salesforce-knowledge/callback.js" /* webpackChunkName: "component---src-pages-salesforce-knowledge-callback-js" */),
  "component---src-pages-shopify-callback-js": () => import("./../../../src/pages/shopify/callback.js" /* webpackChunkName: "component---src-pages-shopify-callback-js" */),
  "component---src-pages-storage-clear-js": () => import("./../../../src/pages/storage/clear.js" /* webpackChunkName: "component---src-pages-storage-clear-js" */),
  "component---src-pages-wpml-callback-js": () => import("./../../../src/pages/wpml/callback.js" /* webpackChunkName: "component---src-pages-wpml-callback-js" */),
  "component---src-pages-zendesk-callback-js": () => import("./../../../src/pages/zendesk/callback.js" /* webpackChunkName: "component---src-pages-zendesk-callback-js" */)
}

