import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"
import ProjectLocale from "../../models/projectLocale"
import Project from "../../models/project"

export default {
  item: null,

  // Thunks
  triggerInstance: thunk(
    async (_actions, { integration, template, mode, projectLocaleIDs }, helper) => {
      const { item } = helper.getState()
      return API.post("nepal", "/instance/trigger", {
        body: {
          projectID: item.id,
          mode: mode,
          projectLocaleIDs,
          integrationID: integration?.id,
          projectTemplateID: integration && template ? template.id : undefined,
        },
      })
    }
  ),

  triggerProInstance: thunk(
    async (_actions, { mode, paymentSourceID, projectLocaleIDs }, helper) => {
      const { item } = helper.getState()
      return API.post("nepal", "/instances/pro", {
        body: {
          paymentSourceID,
          projectID: item.id,
          mode,
          projectLocaleIDs,
        },
      })
    }
  ),

  triggerAIInstance: thunk(
    async (_actions, { mode, projectLocaleIDs }, helper) => {
      const { item } = helper.getState()
      return API.post("nepal", "/instances/ai", {
        body: {
          projectID: item.id,
          mode,
          projectLocaleIDs,
        },
      })
    }
  ),

  // Actions
  set: action((state, project) => (state.item = project)),

  updateLocale: action((state, projectLocale) => {
    const index = state.item.locales.items.findIndex((locale) => (locale.id === projectLocale.id))

    let items = [];

    if (index === -1) {
      items = state.item.locales.items.push(projectLocale)
    } else {
      state.item.locales.items[index] = projectLocale
      items = state.item.locales.items
    }

    state.item = new Project({
      ...state.item,
      locales: {
        items
      }
    })
  })
}
