import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  items: [],

  fetch: thunk(async (actions, { organizationID }) => {
    try {
      const params = {
        queryStringParameters: {
          organizationID,
        },
      }
      const gitlabRepos = await API.get("nepal", "/gitlab/repos", params)
      actions.set(gitlabRepos)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  set: action((state, gitlabRepos) => {
    state.items = gitlabRepos
  }),
}
