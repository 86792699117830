import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Form from "../../forms/Form";
import ControlFooter from "./ControlFooter";
import { useIntl, navigate } from "gatsby-plugin-intl";
import { useStoreActions, useStoreState } from "easy-peasy";
import { API } from "aws-amplify"
import { fieldRequired } from "../../../utils/validations";
import { mapTypeToFieldType } from "../../../helpers/cuisto";
import { WIZARD_ROOT } from "../../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../../store/models/projectWizard"
import Spinner from "../../common/Spinner"

const CuistoConnectorForm = ({ connector: { id, name } }) => {
  const [dependsOn, setDependsOn] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl()
  const setFormField = useStoreActions(actions => actions.projectWizard.setFormField)
  const formValues = useStoreState(state => state.projectWizard.formValues)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const { nextPath } = useStoreState(state => state.projectWizard)
  const { nextStep, setIgnoredStepKey } = useStoreActions(
    actions => actions.projectWizard
  )

  useEffect(() => {
    if (isLoading) return

    setIsLoading(true);
    API.get("nepal", `/cuisto/connectors/${id}/config`)
    .then((data) => {
      const configuration = data?.configuration ?? {};
      setDependsOn(configuration)
      if(Object.keys(configuration).length === 0){
        setIgnoredStepKey(WIZARD_ROUTES.CONNECTOR.key)
        onSubmit({})
        navigate(`${WIZARD_ROOT}${nextPath}`)
        nextStep()
      }
    }).catch()
    .finally(() => setIsLoading(false))
  }, [id])

  const { handleSubmit, errors, control } = useForm({
    mode: "onChange",
  })

  const onSubmit = (values) => {
    const project = {
      ...values.project,
      owner: currentOrg.owner,
      organizationID: currentOrg.id,
    }
    project.cuistoConfig = {
      ...(project.cuistoConfig || {}),
      ...(formValues?.project?.cuistoConfig || {}),
    };
    setFormField({
      project: {
        ...formValues?.project,
        ...project,
      },
      type: id,
    })
  }

  
  const fields = Object.entries(dependsOn).map(([key, { type, name, description, options }]) => ({
    validations: { ...fieldRequired },
    description,
    label: name ?? key,
    name: `project[cuistoConfig][${key}]`,
    type: mapTypeToFieldType(type),
    options
  }));

  return (
    <>
      {fields.length ? (
        <Form
          title={intl.formatMessage(
            {
              id: "components.projects.form.formTitle",
              defaultMessage: "Setup your {name} project",
            },
            { name }
          )}
          errors={errors}
          fields={fields}
          control={control}
          className="project-form"
        >
          <ControlFooter
            errors={errors}
            withBack
            handleSubmit={handleSubmit}
            onNext={onSubmit}
          />
        </Form>
      ) : (
        <div className="d-flex justify-content-end">
          <Spinner />
        </div>
      )}
    </>
  )
}

export default CuistoConnectorForm;
