import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  items: [],

  fetch: thunk(async (actions, { organizationID, type, hubId }) => {
    try {
      if (type === undefined) return
      const params = {
        queryStringParameters: {
          organizationID,
          hubId
        }
      }
      const route = type === "Blog" ? "/hubspot-cms/blogs" : "/hubspot-cms/domains"
      const hubspotcmsResources = await API.get("nepal", route, params)
      actions.setItems(hubspotcmsResources)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  setItems: action((state, items) => {
    state.items = items
  }),
}
