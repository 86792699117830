import React from "react"
import { useForm } from "react-hook-form"
import { useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Alert } from "react-bootstrap"
import Form from "../forms/Form"
import Spinner from "../common/Spinner"

const ContentfulTokenForm = ({ name }) => {
  const { control, errors } = useForm()
  const currentOrg = useStoreState(state => state.currentOrg.item)

  if (!currentOrg?.id) {
    return (
      <div className='pr-4'>
        <Spinner />
      </div>
    )
  }

  const fields = [
    {
      name: "organizationID",
      type: "copyToClip",
      label: "",
      defaultValue: currentOrg.id
    },
  ]

  return (
    <div className='pr-4'>
      <p>
        <FormattedMessage
          id="components.contentful.tokenForm.paragraph1"
          defaultMessage={`Click the link below to setup
          a new {name} project. Enter the following
          key during the installation.`}
          values={{ name }}
        />
      </p>
      <p>
        <Form className="my-3" fields={fields} control={control} errors={errors} />
      </p>
      <p>
        <Alert.Link href={process.env.CONTENTFUL_APP_INSTALLATION_URL}>
          <FormattedMessage
            id="components.contentful.tokenForm.alertLink"
            defaultMessage="Proceed to App installation"
          />
        </Alert.Link>
      </p>
    </div>
  )
}

export default ContentfulTokenForm
