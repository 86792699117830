import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"

const Shopify = ({ name }) => {

  const storeNameModalAction = () => {
    return (
      <Button
        variant="primary"
        className="btn btn--primary"
        block
        onClick={(() => window.location.href = process.env.SHOPIFY_APP_URL)}
      >
        <FormattedMessage
          id="components.shopify.connect.button"
          defaultMessage={`Connect ${name}`}
        />
      </Button>
    )
  }

  return (
    <div className="mt-4 d-flex flex-column">
      <h2>
        <FormattedMessage
          id="components.shopify.title"
          defaultMessage={`How to get your {name} store set up with Locale`}
          values={{ name }}
        />
      </h2>
      <div className="d-flex flex-column">
        {[
          {
            header: (
              <FormattedMessage
                id="components.shopify.listItem1.header"
                defaultMessage={`Connect your {name} store`}
                values={{ b: msg => <b>{msg}</b>, name }}
              />
            ),
            description: (
              <FormattedMessage
                id="components.shopify.listItem1.description"
                defaultMessage={`
                  Click <b>Connect</b> button below.
                  You will be redirected to Shopify to install the Locale app on your store.
                  After accepting the permissions, you are brought back to Locale to continue setup.
                `}
                values={{ b: msg => <b>{msg}</b>, name }}
              />
            ),
          },
          {
            header: (
              <FormattedMessage
                id="components.shopify.listItem2.header"
                defaultMessage={`Set up your {name} project`}
                values={{ b: msg => <b>{msg}</b>, name }}
              />
            ),
            description: (
              <FormattedMessage
                id="components.shopify.listItem2.description"
                defaultMessage={`
                  Select your source language and the target languages. You can always change this later.
                `}
                values={{ b: msg => <b>{msg}</b> }}
              />
            ),
          },
          {
            header: (
              <FormattedMessage
                id="components.shopify.listItem3.header"
                defaultMessage="Choose what you want to translate"
                values={{ b: msg => <b>{msg}</b> }}
              />
            ),
            description: (
              <FormattedMessage
                id="components.shopify.listItem3.description"
                defaultMessage={`
                  In the Languages tab of your {name} project you can add more target languages.
                  On the Content tab, you can select what content you want to have translated.
                `}
                values={{ b: msg => <b>{msg}</b>, name }}
              />
            ),
          },
        ].map(({ header, description }, index) => (
          <div className="mb-3" key={index}>
            <div className="d-flex align-items-center mb-3">
              <h4 className="m-0 mr-3 px-2 py-1 bg-gray-200 rounded-circle">
                {index + 1}
              </h4>
              <h3 className="m-0">{header}</h3>
            </div>
            <p style={{ marginLeft: "2.1rem" }}>{description}</p>
          </div>
        ))}
      </div>
      {storeNameModalAction()}
    </div>
  )
}

export default Shopify
