import { action, computed, thunk } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { getIntegration, listIntegrationsByOrganization, listIntegrationsByVendor } from "../../graphql/locale-queries"
import { createIntegration, updateIntegration } from "../../graphql/locale-mutations"
export default {
  item: {},
  items: [],
  publicIntegrations: [],

  availableIntegrations: computed((state) => [...state.items, ...state.publicIntegrations]),
  find: thunk(async (actions, id) => {
    try {
      const { data } = await API.graphql(graphqlOperation(getIntegration, { id }))
      actions.setItem(data.getIntegration);
    } catch (err) {
      console.error(err);
    }

    return null;
  }),

  fetch: thunk(async (actions, { organizationID, vendorID }) => {
    const data = organizationID
      ? (await API.graphql(graphqlOperation(listIntegrationsByOrganization, { organizationID }))).data.listIntegrationsByOrganization.items
      : (await API.graphql(graphqlOperation(listIntegrationsByVendor, { vendorID }))).data.listIntegrationsByVendor.items

    const dataWithParsedConfig = data.map((integration) => {
      integration.integrationType.configuration = integration.integrationType.configuration
        ? JSON.parse(integration.integrationType.configuration)
        : {}

      return integration
    })

    actions.setItems(dataWithParsedConfig || [])
  }),

  fetchPublicIntegrations: thunk(async (actions, { organizationID }) => {
    const data = await API.get("nepal", "/public-integrations", {
      queryStringParameters: {
        organizationID,
      }
    })
    actions.setPublicIntegrations(data || [])
  }),

  fetchAvailableIntegrations: thunk(async (actions, params) => {
    await actions.fetch(params)
    await actions.fetchPublicIntegrations(params)
  }),

  update: thunk(async (actions, input) => {
    try {
      actions.updateItem(input)
      const { data } = await API.graphql(graphqlOperation(updateIntegration, { input }))
      return data.updateIntegration
    } catch (error) {
      console.error(error)
    }
  }),

  create: thunk(async (_, input, helpers) => {
    try {
      const { data } = await API.graphql(graphqlOperation(createIntegration, { input }))
      if (data.createIntegration.vendorID) {
        helpers.getStoreActions().currentVendor.addIntegration(data.createIntegration)
      }
      return data.createIntegration
    } catch (e) {
      console.error(e)
    }
  }),

  reset: action(state => {
    state.item = {}
  }),

  setItem: action((state, payload) => {
    state.item = payload
  }),


  setItems: action((state, payload) => {
    state.items = payload
  }),

  setPublicIntegrations: action((state, payload) => {
    state.publicIntegrations = payload;
  }),

  updateItem: action((state, payload) => {
    state.item = {
      ...state.item,
      ...payload,
    }
  }),
}
