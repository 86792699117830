import { action, thunk } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listAccessRequests } from "../../graphql/locale-queries"
import { createAccessRequest } from "../../graphql/locale-mutations"

export default {
  items: [],

  // thunks
  fetch: thunk(async (actions, userID) => {
    try {
      const accessRequests = []
      let nextToken
      do {
        const { data } = await API.graphql(
          graphqlOperation(listAccessRequests, {
            filter: { owner: { eq: userID } },
            nextToken,
          })
        )
        nextToken = data.listAccessRequests.nextToken
        accessRequests.push(...data.listAccessRequests.items)
      } while (nextToken)
      actions.set(accessRequests)
    } catch (e) {
      console.error(e)
    }
  }),

  create: thunk(async (actions, payload) => {
    const { data } = await API.graphql(
      graphqlOperation(createAccessRequest, { input: { ...payload } })
    )
    actions.push(data.createAccessRequest)
  }),

  // actions
  set: action((state, accessRequests) => {
    state.items = accessRequests
  }),

  push: action((state, accessRequest) => {
    state.items.push(accessRequest)
  }),
}
