import { API } from "aws-amplify"

export const triggerQuote = ({ projectID, projectLocaleIDs }) => {
  API.post("nepal", "/instance/trigger", {
    body: {
      mode: "quote",
      projectID,
      projectLocaleIDs,
    },
  })
}
