import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { useStoreActions, useStoreState } from "easy-peasy"
import Form from "../../forms/Form"
import ControlFooter from "./ControlFooter"
import { formatFields } from "./commonFields"
import { Setup } from "./Layouts"

const FormatForm = ({ location }) => {
  const intl = useIntl()

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
  })

  const formats = useStoreState(state => state.formats.listable)

  const setFormField = useStoreActions(
    actions => actions.projectWizard.setFormField
  )
  const formValues = useStoreState(state => state.projectWizard.formValues)

  const formatSelect = useWatch({
    control,
    name: "project[format]",
  })

  useEffect(() => {
    setValue("project[format]", formValues?.project?.format)
    setValue(
      "project[resourcePatterns][0][path]",
      formValues?.project?.resourcePatterns?.[0]?.path || ""
    )
  }, [formValues, setValue])

  useEffect(() => {
    const pathPattern = formatSelect?.path || ""
    setValue("project[resourcePatterns][0][path]", pathPattern)
  }, [formatSelect])

  const fields = [
    ...formatFields({
      intl,
      format: { data: formats, isLoading: !formats.length },
      resource: { isDisabled: !formatSelect?.path },
    }),
    { type: "divider" },
  ]

  const onSubmit = values => {
    setFormField({
      ...formValues,
      project: {
        ...formValues?.project,
        ...values.project,
      }
    })
  }

  return (
    <Form
      title={intl.formatMessage({
        id: "components.projects.form.formTitle",
        defaultMessage: "Add a project for translation",
      })}
      errors={errors}
      fields={fields}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        onNext={onSubmit}
      />
    </Form>
  )
}

export default FormatForm
