import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  items: [],
  teams: [],
  teamUsers: [],

  fetch: thunk(async (actions, { organizationID }) => {
    try {
      const params = {
        queryStringParameters: {
          organizationID,
        },
      }
      const projects = await API.get("nepal", "/lokalise/projects", params)
      actions.set(projects)
    } catch (e) {
      console.error(e)
    }
  }),

  fetchTeams: thunk(async (actions, { organizationID }) => {
    try {
      const params = {
        queryStringParameters: {
          organizationID,
        },
      }
      const teams = await API.get("nepal", "/lokalise/teams", params)
      actions.setTeams(teams)
    } catch (e) {
      console.error(e)
    }
  }),

  fetchTeamUsers: thunk(async (actions, { organizationID, teamID }) => {
    try {
      const params = {
        queryStringParameters: {
          organizationID,
        },
      }
      actions.setTeamUsers([])
      const teamUsers = await API.get("nepal", `/lokalise/teams/${teamID}/users`, params)
      actions.setTeamUsers(teamUsers)
    } catch (e) {
      console.error(e)
    }
  }),

  addWebhook: thunk(async (_, { projectID, organizationID }) => {
    const data = await API.post("nepal", "/lokalise/projects/webhooks", {
      queryStringParameters: { organizationID },
      body: {
        projectID
      },
    })

    return data.webhook.secret
  }),

  // actions
  set: action((state, projects) => {
    state.items = projects
  }),

  setTeams: action((state, teams) => {
    state.teams = teams;
  }),

  setTeamUsers: action((state, teamUsers) => {
    state.teamUsers = teamUsers
  }),
}
