import { API_CONNECTORS, CONNECTORS } from "../store/models/connectors"

export const hasTestApiKey = project => {
  switch (project?.connector?.type) {
    case CONNECTORS.api:
    case CONNECTORS.zapier:
      return true
    default:
      return false
  }
}

export const hasApiKey = connectorId => {
  switch (connectorId) {
    case CONNECTORS.api:
    case CONNECTORS.figma:
    case CONNECTORS.zapier:
      return true;
    default:
      return false;
  }
}

export const isEmailable = project => {
  switch (project?.connector?.type) {
    default:
      return false
  }
}

export const isResourceable = project => {
  switch (project?.connector?.type) {
    case CONNECTORS.azurerepos:
    case CONNECTORS.bitbucket:
    case CONNECTORS.github:
    case CONNECTORS.gitlab:
      return true
    default:
      return false
  }
}

export const isContentable = project => {
  switch (project?.connector?.type) {
    case CONNECTORS.contentful:
    case CONNECTORS.googleSheets:
    case CONNECTORS.hubspotcms:
    case CONNECTORS.intercom:
    case CONNECTORS.mailchimp:
    case CONNECTORS.salesforceknowledge:
    case CONNECTORS.shopify:
    case CONNECTORS.zendesk:
      return true
    default:
      return false
  }
}

export const isCuistoProject = project => {
  return project.connector.isCuisto
}

export const isWebhookable = project => {
  switch (project?.connector?.type) {
    case CONNECTORS.api:
      return true
    default:
      return false
  }
}

export const isAPI = project =>
  API_CONNECTORS.includes(project?.connector?.type)
