import React, { useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"
import { useStoreActions, useStoreState } from "easy-peasy"
import Form from "../../forms/Form"
import { CONNECTORS } from "../../../store/models/connectors"
import ControlFooter from "./ControlFooter"
import commonFields, {
  apiFields,
  formatFields,
  webhookFields,
} from "./commonFields"

const ApiForm = ({ connectorId = CONNECTORS.api }) => {
  const intl = useIntl()

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
  })

  const apiFormat = useStoreState(state => state.formats.api)
  const currentOrg = useStoreState(state => state.currentOrg.item)

  const setFormField = useStoreActions(
    actions => actions.projectWizard.setFormField
  )
  const formValues = useStoreState(state => state.projectWizard.formValues)

  useEffect(() => {
    if (formValues) {
      setValue("project[webhookURL]", formValues.project.webhookURL)
    }
  }, [formValues, setValue])

  const fields = [
    ...webhookFields(intl),
    { type: "divider" },
  ]

  const onSubmit = values => {
    const project = {
      ...values.project,
      owner: currentOrg.owner,
      organizationID: currentOrg.id,
    }

    setFormField({
      project: {
        ...formValues?.project,
        ...project,
      },
      type: connectorId,
    })
  }

  return (
    <Form
      title={intl.formatMessage({
        id: "components.projects.form.formTitle",
        defaultMessage: "Add a project for translation",
      })}
      errors={errors}
      fields={fields}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        onNext={onSubmit}
      />
    </Form>
  )
}

export default ApiForm
