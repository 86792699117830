import "@formatjs/intl-displaynames/polyfill"
import "@formatjs/intl-locale/polyfill"
import "@formatjs/intl-displaynames/locale-data/en"
import "@formatjs/intl-displaynames/locale-data/fr"
import { byName } from "../utils/sort"

export const localeOptionsForSelect = (locales, intl) => {
  return locales
    .map(locale => ({
      name: localeName(locale, intl),
      ...locale,
    }))
    .filter(locale => locale.name !== locale.tag)
    .sort(byName)
}

export const localeName = (locale, intl) => {
  if (!locale) return
  return intl.formatDisplayName(locale.tag, { type: "language" }) || locale.tag
}

export const projectLocaleName = (projectLocale, intl) => {
  return `${localeName(projectLocale.locale, intl)} (${projectLocale.code})`
}
