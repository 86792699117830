import { computed } from "easy-peasy";

export default {
  items: [
    {
      id: "596da90cc4f05c000170ffd0",
      label: "Arts & Culture",
      options: [
        {
          id: "596da90cc4f05c000170ffd0",
          name: "Arts & Culture"
        },
        {
          id: "596da90cc4f05c000170ffe2",
          name: "Fine Arts & Graphic Design"
        },
        {
          id: "596da90cc4f05c000170ffe5",
          name: "History & Geography"
        },
        {
          id: "596da90cc4f05c000170ffe8",
          name: "Literature & Poetry"
        },
        {
          id: "596da90cc4f05c000170ffeb",
          name: "Performing Arts (dance, theatre…)"
        },
        {
          id: "596da90cc4f05c000170ffee",
          name: "Philosophy"
        },
        {
          id: "596da90cc4f05c000170fff1",
          name: "Religion"
        }
      ]
    },
    {
      id: "57ce805b39775e0003e221fe",
      name: "Economics & Research"
    },
    {
      id: "596da90cc4f05c000170ffd3",
      name: "Environment & Ecology"
    },
    {
      id: "596da90cc4f05c000170ffd6",
      name: "Fashion & Textile"
    },
    {
      id: "57ce805b39775e0003e221ad",
      label: "Finance",
      options: [
        {
          id: "57ce805b39775e0003e221ad",
          name: "Finance"
        },
        {
          id: "57ce805b39775e0003e221b0",
          name: "Asset Management"
        },
        {
          id: "57ce805b39775e0003e221b3",
          name: "Banking"
        },
        {
          id: "57ce805b39775e0003e221b6",
          name: "Corporate Finance"
        },
        {
          id: "57ce805b39775e0003e221b9",
          name: "Insurance"
        },
        {
          id: "57ce805b39775e0003e221bc",
          name: "Private Equity / Mergers & Acquisitions"
        },
        {
          id: "57ce805b39775e0003e221bf",
          name: "Stock Exchange & Financial Markets"
        }
      ]
    },
    {
      id: "596da90cc4f05c000170ffd9",
      label: "Well-being and beauty",
      options: [
        {
          id: "596da90cc4f05c000170ffd9",
          name: "Well-being and beauty"
        },
        {
          id: "596da90cc4f05c000170fff4",
          name: "Beauty & Cosmetics"
        },
        {
          id: "596da90cc4f05c000170fff7",
          name: "Wellness & Pharmaceutical"
        }
      ]
    },
    {
      id: "596da90cc4f05c000170ffdc",
      name: "Human Resources"
    },
    {
      id: "57ce805b39775e0003e22198",
      label: "IT & Software",
      options: [
        {
          id: "57ce805b39775e0003e22198",
          name: "IT & Software"
        },
        {
          id: "57ce805b39775e0003e2219b",
          name: "Hacking & Security"
        },
        {
          id: "57ce805b39775e0003e2219e",
          name: "Hardware"
        },
        {
          id: "57ce805b39775e0003e221a1",
          name: "Hosting & Architecture"
        },
        {
          id: "57ce805b39775e0003e221a4",
          name: "Networks & Connectivity"
        },
        {
          id: "57ce805b39775e0003e221a7",
          name: "Programming"
        },
        {
          id: "57ce805b39775e0003e221aa",
          name: "SaaS, Apps & Software"
        },
        {
          id: "596da90dc4f05c000170fffa",
          name: "Social Media"
        }
      ]
    },
    {
      id: "57ce805b39775e0003e221c2",
      label: "Legal",
      options: [
        {
          id: "57ce805b39775e0003e221c2",
          name: "Legal"
        },
        {
          id: "57ce805b39775e0003e221c5",
          name: "Contract Law & Litigation"
        },
        {
          id: "57ce805b39775e0003e221c8",
          name: "Corporate Law "
        },
        {
          id: "57ce805b39775e0003e221cb",
          name: "Equity Markets"
        },
        {
          id: "57ce805b39775e0003e221ce",
          name: "Intellectual Property"
        },
        {
          id: "57ce805b39775e0003e221d1",
          name: "Labour And Social Law"
        },
        {
          id: "57ce805b39775e0003e221d4",
          name: "Public Law"
        },
        {
          id: "57ce805b39775e0003e221d7",
          name: "Tax Law"
        },
        {
          id: "596da90dc4f05c000170fffd",
          name: "Economic and Finance Law"
        }
      ]
    },
    {
      id: "5a0ac8941bb64e000d644a28",
      label: "Lifestyle & Hobbies",
      options: [
        {
          id: "5a0ac8941bb64e000d644a28",
          name: "Lifestyle & Hobbies"
        },
        {
          id: "5a0ac8941bb64e000d644a2b",
          name: "Plants & Gardening"
        },
        {
          id: "5a37914d6b6032000dd30a96",
          name: "Music"
        }
      ]
    },
    {
      id: "57ce805a39775e0003e22186",
      label: "Luxury",
      options: [
        {
          id: "57ce805a39775e0003e22186",
          name: "Luxury"
        },
        {
          id: "57ce805a39775e0003e22189",
          name: "Haute Couture"
        },
        {
          id: "57ce805b39775e0003e2218c",
          name: "Jewellery"
        },
        {
          id: "57ce805b39775e0003e2218f",
          name: "Leather goods"
        },
        {
          id: "57ce805b39775e0003e22192",
          name: "Watchmaking"
        },
        {
          id: "57ce805b39775e0003e22195",
          name: "Yachting & Private Jet"
        },
        {
          id: "596da90dc4f05c0001710000",
          name: "Cosmetics"
        },
        {
          id: "5a003b7b9e56fa000edcf69d",
          name: "Shoes"
        }
      ]
    },
    {
      id: "57ce805a39775e0003e22177",
      label: "Marketing & Web-marketing",
      options: [
        {
          id: "57ce805a39775e0003e22177",
          name: "Marketing & Web-marketing"
        },
        {
          id: "57ce805a39775e0003e2217a",
          name: "Offline Advertising"
        },
        {
          id: "57ce805a39775e0003e2217d",
          name: "SEO / SEM / Analytics"
        },
        {
          id: "57ce805a39775e0003e22180",
          name: "Social Media & e-Reputation"
        },
        {
          id: "57ce805a39775e0003e22183",
          name: "Surveys & Opinion Polls"
        }
      ]
    },
    {
      id: "57ce805b39775e0003e221da",
      label: "Technical",
      options: [
        {
          id: "57ce805b39775e0003e221da",
          name: "Technical"
        },
        {
          id: "57ce805b39775e0003e221dd",
          name: "Aeronautics & Aerospace"
        },
        {
          id: "57ce805b39775e0003e221e0",
          name: "Agribusiness & Nutrition"
        },
        {
          id: "57ce805b39775e0003e221e3",
          name: "Agronomy"
        },
        {
          id: "57ce805b39775e0003e221e6",
          name: "Automotive"
        },
        {
          id: "57ce805b39775e0003e221e9",
          name: "Building & Construction"
        },
        {
          id: "57ce805b39775e0003e221ec",
          name: "Electricty & Electronics"
        },
        {
          id: "57ce805b39775e0003e221ef",
          name: "Energy & Natural Resources"
        },
        {
          id: "57ce805b39775e0003e221f2",
          name: "Heavy Industry"
        },
        {
          id: "57ce805b39775e0003e221f5",
          name: "Mechanics & Heavy Machinery"
        },
        {
          id: "57ce805b39775e0003e221f8",
          name: "Military & Civil Defense"
        },
        {
          id: "57ce805b39775e0003e221fb",
          name: "Transportation & Logistics"
        },
        {
          id: "596da90dc4f05c0001710003",
          name: "Chemicals"
        },
        {
          id: "596da90dc4f05c0001710006",
          name: "Physics"
        }
      ]
    },
    {
      id: "596da90cc4f05c000170ffdf",
      name: "Wines & Spirits"
    }
  ],

  expertises: computed((state) => (
    state.items.reduce((acc, { id, name, options }) => {
      if (options) {
        acc.push(...options)
      }

      if (name) {
        acc.push({ id, name })
      }

      return acc
    }, [])
  ))
}
