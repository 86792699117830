import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { INSTANCE_MODES } from "../../models/instanceMode"

export class DefaultText {
  constructor(text) {
    this.brandName = text?.brandName || "LSP"
    this.serviceName = text?.serviceName || this.brandName
  }

  get pushToService() {
    return (
      <FormattedMessage
        id="config.partners.default.pushToService"
        defaultMessage="Push to {brandName}"
        values={{ brandName: this.brandName }}
      />
    )
  }

  get quoteModalTitle() {
    return (
      <FormattedMessage
        id="config.partners.default.quoteModalTitle"
        defaultMessage="Push to {brandName}"
        values={{ brandName: this.brandName }}
      />
    )
  }

  get quoteModalSuccessTitle() {
    return (
      <FormattedMessage
        id="config.partners.default.quoteModalSuccessTitle"
        defaultMessage="Pushed to {brandName}"
        values={{ brandName: this.brandName }}
      />
    )
  }

  get quoteDescription() {
    return (
      <FormattedMessage
        id="config.partners.default.quoteDescription"
        defaultMessage="Deselect to push it later."
      />
    )
  }

  get toastPushSuccess() {
    return (
      <FormattedMessage
        id="components.project.locale.getTranslation.toastSuccess"
        defaultMessage="Pushing your content to {brandName} 🚀"
        values={{ brandName: this.brandName }}
      />
    )
  }

  quoteDescriptionSuccess(connectorType) {
    return (
      <FormattedMessage
        id="config.partners.default.quoteDescriptionSuccess"
        defaultMessage="Success! ✨ Your content for {connectorType} is pushed to {brandName}."
        values={{ connectorType, brandName: this.brandName }}
      />
    )
  }
}

export class DefaultConfig {
  projectTemplates = false
  activateProjectOnCreate = true
  instanceMode = INSTANCE_MODES.PROOFREADING
}

const configFn = (text = {}, config = {}) => ({
  text: new DefaultText(text),
  config: new DefaultConfig(config),
})

export default configFn
