import { navigate } from "gatsby-plugin-intl"

const ROUTES = {
  ACCOUNT_ADD: { path: "/account-add/" },
  ACCOUNT_DETAILS: { path: "/account-details/:id" },
  ACCOUNTS: { path: "/accounts" },
  BILLING: { path: "/billing" },
  BILLING_CHECKOUT: { path: "/billing/checkout/:item_price_id" },
  BILLING_CHECKOUT_SUCCESS: { path: "/billing/checkout/success" },
  BILLING_PLANS: { path: "/billing/plans" },
  BOOK_MEETING: { path: "/meeting/book" },
  CONNECT: { path: "/connect/" },
  CONNECTORS: { path: "/connectors" },
  CONNECTOR_INSTALLER: { path: "/connector/install/:connectorId" },
  DASHBOARD: { path: "/dashboard" },
  EDIT_INTEGRATION: { path: "/integrations/:id/edit" },
  INTEGRATIONS: { path: "/integrations" },
  LOCALES: { path: "/settings/locales" },
  NEW_INTEGRATION: { path: "/integrations/:type/new" },
  NEW_PROJECT: { path: "/projects/new" },
  NEW_PROJECT_CONNECTOR: { path: "/projects/new/connector/:id" },
  PROFILE: { path: "/profile" },
  PROJECT: { path: "/projects/:id" },
  ROOT: { path: "/" },
  SETTINGS: { path: "/settings" },
  GENERAL: { path: "/general" },
  SHOPIFY_CALLBACK: { path: "/shopify/callback" },
  SIGN_IN: { path: "/signin" },
  SIGN_UP: { path: "/signup" },
  TEAM: { path: "/team" },
  PARTNER_REDIRECT: { path: "/protemos/:partnerID" },
  PARTNER_ABOUT_YOUR_TEAM: { path: "/protemos" },
  UPGRADE_PLAN: { path: "/settings/plans/" },
  PLANS: { path: "/plans/" },
  PLAN_CHECKOUT: { path: "/plans/:item_price_id/checkout" },
}

export const path = (routeName, params = {}) => {
  if (!ROUTES[routeName]) throw new Error("Route undefined", routeName)
  const path = path_params_substitution(ROUTES[routeName].path, params)
  return path
}

export const redirect = (routeName, params = {}, options = {}) => {
  navigate(path(routeName, params), options)
}

export function path_params_substitution(path, params) {
  Object.entries(params).forEach(([key, value]) => {
    path = path.replace(new RegExp(`:${key}`), value)
  })
  return path
}

export default ROUTES
