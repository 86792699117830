import React, { useEffect, useState } from "react"
import Form from "../../forms/Form"
import { useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import ControlFooter from "./ControlFooter"
import { useStoreActions, useStoreState } from "easy-peasy"
import { CONNECTORS } from "../../../store/models/connectors"
import { hubspotAdditionalFields } from "./commonFields"
import { useFlags } from "gatsby-plugin-launchdarkly"

const resourcesDetails = {
    SitePage: { description: "List of hubspot domains", label: 'Websites' },
    LandingPage: { description: "List of hubspot landing page domains", label: 'Landing Pages' },
    Blog: { description: "List of hubspot blogs", label: 'Blogs' }
}

const projectNamePrefix = {
    SitePage: "Website",
    LandingPage: "Landing Page",
    Blog: "Blog"
}

/**
 * Handles the additional step of hubspotcms project creation
 * @returns JSX
 */
const HubspotForm = () => {
    const { hubspotCmsBlogs } = useFlags()
    const intl = useIntl()
    const currentOrg = useStoreState(state => state.currentOrg.item)
    const resources = useStoreState(state => state.hubspotcms.items)
    const fetchHubspotResources = 
        useStoreActions(actions => actions.hubspotcms.fetch)
    const setFormField = useStoreActions(actions => actions.projectWizard.setFormField)
    const setItems = useStoreActions(actions => actions.hubspotcms.setItems)
    const [ description, setDescription ] = useState("")
    const [ label, setLabel ] = useState("Resource")
    const [ options, setOptions ] = useState([
        {
          label: `${intl.formatMessage({
            id: "components.projects.form.fields.options.websites",
            defaultMessage: "Websites",
          })}`,
          value: "SitePage"
        },
        {
          label: `${intl.formatMessage({
            id: "components.projects.form.fields.options.landingPages",
            defaultMessage: "Landing Pages",
          })}`,
          value: "LandingPage"
        },
        {
          label: `${intl.formatMessage({
            id: "components.projects.form.fields.options.blogs",
            defaultMessage: "Blogs",
          })}`,
          value: "Blog"
        }
    ])
    let formValues = useStoreState(state => state.projectWizard.formValues)
    let providerID = formValues?.project?.providerID
    const { errors, control, handleSubmit } = useForm({
        mode: "onChange",
    })
    const onSubmit = values => {
        const { 
            project: {
                resourceScope: { id, name: resourceValue }, 
                resourceType: resourceTypeName 
            } 
        } = values
        const projectName = `${projectNamePrefix[resourceTypeName]} - ${resourceValue}`
        const providerIDFragments = providerID.split("#")
        providerID = `${providerIDFragments[0]}#${providerIDFragments[1]}#${resourceType}#${id}`
        formValues.project.providerID = providerID
        formValues.project.connector.url = `https://${resourceValue}`
        formValues.project.name = projectName
        setFormField({
            project: formValues.project,
            type: CONNECTORS.hubspotcms
        })
    }
    const resourceType = useWatch({
        control,
        name: "project[resourceType]",
    })
    useEffect(() => {
        setItems([])
        if (providerID) {
            const hubId = providerID.split('#')[1]
            if (resourceType) {
                setDescription(resourcesDetails[resourceType].description)
                setLabel(resourcesDetails[resourceType].label)
            }
            fetchHubspotResources({ organizationID: currentOrg.id, type: resourceType, hubId })
        }
    }, 
        [resourceType]
    )
    useEffect(() => {
        if (hubspotCmsBlogs === false) {
            options.pop()
            setOptions(options)
        }
    }, 
        [ hubspotCmsBlogs ]
    )
    const fields = hubspotAdditionalFields({
        resourceType, 
        resources, 
        description, 
        intl,
        label,
        options
    })
    return (
        <Form
            fields={fields}
            errors={errors}
            control={control}
            className="project-form"
        >
            <ControlFooter
                errors={errors}
                withBack
                handleSubmit={handleSubmit}
                onNext={onSubmit}
            />
        </Form>
    )
}

export default HubspotForm
