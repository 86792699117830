import API from "@aws-amplify/api";
import { action, thunk } from "easy-peasy";
import { INTEGRATION_IDS } from "../../models/integrationType";

export default {
  items: [],

  fetch: thunk(async (actions, input) => {
    let data = [];
    switch (input?.integrationTypeID) {
      case INTEGRATION_IDS.memsource:
        data = await actions.fetchMemsourceProjectTemplates(input);
        break;
      case INTEGRATION_IDS.trados:
        data = await actions.fetchTradosProjectTemplates(input)
        break;
      case INTEGRATION_IDS.memoq:
        data = await actions.fetchMemoQProjectTemplates(input)
        break;
      default:
        data = [];
    }

    actions.set(data)
    return data
  }),

  fetchMemsourceProjectTemplates: thunk((_, integration) => (
    API.get("nepal", `/${integration.integrationTypeID}/projectTemplates`, {
      queryStringParameters: {
        integrationID: integration.id,
      },
    }
  ))),

  fetchMemoQProjectTemplates: thunk((actions, integration) => (
     API.get("nepal", `/${integration.integrationTypeID}/projectTemplates`, {
      queryStringParameters: {
        apiKey: integration?.memoq_apiKey,
        webServiceAPIAccessPoint: integration?.memoq_webServiceAPIAccessPoint,
      },
    })
  )),

  fetchTradosProjectTemplates: thunk((actions, integration) => (
    API.get("nepal", `/${integration.integrationTypeID}/projectTemplates`, {
      queryStringParameters: {
        accountId: integration?.trados_languageCloudAccountId,
        clientId: integration?.trados_clientId,
        clientSecret: integration?.trados_clientSecret,
      },
    })
  )),

  set: action((state, payload) => {
    state.items = payload
  })
}
