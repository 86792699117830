import { action, thunk, computed } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { getIntegrationType, listIntegrationTypes } from "../../graphql/locale-queries"
import { INTEGRATION_STATUSES, INTEGRATION_TYPES, INTEGRATION_PUBLIC_STATUSES } from "../../models/integrationType"

export default {
  item: {},
  items: [],

  find: computed(state => id => state.items.find(integrationType => integrationType.id === id) || {}),
  public: computed(
    [state => state.items, (_, storeState) => ({
      currentOrg: storeState.currentOrg.item,
      currentVendor: storeState.currentVendor.item,
      isLanguageTeam: storeState.currentOrg.isLanguageTeam,
      isSharedIntegrationMode: storeState.currentVendor.isSharedIntegrationMode,
    })],
    (items, { currentVendor, currentOrg, isLanguageTeam, isSharedIntegrationMode }) => {
      const integrationTypeIds = currentVendor.id
        ? currentVendor.integrationTypeIDs
        : (currentOrg?.vendor?.integrationTypeIDs || [])

      return items.filter(({ id, status }) => (
        isLanguageTeam || isSharedIntegrationMode
          ? status !== INTEGRATION_STATUSES.private
          : integrationTypeIds.includes(id)
      ))
    },
  ),
  publicTMS: computed(state => state.items.filter(integrationType => INTEGRATION_PUBLIC_STATUSES.includes(integrationType.status) && integrationType.type === INTEGRATION_TYPES.tms) || []),

  fetch: thunk(async (actions, id) => {
    try {
      const { data: { getIntegrationType: integrationType } } = await API.graphql(graphqlOperation(getIntegrationType, { id }))
      integrationType.name = integrationType.metadata?.name || integrationType.name
      integrationType.logo = integrationType.metadata?.logo
      integrationType.logoSquare = integrationType.metadata?.logoSquare
      actions.setItem(integrationType)
    } catch (e) {
      console.error(e)
    }
  }),

  fetchAll: thunk(async actions => {
    try {
      let nextToken
      const integrationTypes = []
      do {
        const { data } = await API.graphql(graphqlOperation(listIntegrationTypes, { nextToken }))
        nextToken = data.listIntegrationTypes.nextToken
        integrationTypes.push(...data.listIntegrationTypes.items)
      } while (nextToken)
      integrationTypes.forEach(integrationType => {
        integrationType.name = integrationType.metadata?.name || integrationType.name
        integrationType.logo = integrationType.metadata?.logo
        integrationType.logoSquare = integrationType.metadata?.logoSquare
      })
      actions.setItems(integrationTypes)
    } catch (e) {
      console.error(e)
    }
  }),

  reset: action((state, payload) => {
    state.item = {}
  }),

  setItem: action((state, payload) => {
    state.item = payload
  }),

  setItems: action((state, payload) => {
    state.items = payload
  }),
}
