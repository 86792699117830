import AzureWebhookCreator from "./azureWebhookCreator"
import { formatPath } from "../helpers/format"
import { CONNECTORS, CONNECTOR_TYPES } from "../store/models/connectors"
import store from "../store/index"
import ProjectLocaleCreator from "./projectLocaleCreator"
import ProjectResourcesCreator from "./projectResourcesCreator"
import GitlabWebhookCreator from "./gitlabWebhookCreator"
import { defaultBrief } from "../config/defaultBrief"

export default class ProjectCreator {
  #_project;
  #projectID;

  constructor({ project, activeLocale = true }) {
    this._activeLocale = activeLocale
    this._project = project
  }

  static async call(args) {
    return new ProjectCreator(args).call()
  }

  async call() {
    try {
      this.projectID = await this.createProject()
      const localesData = await this.createProjectLocales()
      if (!this.project().resourcePatterns) {
        await this.createProjectResources(localesData)
      }
      switch (this.project().connector.type) {
        case CONNECTORS.azurerepos:
          await AzureWebhookCreator.call(this.project());
          break;
        case CONNECTORS.gitlab:
          await GitlabWebhookCreator.call(this.project());
          break;
        default:
          break;
      }
      return this.projectID
    } catch (err) {
      console.log(err)
      return null
    }
  }

  // PRIVATE METHODS
  project() {
    const {
      autoUpdateFrequency,
      branch,
      connector,
      cuistoConfig,
      format,
      isActive,
      liveApiKey,
      name,
      organizationID,
      owner,
      providerID,
      repository,
      resourcePatterns,
      testApiKey,
      textmasterProjectSettings,
      integrationID,
      integrationTemplateID,
      vendorID,
      webhookURL,
    } = this._project

    const groups = [organizationID];

    if (vendorID) {
      groups.push(vendorID)
    }

    const project = {
      autoUpdateFrequency,
      connector: {
        isCuisto: connector.type === CONNECTOR_TYPES.cuisto,
        branch: branch?.name,
        mode: connector.mode,
        organization: repository?.organization,
        project: repository?.project,
        repository: repository?.name,
        type: connector.id,
        userId: connector.userId,
        url: connector.url,
        webhookURL,
      },
      formatID: format.id,
      groups,
      isActive: isActive || false,
      liveApiKey,
      name,
      organizationID,
      owner,
      providerID,
      resourcePatterns,
      testApiKey,
      textmasterProjectSettings,
      integrationID,
      integrationTemplateID,
      translationBrief: defaultBrief,
      cuistoConfig: JSON.stringify(cuistoConfig),
    }

    return project
  }

  createProject() {
    return store
      .getActions()
      .projects
      .create(this.project())
  }

  projectLocaleSource() {
    let { projectLocales: { source } } = this._project

    return this.projectLocale({
      isActive: this._activeLocale,
      isSource: true,
      ...source,
    })
  }

  projectLocaleTargets() {
    let { projectLocales: { targets } } = this._project

    return targets.map(target =>
      this.projectLocale({
        isActive: this._activeLocale,
        isSource: false,
        ...target,
      })
    )
  }

  projectLocale(attrs) {
    return {
      code: attrs.tag,
      groups: this.project().groups,
      isActive: attrs.isActive,
      isSource: attrs.isSource,
      localeID: attrs.id,
      organizationID: this.project().organizationID,
      owner: this.project().owner,
      projectID: this.projectID,
    }
  }

  projectLocales() {
    return [
      this.projectLocaleSource(),
      ...this.projectLocaleTargets()
    ]
  }

  createProjectLocales() {
    return Promise.all(
      this.projectLocales().map(projectLocale =>
        ProjectLocaleCreator.call(projectLocale)
      )
    )
  }

  async createProjectResources(locales) {
    const { format } = this._project
    const resources = locales.map(locale => ({
      path: formatPath(format, locale),
      projectLocaleID: locale.id,
    }))

    await ProjectResourcesCreator.call({
      projectID: this.projectID,
      resources: [resources],
    })
  }
}
