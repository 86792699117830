import React from "react"
import { Controller } from "react-hook-form"
import { Form } from "react-bootstrap"

const TextArea = ({
  name,
  type,
  control,
  errors,
  validations,
  defaultValue,
  placeholder,
  plaintext = false,
  readOnly = false,
  isDisabled = false,
  rows = 6,
}) => (<Controller
    name={name}
    type={type}
    control={control}
    rules={validations}
    render={(props) => (
      <Form.Control
        name={name}
        as="textarea"
        className={errors[name] ? "is-invalid" : ""}
        placeholder={placeholder}
        onChange={props.onChange}
        defaultValue={props.value}
        plaintext={plaintext}
        readOnly={readOnly}
        disabled={isDisabled}
        rows={rows}
      />
    )}
  />
)

export default TextArea
