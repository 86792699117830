import React, { useEffect, useMemo } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { useStoreActions, useStoreState } from "easy-peasy"
import Form from "../../forms/Form"
import { stateGenerator } from "../../../helpers/application"
import { CONNECTORS } from "../../../store/models/connectors"
import ControlFooter from "./ControlFooter"
import { WIZARD_ROOT } from "../../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../../store/models/projectWizard"
import commonFields, { gitFields } from "./commonFields"
import { defaultBranchOption } from "../../../helpers/git"

const BitbucketForm = ({ location }) => {
  const intl = useIntl()

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
  })

  const fetchBitbucketRepos = useStoreActions(actions => actions.bitbucketRepos.fetch)

  const bitbucketRepos = useStoreState(state => state.bitbucketRepos.items)
  const currentOrg = useStoreState(state => state.currentOrg.item)

  const bitbucketBranches = useStoreState(state => state.bitbucketBranches.items)
  const setBranches = useStoreActions(actions => actions.bitbucketBranches.set)
  const fetchBitbucketBranches = useStoreActions(
    actions => actions.bitbucketBranches.fetch
  )

  const setFormField = useStoreActions(
    actions => actions.projectWizard.setFormField
  )
  const formValues = useStoreState(state => state.projectWizard.formValues)

  const fetchConnectors = useStoreActions(actions => actions.connectors.fetch)
  const connectors = useStoreState(state => state.connectors.items)

  const repoSelect = useWatch({
    control,
    name: "project[repository]",
  })

  useEffect(() => {
    if (currentOrg) fetchBitbucketRepos({ organizationID: currentOrg.id })
  }, [currentOrg, fetchBitbucketRepos])

  const formRepoId = formValues?.project?.repository?.id
  const repoId = repoSelect?.id
  const repoName = repoSelect?.name

  useEffect(() => {
    if (!repoId || !currentOrg) return

    const name = repoName.split("/")[1]
    setValue("project[name]", name)

    if (formRepoId !== repoId) {
      setBranches([])
      fetchBitbucketBranches({
        repoName: repoName,
        organizationID: currentOrg.id,
      })
    }
  }, [
    currentOrg,
    fetchBitbucketBranches,
    formRepoId,
    repoId,
    repoName,
    setBranches,
    setValue,
  ])

  useEffect(() => {
    const connector = connectors.find(
      connector => connector.id === CONNECTORS.bitbucket
    )

    if (connector) setValue("project[connector]", connector)
    else fetchConnectors()
  }, [connectors, fetchConnectors, setValue])

  const state = useMemo(
    () =>
      stateGenerator({
        redirectURL: `${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(
          CONNECTORS.bitbucket
        )}`,
      }),
    []
  )

  useEffect(() => {
    if (!bitbucketRepos.length) return

    if (!formValues?.project?.repository) {
      setValue("project[repository]", bitbucketRepos[0])
    } else {
      setValue("project[repository]", formValues.project?.repository)
    }
  }, [bitbucketRepos, formValues?.project?.repository])

  useEffect(() => {
    if (!bitbucketBranches.length) {
      setValue("project[branch]", null)
      return
    }

    const selectedAnotherRepo =
      formValues?.project?.repository?.id !== repoSelect?.id
    if (!formValues || selectedAnotherRepo) {
      setValue("project[branch]", defaultBranchOption(bitbucketBranches))
    } else {
      setValue("project[branch]", formValues?.project?.branch)
    }
  }, [formValues, bitbucketBranches, repoSelect])

  const bitbucketFields = [
    ...commonFields,
    ...gitFields(
      intl,
      state,
      {
        data: bitbucketRepos,
        isLoading: !bitbucketRepos.length,
      },
      {
        data: bitbucketBranches,
        isLoading: !bitbucketBranches.length,
        isDisabled: !repoSelect,
      }
    ),
    { type: "divider" },
  ]

  const onSubmit = values => {
    const project = {
      ...formValues?.project,
      ...values.project,
      owner: currentOrg.owner,
      organizationID: currentOrg.id,
    }

    setFormField({
      project,
      type: CONNECTORS.bitbucket,
    })
  }

  return (
    <Form
      title={intl.formatMessage({
        id: "components.projects.form.formTitle",
        defaultMessage: "Add a project for translation",
      })}
      errors={errors}
      fields={bitbucketFields}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        onNext={onSubmit}
      />
    </Form>
  )
}

export default BitbucketForm
