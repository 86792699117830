import React from "react"
import { Controller } from "react-hook-form"
import Select from "react-select"

const SelectField = ({
  name,
  errors,
  options,
  multi,
  control,
  validations,
  defaultValue,
  defaultInputValue,
  placeholder,
  isClearable,
  isDisabled,
  isLoading
}) => (
  <Controller
    as={Select}
    name={name}
    options={options}
    isLoading={isLoading}
    getOptionLabel={option => option.name}
    getOptionValue={option => option.id}
    control={control}
    isMulti={multi === true}
    className={errors[name] ? "is-invalid" : ""}
    rules={validations}
    defaultInputValue={defaultInputValue}
    defaultValue={defaultValue}
    placeholder={placeholder}
    isClearable={isClearable}
    isDisabled={isDisabled}
  />
)

export default SelectField
