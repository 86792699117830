import { action, thunk } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listOrganizations } from "../../graphql/locale-queries"
import Organization from "../../models/organization"

export default {
  items: [],

  // thunks
  fetch: thunk(async actions => {
    try {
      const organizations = []
      let nextToken
      do {
        const { data } = await API.graphql(
          graphqlOperation(listOrganizations, { nextToken })
        )
        nextToken = data.listOrganizations.nextToken
        organizations.push(...data.listOrganizations.items)
      } while (nextToken)

      actions.set(organizations)
    } catch (e) {
      console.error(e)
    }
  }),

  create: thunk(async (actions, input, helpers) => {
    try {
      let { organization: org } = await API.post(
        "nepal", "/workspace", { body: input }
      );
      await actions.fetch()
      const currentOrg = helpers.getStoreState().organizations.items
        .find((row) => row.id === org.id)
      helpers.getStoreActions().currentOrg.set(currentOrg)
      return currentOrg
    } catch (err) {
      console.log(err)
    }
  }),

  updateOrgById: action((state, payload) => {
    state.items = state.items.map(item =>
      item.id === payload.id ? { ...item, ...payload } : item
    )
  }),

  // actions
  set: action((state, orgs) => {
    state.items = orgs.map((org) => new Organization(org))
  })
}
