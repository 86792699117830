import React, { useMemo, useEffect } from "react"
import { useLocation, Router } from "@reach/router"
import { SwitchTransition, CSSTransition } from "react-transition-group"
import { useStoreActions, useStoreState } from "easy-peasy"
import ConnectorForms from "../../components/projects/wizard/ConnectorForms"
import ConnectorSelect from "../../components/projects/wizard/ConnectorSelect"
import FormatForm from "../../components/projects/wizard/FormatForm"
import LocaleForm from "../../components/projects/wizard/LocaleForm"
import ProjectTemplateForm from "../../components/projects/wizard/ProjectTemplateForm"
import MailchimpCampaignFolders from "../../components/projects/wizard/MailchimpCampaignFolders"
import Success from "../../components/projects/wizard/Success"
import ROUTES from "../../config/routes"
import { WIZARD_ROUTES } from "../../store/models/projectWizard"
import { Setup } from "../../components/projects/wizard/Layouts"
import HubspotForm from "../../components/projects/wizard/HubspotForm"

export const animationTimeout = 600
export const WIZARD_ROOT = `${ROUTES.NEW_PROJECT.path}`

const NewProject = () => {
  const location = useLocation()

  const { stepIdx, lastStepIdx } = useStoreState(state => state.projectWizard)
  const { fetchSubscription } = useStoreActions(actions => actions.billing)
  const { item: currentOrg } = useStoreState(state => state.currentOrg)
  const { subscription } = useStoreState(state => state.billing)

  useEffect(() => {
    if (!currentOrg || subscription?.details) return
    fetchSubscription({ organizationId: currentOrg.id })
  }, [currentOrg, subscription])

  const slideClass = useMemo(
    () => (stepIdx >= lastStepIdx ? "slide" : "slide-back"),
    [stepIdx, lastStepIdx]
  )

  return (
    <Setup>
      <SwitchTransition>
        <CSSTransition
          key={location.pathname}
          classNames={slideClass}
          className="position-relative"
          timeout={{ enter: animationTimeout, exit: 100 }}
          unmountOnExit
        >
          <Router location={location}>
            <ConnectorSelect path={WIZARD_ROUTES.CONNECTOR_SELECT.path} exact />
            <Success path={WIZARD_ROUTES.SUCCESS.path()} exact />
            <ProjectTemplateForm
              path={WIZARD_ROUTES.PROJECT_TEMPLATE.path()}
            />
            <ConnectorForms path={WIZARD_ROUTES.CONNECTOR.path()} />
            <HubspotForm path={WIZARD_ROUTES.RESOURCE_TYPES.path()} />
            <FormatForm path={WIZARD_ROUTES.FORMAT.path()} />
            <LocaleForm path={WIZARD_ROUTES.LANGUAGE.path()} />
            <MailchimpCampaignFolders path={WIZARD_ROUTES.MAILCHIMP_CAMPAIGN_FOLDERS.path()} />
          </Router>
        </CSSTransition>
      </SwitchTransition>
    </Setup>
  )
}

export default NewProject
