import React from "react"
import { Controller } from "react-hook-form"
import { Form } from "react-bootstrap"
import InputGroup from 'react-bootstrap/InputGroup';

const WithInputGroup = ({ prependInputGroupText, appendInputGroupText, children }) => {
  return (
    <InputGroup>
      {prependInputGroupText && (
        <InputGroup.Prepend>
          <InputGroup.Text>{prependInputGroupText}</InputGroup.Text>
        </InputGroup.Prepend>
      )}

      {appendInputGroupText && (
        <InputGroup.Append>
          <InputGroup.Text>{appendInputGroupText}</InputGroup.Text>
        </InputGroup.Append>
      )}

      {children}
    </InputGroup>
  )
}

const Input = ({
  autocomplete,
  name,
  type,
  control,
  errors,
  validations,
  defaultValue,
  placeholder,
  plaintext = false,
  readOnly = false,
  isDisabled = false,
}) => (<Controller
    autoComplete={autocomplete}
    as={Form.Control}
    name={name}
    type={type}
    control={control}
    className={errors[name] ? "is-invalid" : ""}
    rules={validations}
    defaultValue={defaultValue}
    placeholder={placeholder}
    plaintext={plaintext}
    readOnly={readOnly}
    disabled={isDisabled}
  />
)
const InputField = (props) => <WithInputGroup {...props}><Input {...props} /></WithInputGroup>

export default InputField
