import React, { useEffect, useMemo } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useForm, useWatch } from "react-hook-form"
import { useStoreActions, useStoreState } from "easy-peasy"
import Form from "../../forms/Form"
import { stateGenerator } from "../../../helpers/application"
import { CONNECTORS } from "../../../store/models/connectors"
import ControlFooter from "./ControlFooter"
import { WIZARD_ROOT } from "../../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../../store/models/projectWizard"
import commonFields, { gitFields } from "./commonFields"
import { defaultBranchOption } from "../../../helpers/git"

const AzureForm = ({ location }) => {
  const intl = useIntl()

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
  })

  const fetchAzureRepos = useStoreActions(actions => actions.azureRepos.fetch)
  const fetchAzureBranches = useStoreActions(
    actions => actions.azureBranches.fetch
  )

  const azureRepos = useStoreState(state => state.azureRepos.items)
  const azureBranches = useStoreState(state => state.azureBranches.items)
  const setBranches = useStoreActions(actions => actions.azureBranches.set)

  const currentOrg = useStoreState(state => state.currentOrg.item)

  const setFormField = useStoreActions(
    actions => actions.projectWizard.setFormField
  )
  const formValues = useStoreState(state => state.projectWizard.formValues)

  const fetchConnectors = useStoreActions(actions => actions.connectors.fetch)
  const connectors = useStoreState(state => state.connectors.items)

  const repoSelect = useWatch({
    control,
    name: "project[repository]",
  })

  useEffect(() => {
    if (currentOrg) fetchAzureRepos({ organizationID: currentOrg.id })
  }, [currentOrg, fetchAzureRepos])

  const formRepoId = formValues?.project?.repository?.id
  const repoId = repoSelect?.id
  const repoName = repoSelect?.repository
  const repoOrg = repoSelect?.organization
  const repoProject = repoSelect?.project

  useEffect(() => {
    if (!repoId || !currentOrg) return
    setValue("project[name]", repoName)
    if (formRepoId !== repoId) {
      setBranches([])
      fetchAzureBranches({
        gitOrganization: repoOrg,
        gitProject: repoProject,
        gitRepository: repoName,
        organizationID: currentOrg.id,
      })
    }
  }, [
    currentOrg,
    fetchAzureBranches,
    formRepoId,
    repoId,
    repoName,
    repoOrg,
    repoProject,
    setBranches,
    setValue,
  ])

  useEffect(() => {
    const connector = connectors.find(
      connector => connector.id === CONNECTORS.azurerepos
    )

    if (connector) {
      setValue("project[connector]", connector)
      return
    }
    fetchConnectors();
  }, [connectors, fetchConnectors, setValue])

  const state = useMemo(
    () =>
      stateGenerator({
        redirectURL: `${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(
          CONNECTORS.azurerepos
        )}`,
      }),
    []
  )

  useEffect(() => {
    if (!azureRepos.length) return

    if (!formValues?.project?.repository) {
      setValue("project[repository]", azureRepos[0])
      return
    }
    setValue("project[repository]", formValues.project.repository)
  }, [azureRepos, formValues?.project?.repository])

  useEffect(() => {
    if (!azureBranches.length) {
      setValue("project[branch]", null)
      return
    }

    const selectedAnotherRepo =
      formValues?.project?.repository?.id !== repoSelect?.id
    if (!formValues || selectedAnotherRepo) {
      setValue("project[branch]", defaultBranchOption(azureBranches))
      return
    }
    setValue("project[branch]", formValues?.project?.branch)
  }, [formValues, azureBranches, repoSelect])

  const azureFields = [
    ...commonFields,
    ...gitFields(
      intl,
      state,
      {
        data: azureRepos,
        isLoading: !azureRepos.length,
      },
      {
        data: azureBranches,
        isLoading: !azureBranches.length,
        isDisabled: !repoSelect,
      },
      `https://app.vssps.visualstudio.com/oauth2/authorize/?state=${state}&client_id=${process.env.AZURE_CLIENT_ID}&scope=${encodeURIComponent(process.env.AZURE_SCOPES)}&redirect_uri=${encodeURIComponent(process.env.AZURE_REDIRECT_URI)}&response_type=Assertion`
    ),
    { type: "divider" },
  ]

  const onSubmit = values => {
    const project = {
      ...formValues?.project,
      ...values.project,
      owner: currentOrg.owner,
      organizationID: currentOrg.id,
    }
    project.repository.name = values?.project?.repository?.repository;
    project.connector.url = values?.project?.repository?.url;
    project.providerID = `azurerepos#${project?.repository?.organization}#${project?.repository?.project}#${project?.repository?.repository}#${project?.branch?.name}`;
    setFormField({
      project,
      type: CONNECTORS.azurerepos,
    })
  }

  return (
    <Form
      title={intl.formatMessage({
        id: "components.projects.form.formTitle",
        defaultMessage: "Add a project for translation",
      })}
      errors={errors}
      fields={azureFields}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        onNext={onSubmit}
      />
    </Form>
  )
}

export default AzureForm
