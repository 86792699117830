export const byId = (a, b) => {
  return by("id", a, b)
}

export const byName = (a, b) => {
  return by("name", a, b)
}

export const byCode = (a, b) => {
  return by("code", a, b)
}

export const byCategory = (a, b) => {
  return by("category", a, b)
}

export const byPrice = (a, b) => {
  if (a["price"] > b["price"]) return 1
  if (a["price"] < b["price"]) return -1
  return 0
}

export const byRank = (a, b) => {
  if (a["rank"] > b["rank"]) return 1
  if (a["rank"] < b["rank"]) return -1
  return 0
}

export const by = (property, a, b) => {
  return a[property]?.localeCompare(b[property], undefined, { numeric: true })
}
