import { DateTime, Duration } from "luxon"

export const LOCALE_STATE = {
  CALCULATING: "calculating",
  COMPLETE: "complete",
  IN_PROGRESS: "inProgress",
  INCOMPLETE: "incomplete",
  MISSING: "missing",
  SOURCE: "source",
}

export default class ProjectLocale {
  constructor(attrs) {
    this.audit = attrs.audit || {
      completion: null,
      duration: null,
      missingSegments: null,
      missingWords: null,
      segments: null,
      words: null,
    }
    this.code = attrs.code || null
    this.id = attrs.id || null
    this.isActive = attrs.isActive || false
    this.isSource = attrs.isSource || false
    this.locale = attrs.locale || null
    this.localeID = attrs.localeID || null
    this.organizationID = attrs.organizationID || null
    this.owner = attrs.owner || null
    this.project = attrs.project || null
    this.projectID = attrs.projectID || null
    this.inProgress = attrs.inProgress
    this.groups = attrs.groups
    this.stateUpdatedAt = attrs.stateUpdatedAt || null
    this.createdAt = attrs.createdAt || null
    this.updatedAt = attrs.updatedAt || null
  }

  get completion() {
    return this.audit.completion;
  }

  get duration() {
    return this.audit.duration ? Duration.fromISO(this.audit.duration) : null
  }

  get hasQuoteInProgress() {
    return this.quotesInProgress.length > 0;
  }

  get isCalculating() {
    return this.hasQuoteInProgress || this.state === undefined
  }

  get instancesInProgress() {
    return JSON.parse(this.inProgress).instances
  }

  get isCompleted() {
    return this.completion === 100
  }

  get isIncomplete() {
    return this.completion > 0 && this.completion < 100
  }

  get isInProgress() {
    return this.instancesInProgress.length > 0
  }

  get isMissing() {
    return this.completion === 0
  }

  get isQuoteComplete() {
    return !this.hasQuoteInProgress
  }

  get isTranslatable() {
    return [LOCALE_STATE.INCOMPLETE, LOCALE_STATE.MISSING].includes(this.state)
  }

  get isPushable() {
    return ![undefined, LOCALE_STATE.CALCULATING].includes(this.state)
  }

  lastDelivered(intl) {
    if (!this.stateUpdatedAt) return
    return intl.formatMessage(
      {
        id: "models.projectLocale.lastDelivered",
        defaultMessage: "Updated {lastDelivered}",
      },
      {
        lastDelivered: DateTime.now().toRelative({
          base: DateTime.fromISO(this.stateUpdatedAt),
          locale: intl.locale,
          style: "narrow",
        }),
      }
    )
  }

  get missingSegments() {
    return this.audit.missingSegments
  }

  get missingWords() {
    return this.audit.missingWords
  }

  get quotesInProgress() {
    return JSON.parse(this.inProgress).quotes
  }

  get segments() {
    return this.audit.segments
  }

  get state() {
    if (this.isSource) return LOCALE_STATE.SOURCE
    if (this.hasQuoteInProgress) return LOCALE_STATE.CALCULATING
    if (this.isInProgress) return LOCALE_STATE.IN_PROGRESS
    if (this.isCompleted) return LOCALE_STATE.COMPLETE
    if (this.isIncomplete) return LOCALE_STATE.INCOMPLETE
    if (this.isMissing) return LOCALE_STATE.MISSING
  }

  get words() {
    return this.audit.words
  }
}
