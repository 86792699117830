import { action, thunk, persist, computed } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { getVendor, listVendors } from "../../graphql/locale-queries"
import { updateVendor } from "../../graphql/locale-mutations"
import { INTEGRATION_MODES } from "../../models/integrationModes"
import { INTEGRATION_TYPES } from "../../models/integrationType"
import Onboarding from "../../models/onboarding"

export default persist({
  item: {},

  integrationById: computed(state => id =>
    state.item?.integrations?.items.find(i => i.id === id)
  ),

  defaultIntegration: computed(state =>
    state.item?.defaultIntegration || {}
  ),

  integrations: computed(state =>
    (state.item?.integrations?.items || []).map((integration) => {
      integration.integrationType.configuration = typeof integration.integrationType.configuration === "string"
        ? JSON.parse(integration.integrationType.configuration)
        : integration.integrationType.configuration || {}

      return integration
    })
  ),
  bmsIntegrations: computed(state =>
    (state.item?.integrations?.items || []).filter(integration => integration.integrationType.type === INTEGRATION_TYPES.bms || integration.integrationType.type === INTEGRATION_TYPES.hybrid).map((integration) => {
      integration.integrationType.configuration = typeof integration.integrationType.configuration === "string"
        ? JSON.parse(integration.integrationType.configuration)
        : integration.integrationType.configuration || {}

      return integration
    })
  ),
  tmsIntegrations: computed(state =>
    (state.item?.integrations?.items || []).filter(integration => integration.integrationType.type === INTEGRATION_TYPES.tms).map((integration) => {
      integration.integrationType.configuration = typeof integration.integrationType.configuration === "string"
        ? JSON.parse(integration.integrationType.configuration)
        : integration.integrationType.configuration || {}

      return integration
    })
  ),

  isDedicatedIntegrationMode: computed(state =>
    state.item.integrationMode === INTEGRATION_MODES.dedicated
  ),

  isSharedIntegrationMode: computed(state =>
    state.item.integrationMode === INTEGRATION_MODES.shared
  ),

  id: computed(state =>
    state.item?.id || null
  ),

  fetch: thunk(async (actions, payload) => {
    const { id } = payload || {}
    try {
      const { data: { getVendor: vendor } } = await API.graphql(graphqlOperation(getVendor, { id }))
      actions.set(vendor)
      return vendor
    } catch (error) {
      console.error(error)
      actions.set({})
      return null
    }
  }),

  find: thunk(async (actions, payload, helpers) => {
      let vendor = null
      let nextToken = null
      let vendors = [];
      do {
        try {
          const { data } = await API.graphql(graphqlOperation(listVendors))
          nextToken = data.listVendors.nextToken
          vendors = data.listVendors.items
        } catch (error) {
          nextToken = error.data.listVendors.nextToken
          vendors = error.data.listVendors.items
          console.log(error)
        }

        vendor = vendors.find((item) => (item));
        if (vendor) {
          break;
        }
      } while(nextToken);
      actions.set(vendor)
      helpers.getStoreActions().onboarding.setAttrs({
        ...new Onboarding({ vendor, ...vendor.onboarding || {} }),
        vendor
      })
  }),

  update: thunk(async (actions, input) => {
    actions.updateItem(input)
    await API.graphql(graphqlOperation(updateVendor, { input }))
  }),

  updateDefaultIntegration: thunk(async (actions, { id, integration }) => {
    const input = {
      id,
      defaultIntegrationID: integration.id,
    };
    actions.updateItem({
      defaultIntegration: integration,
      defaultIntegrationID: integration.id,
    })
    await API.graphql(graphqlOperation(updateVendor, { input }))
  }),


  addIntegration: action((state, payload) => {
    const integrations = state.item?.integrations || { items: [] }
    integrations.items.push(payload);
    state.item = {
      ...state.item,
      integrations,
    }
  }),

  updateItem: action((state, item) => {
    state.item = {
      ...state.item,
      ...item
    }
  }),

  set: action((state, payload) => {
    state.item = payload
  }),
})
