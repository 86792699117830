import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  items: [],
  isLoading: false,
  fetch: thunk(async (actions, { organizationID, userId }) => {
    try {
      const params = {
        queryStringParameters: {
          organizationID,
          userId,
          connectorId: "googlesheets",
          mimeType: "application/vnd.google-apps.spreadsheet"
        }
      }
      actions.setLoading(true);
      const files = await API.get("nepal", "/googledrive/files", params);
      actions.setLoading(false);
      actions.setItems(files)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  setItems: action((state, items) => {
    state.items = items
  }),
  setLoading: action((state, payload)=>{
    state.isLoading = payload
  })
}