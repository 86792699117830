import React from "react"
import { Form as BootstrapForm } from "react-bootstrap"
import FormFields from "./FormFields"

const Form = ({ id, fields, errors, control, onSubmit, children, className }) => {
  return (
    <BootstrapForm id={id} onSubmit={onSubmit} className={className}>
      {fields && <FormFields fields={fields} errors={errors} control={control} />}
      {children}
    </BootstrapForm>
  )
}

export default Form
