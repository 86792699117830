import React from "react"
import { useFlags } from "gatsby-plugin-launchdarkly"
import { CONNECTOR_STATUSES } from "../../store/models/connectors"
import BetaConnector from "./BetaConnector"
import PublicConnector from "./PublicConnector"

const Connector = ({ connector, setShowToast, setRequested, createProject }) => {
  const ldFlags = useFlags();

  const renderConnector = () => {
    switch (connector.status) {
      case CONNECTOR_STATUSES.public:
      case CONNECTOR_STATUSES.preview:
        return <PublicConnector createProject={createProject} connector={connector} />
      case CONNECTOR_STATUSES.beta:
        return <BetaConnector connector={connector} setShowToast={setShowToast} setRequested={setRequested} />
      case CONNECTOR_STATUSES.private:
        return ldFlags[`${connector.id}ConnectorAccess`] ? <PublicConnector connector={connector} /> : null
      default:
        return null
    }
  }

  return renderConnector()
}

export default Connector
