export const defaultBranchOption = (branches) => {
  const defaultBranches = branches.reduce((acc, branch) => {
    const name = branch.name.toLowerCase()
    if (!["main", "master", "develop"].includes(name)) return acc
    acc[name] = branch
    return acc
  }, {})
  return defaultBranches.main
    || defaultBranches.master
    || defaultBranches.develop
    || branches[0]
}