import { API } from "aws-amplify"

export default class AzureWebhookCreator {
  constructor(project) {
    this._project = project;
  }

  static async call(args) {
    return new AzureWebhookCreator(args).call()
  }

  async call() {
    try {
      await API.post("nepal", "/azure/webhook", {
        body: { project: this._project }
      });
    } catch (error) {
      console.error('An error has occurred while creating an Azure Repo webhook', error);
    }
  }
}
