import { CONNECTORS } from "../store/models/connectors";

export const zapUrl = (app) => `https://zapier.com/apps/${app}/integrations`;

const getZapUrl = (connectorId) => {
  switch(connectorId) {
    case CONNECTORS.hubspot:
      return zapUrl("hubspot");
    case CONNECTORS.azurerepos:
      return zapUrl("azure-devops");
    case CONNECTORS.microsoftTeams:
      return zapUrl("microsoft-teams");
    case CONNECTORS.helpScout:
      return zapUrl("help-scout");
    case CONNECTORS.tawk:
      return zapUrl("tawk-to");
    case CONNECTORS.googleDocs:
      return zapUrl("google-docs");
    case CONNECTORS.campaignMonitor:
      return zapUrl("campaign-monitor");
    case CONNECTORS.awsses:
      return zapUrl("amazon-ses");
    case CONNECTORS.facebookleadads:
      return zapUrl("facebook-lead-ads");
    default:
      return zapUrl(connectorId)
  }
}

export default getZapUrl
