import store from "../store/index"

export default class OnboardingUpdater {
  constructor({ onboarding, stage, isCompleted }) {
    this._onboarding = onboarding
    this._stage = stage || onboarding.stage
    this._isCompleted = isCompleted || onboarding.isCompleted
  }

  static async call(args) {
    return new OnboardingUpdater(args).call()
  }

  async call() {
    try {
      switch(this.type()) {
        case "vendor":
          await this.updateVendor()
          break;
        case "organization":
        default:
          await this.updateOrg()
      }
      return true
    } catch (err) {
      return false
    }
  }

  type() {
    return this.organization().id ? "organization" : "vendor"
  }

  id() {
    return this.type() === "organization" ? this.organization().id : this.vendor().id
  }

  // PRIVATE METHODS
  onboarding() {
    return {
      id: this.id(),
      onboarding: {
        ...this._onboarding.toJSON(),
        stage: this._stage,
        isCompleted: this._isCompleted,
      },
    }
  }

  organization() {
    return this._onboarding.organization
  }

  vendor() {
    return this._onboarding.vendor
  }

  updateOrg() {
    return store.getActions().currentOrg.update(this.onboarding())
  }

  updateVendor() {
    return store.getActions().currentVendor.update(this.onboarding())
  }
}
