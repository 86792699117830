import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useStoreState } from "easy-peasy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"
import Form from "../forms/Form"
import { fieldRequired } from "../../utils/validations"
import { API } from "aws-amplify"
import Spinner from "../common/Spinner"
import Toast from "../../components/Toast"

const CommercetoolsProjectForm = ({ name, onSuccess }) => {
  const intl = useIntl()
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const { handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  })

  const onSubmit = async ({ projectKey }) => {
    setShowToast(false)
    setIsSubmitting(true)
    try {
      const { cuistoConfig } = await API.post(
        "nepal",
        `/oauth/commercetools/callback`,
        { body: { projectKey, organizationId: currentOrg.id } }
      )
      onSuccess({ state: { cuistoConfig } })
    } catch (e) {
      setShowToast(true)
    }
    setIsSubmitting(false);
  }

  const fields = [
    {
      name: "projectKey",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.commercetools.CommerceToolsProjectForm.formLabel",
        defaultMessage: "Project Key",
      })}`,
      placeholder: "my-project",
      defaultValue: "",
      validations: { ...fieldRequired },
    },
  ]

  return (
    <div className="pr-4">
      <Toast show={showToast} setShow={setShowToast} delay={3000}>
        <p className="text-gray-50 font-normal mb-0">
          <FormattedMessage
            id="components.commercetools.CommerceToolsProjectForm.error.toast"
            defaultMessage="Authentication failed. Please verify the project key for accuracy."
          />
        </p>
      </Toast>
      <Form
        className="text-left"
        errors={errors}
        fields={fields}
        control={control}
        onSubmit={handleSubmit(onSubmit)}
        status={isSubmitting}
      >
        <Button
          variant="primary"
          className="btn btn--primary"
          type="submit"
          block
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <Spinner />
          ) : (
            <FormattedMessage
              id="components.commercetools.ProjectForm.button"
              defaultMessage="Submit"
            />
          )}
        </Button>
      </Form>
      <div className="mt-2 mb-0 px-2 font-size-sm leading-tight text-gray-500">
        <p>
          <FormattedMessage
            id="components.commercetools.commercetoolsProjectForm.paragraph1"
            defaultMessage={`Authorize Locale to access your {name} project, please provide your {name}'s Project Key in the input above, Which can be found on Account > Projects > Manage Projects > Key`}
            values={{ name }}
          />
        </p>
      </div>
    </div>
  )
}

export default CommercetoolsProjectForm
