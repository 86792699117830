import * as Sentry from "@sentry/gatsby"
import { action, computed, persist, thunk } from "easy-peasy"
import { Auth, API } from "aws-amplify"

const defaultItem = {
  id: "",
  email: "",
  given_name: "",
  phone_number: "",
  "custom:type": ""
}

const USER_TYPES = {
  vendor: "vendor"
}

export default {
  item: defaultItem,
  isLoggedIn: false,
  isVendor: computed(
    [state => state.item, (_, storeState) => storeState.appMode.asClient],
    (user, asClient) => user["custom:type"]?.startsWith(USER_TYPES.vendor) && !asClient
  ),

  vendorLSP: computed(state => state.item["custom:type"]?.split("#")?.[1]),

  // thunks
  fetch: thunk(async actions => {
    try {
      const data = await Auth.currentUserInfo()
      actions.set({ id: data.username, ...data.attributes })
      actions.setIsLoggedIn(true)
    } catch (err) {
      actions.setIsLoggedIn(false)
      throw err
    }
  }),

  update: thunk( async (actions, { given_name, phone_number }, helper ) => {
    const user = helper.getStoreState().currentUser.item;
    actions.set({ ...user, given_name, phone_number })
    await API.post('nepal', '/user/profile', {
      body : { given_name, phone_number }
    });
  }),

  signOut: thunk(async actions => {
    actions.setIsLoggedIn(false)
    actions.set(defaultItem)
    await Auth.signOut()
  }),

  // actions
  set: action((state, payload) => {
    Sentry.setUser({
      id: payload.sub,
      email: payload.email,
    });
    state.item = payload
  }),

  setIsLoggedIn: action((state, payload) => {
    state.isLoggedIn = payload
  }),
}
