export const INTEGRATION_STATUSES = {
  live: "live",
  pending: "pending",
  private: "private",
}

export const INTEGRATION_IDS = {
  dummy: "dummy",
  email: "email",
  memoq: "memoq",
  memsource: "memsource",
  protemos: "protemos",
  plunet: "plunet",
  textmaster: "textmaster",
  trados: "trados",
}

export const INTEGRATION_TYPES = {
  tms: "tms",
  bms: "bms",
  hybrid: "hybrid",
}

export const INTEGRATION_PUBLIC_STATUSES = [
  INTEGRATION_STATUSES.live,
  INTEGRATION_STATUSES.pending,
]
