import React from "react"
import Form from "../../forms/Form"
import ControlFooter from "./ControlFooter"
import { useForm } from "react-hook-form"
import { CONNECTORS } from "../../../store/models/connectors"
import { useStoreActions, useStoreState } from "easy-peasy"
import { mailchimpAccountsSelectionField } from "./commonFields"

const MailchimpAccountsForm = () => {
    const currentOrg = useStoreState(state => state.currentOrg.item)
    const { connectors: { mailchimp } } = currentOrg
    const resources = mailchimp.map(({ accountId, accountName }) => ({ id: accountId, name: accountName }))
    const { errors, control, handleSubmit } = useForm({
        mode: "onChange",
    })
    const setFormField = useStoreActions(actions => actions.projectWizard.setFormField)
    let formValues = useStoreState(state => state.projectWizard.formValues)
    const onSubmit = values => {
        const { project: { account: { id, name } } } = values
        const providerID = `mailchimp#${id}`
        formValues.project.providerID = providerID
        formValues.project.connector.url="https://admin.mailchimp.com";
        formValues.project.name = name
        setFormField({
            project: formValues.project,
            type: CONNECTORS.mailchimp
        })
    }
    const fields = mailchimpAccountsSelectionField(resources)
    return (
        <Form
            fields={fields}
            errors={errors}
            control={control}
            className="project-form"
        >
            <ControlFooter
                errors={errors}
                withBack
                handleSubmit={handleSubmit}
                onNext={onSubmit}
            />
        </Form>
    )
}

export default MailchimpAccountsForm
