import { computed, action, thunk } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listOrganizationsByVendorID } from "../../graphql/locale-queries"

export default {
  items: [],

  byID: computed(state => id => state.items.find(item => item.id === id)),

  fetch: thunk(async (actions, { vendorID }) => {
    try {
      let clients = []
      let nextToken
      do {
        const { data } = await API.graphql(graphqlOperation(listOrganizationsByVendorID, {
          vendorID,
          nextToken,
        }))
        clients = [...clients, ...data.listOrganizationsByVendorID.items]
        nextToken = data.listOrganizationsByVendorID.nextToken
      } while (nextToken)
      actions.set(clients)
      return clients
    } catch (error) {
      console.error(error)
      actions.set([])
      return null
    }
  }),

  set: action((state, payload) => {
    state.items = payload.sort((first, second) => first.name?.localeCompare(second.name, undefined, { numeric: true }))
  }),
}
