import { action, computed, thunk } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listContentResourcesByOrganization } from "../../graphql/locale-queries"
import SORT_DIRECTION from "../../helpers/sortDirection"
import {
  filterContentByConnector,
  getTotal,
  groupByNamespace,
  isContentNamespace,
} from "../../helpers/contentResource"

export default {
  items: [],
  namespaces: [],
  nextToken: null,

  total: computed(state => getTotal(state.resources)),
  resources: computed(state => groupByNamespace(state.items)),

  hasMore: computed(
    state => !!state.nextToken && state.queryItems.length === 0
  ),

  insertContentResource: action((state, payload) => {
    state.items = [payload.data, ...state.items]

    if (!isContentNamespace(payload.connectorID, payload.data)) state.total++
  }),

  updateContentResource: action((state, payload) => {
    state.items = state.items.map(i =>
      i.id === payload.data.id ? payload.data : i
    )
  }),

  deleteContentResource: action((state, payload) => {
    state.items = state.items.filter(i => i.id !== payload.data.id)

    if (!isContentNamespace(payload.data.connectorID, payload.data))
      state.total--
  }),

  searchResourcesByTitle: thunk(async (actions, payload) => {
    try {
      let nextToken
      let items = []

      do {
        const { data } = await API.graphql(
          graphqlOperation(listContentResourcesByOrganization, {
            nextToken,
            connectorIDTitle: {
              beginsWith: { connectorID: payload.connector },
            },
            filter: {
              title: { contains: payload?.search || "" },
            },
            sortDirection: SORT_DIRECTION.ASC,
            organizationID: payload.organization,
          })
        )
        nextToken = data.listContentResourcesByOrganization.nextToken

        items = [...items, ...data.listContentResourcesByOrganization.items]
      } while (nextToken)

      return items
    } catch (error) {
      console.error(error)
      return []
    }
  }),

  fetch: thunk(async (actions, payload, { getState }) => {
    try {
      const { connector, organization, intl } = payload

      let items = []
      let nextToken
      do {
        const { data } = await API.graphql(
          graphqlOperation(listContentResourcesByOrganization, {
            nextToken,
            connectorIDTitle: {
              beginsWith: { connectorID: connector },
            },
            sortDirection: SORT_DIRECTION.ASC,
            organizationID: organization,
          })
        )
        nextToken = data.listContentResourcesByOrganization.nextToken
        items = [...items, ...data.listContentResourcesByOrganization.items]
      } while (nextToken)

      const filteredItems = items.filter(filterContentByConnector(connector))
      const namespace = items.filter(
        namespace => !filteredItems.find(i => i.id === namespace.id)
      )

      actions.setNamespace({ namespace, connector, intl })
      actions.set({ items, connector })
    } catch (e) {
      console.error(e)
    }
  }),

  setNamespace: action((state, payload) => {
    const joinNamespaceNames = item =>
      [...(item.namespace || []), item.title].join(" / ")

    state.namespaces = payload.namespace
      .sort((a, b) =>
        joinNamespaceNames(a).localeCompare(
          joinNamespaceNames(b),
          payload.intl?.locale,
          { numeric: true }
        )
      )
      .map(namespace => ({
        id: namespace.id,
        value: namespace.id,
        label: joinNamespaceNames(namespace),
      }))
  }),

  setToken: action((state, nextToken) => {
    state.nextToken = nextToken
  }),

  set: action((state, payload) => {
    const { items } = payload
    state.items = items
  }),
}
