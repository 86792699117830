import React from "react"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"
import Form from "../forms/Form"
import { fieldRequired } from "../../utils/validations"
import { API } from "aws-amplify"
import awsconfig from "../../aws-exports"

const AkeneoPimUrlForm = ({ name }) => {
  const intl = useIntl();
  const { handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  })

  const onSubmit = ({ pimUrl }) => {
    if (typeof window !== "undefined") window.location.href = `${process.env.NEPAL_REST_API}oauth/akeneo/activate?pim_url=${pimUrl}`
  }

  const fields = [
    {
      name: "pimUrl",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.akeneo.AkeneoPimUrlForm.formLabel",
        defaultMessage: "PIM Url",
      })}`,
      placeholder: "https://my-pim.cloud.akeneo.com",
      defaultValue: "",
      validations: { ...fieldRequired },
    },
  ]

  return (
    <div className='pr-4'>
      <Form
        className="text-left"
        errors={errors}
        fields={fields}
        control={control}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          variant="primary"
          className="btn btn--primary"
          type="submit"
          block
        >
          <FormattedMessage
            id="components.akeneo.domainForm.button"
            defaultMessage="Submit"
          />
        </Button>
      </Form>
      <div className="mt-2 mb-0 px-2 font-size-sm leading-tight text-gray-500">
        <p>
          <FormattedMessage
            id="components.akeneo.AkeneoPimUrlForm.paragraph1"
            defaultMessage={`Authorize Locale to access your {name} account, please provide your {name}'s PIM Url in the input above`}
            values={{ name }}
          />
        </p>
      </div>
    </div>
  )
}

export default AkeneoPimUrlForm
