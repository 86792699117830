import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"
import { useForm } from "react-hook-form"
import { useStoreActions, useStoreState } from "easy-peasy"
import Form from "../../forms/Form"
import { fieldRequired } from "../../../utils/validations"
import ControlFooter from "./ControlFooter"
import { localeOptionsForSelect } from "../../../helpers/locale"
import { DEFAULT_LOCALE_TAG } from "../../../store/models/locales"

const LocaleForm = () => {
  const intl = useIntl()

  const { handleSubmit, errors, control, setValue } = useForm({
    mode: "onChange",
  })

  const [loading, setLoading] = useState(false)

  const fetchLocales = useStoreActions(actions => actions.locales.fetch)

  const locales = useStoreState(state => state.locales.items)
  const localeOptions = localeOptionsForSelect(locales, intl)

  const { formValues, isLastStep } = useStoreState(state => state.projectWizard)
  const {
    submitForm,
    setFormField,
  } = useStoreActions(actions => actions.projectWizard)

  useEffect(() => {
    fetchLocales()
  }, [fetchLocales])

  useEffect(() => {
    if (locales.length === 0) return
    const defaultLocale = localeOptions.find(
      locale => locale.tag === DEFAULT_LOCALE_TAG
    )
    setValue("project[projectLocales][source]", defaultLocale)
  }, [locales])

  useEffect(() => {
    const targets = formValues?.project?.projectLocales?.targets
    if (targets) setValue("project[projectLocales][targets]", targets)
  }, [formValues?.project?.projectLocales?.targets])

  const localeFields = [
    {
      name: "project[projectLocales][source]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.sourceLabel",
        defaultMessage: "Source language",
      })}`,
      options: localeOptions,
      validations: { ...fieldRequired },
      isLoading: !localeOptions.length,
    },
    {
      name: "project[projectLocales][targets]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.targetsLabel",
        defaultMessage: "Target languages",
      })}`,
      placeholder: `${intl.formatMessage({
        id: "components.projects.form.fields.targetsPlaceholder",
        defaultMessage: "Select (one or more)",
      })}`,
      options: localeOptions,
      isLoading: !localeOptions.length,
      multi: true,
      validations: { ...fieldRequired },
    },
    { type: "divider" },
  ]

  const onSubmit = values => {
    setLoading(true)

    submitForm({ values, onSuccess: () => setLoading(false) })
  }

  const onNext = values => {
    setFormField({
      ...formValues,
      project: {
        ...formValues?.project,
        ...values.project,
      }
    })
  }

  return (
    <Form
      title={intl.formatMessage({
        id: "components.projects.form.formTitle",
        defaultMessage: "Add a project for translation",
      })}
      errors={errors}
      fields={localeFields}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        {...(isLastStep ? { onSubmit } : { onNext })}
        loading={loading}
      />
    </Form>
  )
}

export default LocaleForm
