import React, { useState, useEffect } from "react"
import { Modal } from "react-bootstrap"
import { STATUSES } from "../FormContainer"
import Icon from "../../Icon"

const InfoModal = ({
  children,
  title,
  subtitle,
  description,
  Trigger,
  status,
  setStatus,
  banner,
  onExit,
  className,
  dialogClassName,
  isOpen,
  scrollable,
  dialog,
  centered
}) => {
  const [show, setShow] = useState(false)
  const toggleModal = () => setShow(!show)

  useEffect(() => {
    if (status === STATUSES.success && show === true) {
      setStatus(null)
      toggleModal()
    }
  })

  useEffect(() => {
    if (isOpen !== undefined) setShow(isOpen)
  }, [isOpen])
  
  return (
    <>
      {Trigger && <Trigger onClick={toggleModal} />}
      <Modal
        show={show}
        onHide={toggleModal}
        scrollable={scrollable !== undefined ? scrollable : true}
        className={!dialog ? "d-flex justify-content-end" : ""}
        dialogClassName={!dialog ? `w-50 mt-3 mr-3 ${dialogClassName}` : ""}
        onExit={onExit}
        centered={centered}
      >
        <button className="btn-close" onClick={toggleModal}>
          <Icon name="x"/>
        </button>
        {banner && (
          <Modal.Header className="text-gray-500 border-gray-50 bg-gray-50">
            <Modal.Title>{banner}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={className}>
          <Modal.Title as="p" className="text-gray-500 font-medium leading-tight">
            {subtitle}
          </Modal.Title>
          <Modal.Title as="h1" className="text-gray-900 font-bold leading-tight">
            {title}
          </Modal.Title>
          {description && (
            <p className="pr-4 text-lg text-gray-700">{description}</p>
          )}
          {children}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default InfoModal
