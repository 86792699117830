import validURL from "valid-url"
import { isValidEmail } from "../helpers/email"
import { isValidPhoneNumber } from 'react-phone-number-input'

export const fieldRequired = {
  required: {
    value: true,
    message: "This field is required",
  },
}

export const validatePhone = {
  validate: value => {
    if(!value) return true
    return isValidPhoneNumber(value) || "Must be a valid phone number"
  }
}

export const validateEmail = {
  validate: value => {
    if(!value) return true
    return isValidEmail(value) || "Must be a valid email"
  }
}

export const validateURL = {
  validate: value => {
    if (!value) return true
    return !!validURL.isWebUri(value) || "Must be a valid URL"
  },
}

export const validatePathPattern = {
  validate: value => {
    if (!/\$\{localeCode\}/.test(value || '')) {
      return 'Make sure that the ${localeCode} is defined within the path'
    }
  }
}
