export const listContentResourcesIDByOrganization = /* GraphQL */ `
  query ListContentResourcesByOrganization(
    $organizationID: ID!
    $connectorIDTitle: ModelContentResourceByOrganizationConnectorTitleCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentResourcesByOrganization(
      organizationID: $organizationID
      connectorIDTitle: $connectorIDTitle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerType
        parentId
        namespace
        translatable
        title
      }
      nextToken
    }
  }
`;

export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        connector {
          isCuisto
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        format {
          framework
          id
          name
        }
        formatID
        id
        name
        organizationID
        owner
        createdAt
        updatedAt
        format {
          id
        }
      }
      nextToken
    }
  }
`;
