import React from "react"
import { useStoreState } from "easy-peasy"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Button } from "react-bootstrap"
import Form from "../forms/Form"
import { fieldRequired } from "../../utils/validations"
import { WIZARD_ROOT } from "../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../store/models/projectWizard"
import { stateGenerator } from "../../helpers/application"

const ZendeskDomainForm = ({ connector, name }) => {
  const intl = useIntl();
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const { handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  })
  const connectorRoute = `${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(connector)}`

  const onSubmit = ({ domain }) => {
    const state = stateGenerator({
      redirectURL: connectorRoute,
      domain
    });
    window.location.href = `https://${domain}/oauth/authorizations/new?response_type=code&redirect_uri=${encodeURIComponent(process.env.ZENDESK_REDIRECT_URI)}&client_id=${encodeURIComponent(process.env.ZENDESK_CLIENT_ID)}&scope=${encodeURIComponent(process.env.ZENDESK_SCOPES)}&state=${state}`;
  }

  const fields = [
    {
      name: "domain",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.azure.azureDomainForm.formLabel",
        defaultMessage: "Domain",
      })}`,
      placeholder: "",
      defaultValue: "",
      validations: { ...fieldRequired },
    },
  ]

  return (
    <div className='pr-4'>
      <Form
        className="text-left"
        errors={errors}
        fields={fields}
        control={control}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          variant="primary"
          className="btn btn--primary"
          type="submit"
          block
        >
          <FormattedMessage
            id="components.zendesk.domainForm.button"
            defaultMessage="Submit"
          />
        </Button>
      </Form>
      <div className="mt-2 mb-0 px-2 font-size-sm leading-tight text-gray-500">
        <p>
          <FormattedMessage
            id="components.zendesk.zendeskDomainForm.paragraph1"
            defaultMessage={`Authorize Locale to access your {name} account, please provide your {name} default domain name in the input above`}
            values={{ name }}
          />
        </p>
      </div>
    </div>
  )
}

export default ZendeskDomainForm
