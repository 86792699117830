export const defaultBrief = `Dear Translator,

This project is a **translation for a website**
Segments can contain:
- full sentences (e.g. "Your password is too short. Please choose another one with at least 12 characters.")
- part of a sentence (e.g. "cannot be blank", that will dynamically be inserted into sentences like "Email address cannot be blank." or "Name cannot be blank.")
- interface elements (e.g. menu text, button text, â€¦)

## System variables - DO NOT TOUCH!

System variables look like this:

    %{variable_name}

They must not be translated at all.
If a variable exists in source language, it must also appear in the translation.
The order can change, but the variables must be the same between source and target languages.

## HTML markups - DO NOT TOUCH!

HTML markups look like this:

    <span class="something">

Or like this:

    </span>

They must not be translated at all.
As a general rule of thumb: don't touch what's between < and >.

## Punctuation - RESPECT & ADAPT!

Original punctuation must be respected, unless there is a grammatical or cultural reason to do so.
Specifically, do not add or remove trailing dots, as it may break the sentence in which the segment may be dynamically inserted.
But please adapt punctuation to cultural requirements when necessary (e.g. there is no space before colons in English, but there should be one in many other languages).

## Capitalization - FOLLOW STYLE GUIDE!

Original capitalization should be adapted to the target language according to the "Capitalization" paragraph
of the appropriate Microsoft Localization Style Guide: https://www.microsoft.com/en-us/language/StyleGuides
`
