import React from "react"
import { Alert, Button } from "react-bootstrap"
import { FormattedMessage } from "gatsby-plugin-intl"
import CenteredContainer from "../../components/layout/CenteredContainer"
import Form from "../../components/forms/Form"
import Spinner from "../../components/common/Spinner"

const Divider = () => <hr className="mt-4 mb -5" />

export const STATUSES = {
  success: "success",
  loading: "loading",
  error: "error",
}

const FormContainer = ({
  errors,
  fields,
  onSubmit,
  submitLabel,
  title,
  control,
  status,
  classes
}) => (
  <CenteredContainer title={title}>
    {status === STATUSES.error && (
      <Alert variant="danger">
        <FormattedMessage
          id="components.forms.modalForm.modalBody.alert"
          defaultMessage="Whoops! The server returned an error"
          description="modal error alert"
        />
      </Alert>
    )}
    <Form
      className={`text-left ${classes}`}
      fields={fields}
      onSubmit={onSubmit}
      control={control}
      errors={errors}
    >
      <Button
        type="submit"
        className="btn btn--primary btn-block"
        disabled={errors?.length > 0 || status === STATUSES.loading}
      >
        {status === STATUSES.loading ? <Spinner /> : submitLabel}
      </Button>
    </Form>
  </CenteredContainer>
)

export default FormContainer
