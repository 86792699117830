import React, { useEffect } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { Link } from "gatsby-plugin-intl"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { path, redirect } from "../../../config/routes"
import Form from "../../forms/Form"
import { useForm } from "react-hook-form"
import { fieldRequired } from "../../../utils/validations"
import { Button } from "react-bootstrap"
import { API_CONNECTORS } from "../../../store/models/connectors"
import { hasApiKey, hasTestApiKey, isEmailable } from "../../../helpers/project"

export const shouldRedirectToSuccess = project =>
  API_CONNECTORS.includes(project.connector.id) && hasApiKey(project.connector.id);

const Success = ({ projectId }) => {
  const { errors, control, status, setValue } = useForm()

  const intl = useIntl()
  const project = useStoreState(state => state.projects.byId(projectId))
  const resetForm = useStoreActions(actions => actions.projectWizard.resetForm)

  useEffect(() => {
    return () => resetForm()
  }, [resetForm])

  useEffect(() => {
    if (project) {
      setValue(
        "email",
        `wpml+${project.liveApiKey?.replace("live_", "")}@locale.email`
      )
      setValue("project[liveApiKey]", project.liveApiKey)
      setValue("project[testApiKey]", project.testApiKey)
    }
  }, [project, setValue])

  const keyFields = []
  if (isEmailable(project)) {
    keyFields.push({
      name: "email",
      type: "copyToClip",
      label: intl.formatMessage({
        id: "components.projects.editForm.fields.email.label",
        defaultMessage: "Email address",
      }),
      description: intl.formatMessage({
        id: "components.projects.editForm.fields.email.description",
        defaultMessage: "The email address to give an access to your project",
      }),
      plaintext: true,
      readOnly: true,
      validations: { ...fieldRequired },
    })
    keyFields.push({
      name: "project[liveApiKey]",
      type: "hidden",
      validations: { ...fieldRequired },
    })
  } else {
    keyFields.push({
      name: "project[liveApiKey]",
      type: "copyToClip",
      label: intl.formatMessage({
        id: "components.projects.editForm.fields.apiKey.label",
        defaultMessage: "Live API key",
      }),
      moreInfo: `${process.env.API_DOCUMENTATION_URL}/#section/Authentication`,
      validations: { ...fieldRequired },
    })
    if (hasTestApiKey(project)) {
      keyFields.push({
        name: "project[testApiKey]",
        type: "copyToClip",
        label: intl.formatMessage({
          id: "components.projects.editForm.fields.testApiKey.label",
          defaultMessage: "Test API key",
        }),
        validations: { ...fieldRequired },
      })
    }
  }

  return (
    <div className="w-60">
      <h1 className="text-gray-900 font-bold leading-tight">
        <FormattedMessage
          id="components.projects.success.heading"
          defaultMessage="Your new project is all set up! ✨"
        />
      </h1>
      <p className="ml-1 mt-3 font-size-lg">
        <span
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: "components.projects.success.text",
                defaultMessage: isEmailable(project)
                  ? `Your <b>{project} Project</b> is ready to go. Below is the email address to invite that will give us access to your project. You can also find it in your project's `
                  : `Your <b>{project} Project</b> is ready to go. Below are your API key. Keep it safe. You can also find it in your project's `,
              },
              {
                project: project.name,
              }
            ),
          }}
        />
        <Link
          to={path("PROJECT", { id: projectId })}
          state={{ tab: "settings" }}
          className="text-dark text-underline"
          replace
        >
          <u>
            <FormattedMessage
              id="components.projects.success.link"
              defaultMessage="Settings"
            />
          </u>
          .
        </Link>
      </p>
      <div className="mt-4">
        <Form
          fields={keyFields}
          errors={errors}
          control={control}
          status={status}
        ></Form>
      </div>
      <Button
        onClick={() =>
          redirect("PROJECT", { id: projectId }, { state: { tab: "locales" } })
        }
        className="btn btn--primary mt-4"
      >
        Go to your project
      </Button>
    </div>
  )
}

export default Success
