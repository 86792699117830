export const SERVICE_MODES = {
  PROOFREADING: "proofreading",
  SYNCHRONIZATION: "synchronization",
}

export const SERVICE_TYPES = {
  CONNECTOR_CLOUD: "locale-team",
  LOCALE_AI: "locale-ai",
  PRO: "Pro",
}
