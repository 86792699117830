import React, { useState, useEffect } from "react"
import Form from "../../forms/Form"
import ControlFooter from "./ControlFooter"
import { useForm, useWatch } from "react-hook-form"
import { fieldRequired } from "../../../utils/validations"
import { useStoreActions, useStoreState } from "easy-peasy"
import { useIntl } from "gatsby-plugin-intl"

const ProjectTemplateForm = () => {
  const intl = useIntl()
  const { errors, control, handleSubmit, setValue } = useForm()
  const [fetchTemplatesLoading, setFetchTemplatesLoading] = useState(false)
  const [fetchIntegrationIsLoading, setFetchIntegrationIsLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const { isPartner, item: currentOrg } = useStoreState(
    state => state.currentOrg
  )
  const { submitForm, setFormField} = useStoreActions(actions => actions.projectWizard)
  const fetchProjectTemplates = useStoreActions(actions => actions.projectTemplates.fetch)
  const fetchAvailableIntegrations = useStoreActions(actions => actions.integrations.fetchAvailableIntegrations)
  const { formValues, isLastStep } = useStoreState(
    state => state.projectWizard
  )
  const projectTemplates = useStoreState(state => state.projectTemplates.items)
  const availableIntegrations = useStoreState(state => state.integrations.availableIntegrations)

  const projectIntegration = useWatch({
    control,
    name: "project[integration]",
    defaultValue: availableIntegrations.find(({ id }) => (currentOrg.integrationID === id)),
  })

  useEffect(() => {
    if (!projectIntegration) return

    setFetchTemplatesLoading(true)

    fetchProjectTemplates(projectIntegration).finally(() => setFetchTemplatesLoading(false))
  }, [projectIntegration, fetchProjectTemplates])

  useEffect(() => {
    if (!availableIntegrations.length ||!currentOrg.integrationID) return

    setValue('project[integration]', availableIntegrations.find(({ id }) => (currentOrg.integrationID === id)))
  }, [availableIntegrations, currentOrg, setValue])

  useEffect(() => {
    if (!currentOrg.id) return

    setFetchIntegrationIsLoading(true)
    fetchAvailableIntegrations({ organizationID: currentOrg.id }).then(() => setFetchIntegrationIsLoading(false))
  }, [fetchAvailableIntegrations, setFetchIntegrationIsLoading, currentOrg])

  const fields = [
    ...!isPartner ? [{
      name: "project[integration]",
      type: "select",
      label: `${intl.formatMessage({
        id: "components.projects.wizard.projectTemplatesForm.projectLanguageTool",
        defaultMessage: "Language tool",
      })}`,
      options: availableIntegrations,
      validations: { ...fieldRequired },
      isLoading: fetchIntegrationIsLoading,
    }] : [],
    ...projectIntegration && projectIntegration.integrationType?.configuration?.hasIntegrationTemplate
      ? [
        {
          name: "project[integrationTemplate]",
          type: "select",
          label: `${intl.formatMessage({
            id: "components.projects.wizard.projectTemplatesForm.projectTemplate",
            defaultMessage: "Project Template",
          })}`,
          helperText: intl.formatMessage({
            id: "components.projects.wizard.projectTemplatesForm.projectTemplate.description",
            defaultMessage: "The project template that will be executed when your content changes."
          }),
          options: projectTemplates,
          validations: { ...fieldRequired },
          isLoading: fetchTemplatesLoading,
        },
      ]
    : [],
    { type: "divider" }
  ]

  const onSubmit = values => {
    setSubmitLoading(true)

    const payload = {
      project: {
        integrationID: values?.project?.integration?.id,
        integrationTemplateID: values?.project?.integrationTemplate?.id,
      },
    }

    submitForm({ values: payload, onSuccess: () => setSubmitLoading(false) })
  }

  const onNext = values => {
    const project = {
      ...formValues?.project,
      integrationID: values.project.integration?.id,
      integrationTemplateID: values.project.integrationTemplate?.id,
    }

    setFormField({
      ...formValues,
      project,
    })
  }

  if (!availableIntegrations.length) {
    return <></>
  }

  return (
    <Form
      errors={errors}
      fields={fields}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        loading={submitLoading}
        {...(isLastStep ? { onSubmit } : { onNext })}
      />
    </Form>
  )
}

export default ProjectTemplateForm
