import { action, thunk, computed } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import { listLocales } from "../../graphql/locale-queries"

export const DEFAULT_LOCALE_TAG = "en"

export default {
  items: [],

  // computed
  byId: computed(state => id =>
    state.items.find(locale => String(locale.id) === String(id))
  ),

  availableForProject: computed(
    [
      state => state.items,
      (_, storeState) => storeState.projects.items
    ],
    (locales, projects) =>
    (projectID) => {
      const project = projects.find(project => project.id === projectID)
      const existingLocaleIDs = project?.locales?.items.map(projectLocale => projectLocale.localeID) || []
      return locales.filter((locale) => !existingLocaleIDs.includes(locale.id))
    }
	),

  // thunks
  fetch: thunk(async actions => {
    try {
      let locales = []
      let nextToken
      do {
        const { data } = await API.graphql(
          graphqlOperation(listLocales, { nextToken })
        )
        nextToken = data.listLocales.nextToken
        locales.push(...data.listLocales.items)
      } while (nextToken)
      actions.set(locales)
    } catch (e) {
      console.error(e)
    }
  }),

  //actions
  set: action((state, locales) => {
    state.items = locales
  }),
}
