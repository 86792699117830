import React from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { useForm } from "react-hook-form"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { Alert, Button } from "react-bootstrap"
import Form from "../forms/Form"
import { fieldRequired } from "../../utils/validations"
import { CONNECTORS } from "../../store/models/connectors"

const AdobeCommerceTokenForm = ({ onSuccess, name }) => {
  const intl = useIntl()
  const { handleSubmit, errors, control } = useForm({
    mode: "onSubmit",
  })
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const updateConnectors = useStoreActions(actions => actions.currentOrg.updateConnectors)

  const onSubmit = ({ accessToken, domain }) => {
    try {
      updateConnectors({
        connectorID: CONNECTORS.adobeCommerce,
        connectorAuth: { accessToken, domain },
        organizationID: currentOrg.id,
      })
      onSuccess()
    } catch (e) {
      console.error(e)
    }
  }

  const fields = [
    {
      name: "domain",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.adobeCommerce.adobeCommerceDomainForm.formLabel",
        defaultMessage: "Domain",
      })}`,
      placeholder: "https://mystore.commerce.com",
      defaultValue: "",
      validations: { ...fieldRequired },
    },
    {
      name: "accessToken",
      type: "text",
      label: `${intl.formatMessage({
        id: "components.adobeCommerce.adobeCommerceTokenForm.formLabel",
        defaultMessage: "Access Token",
      })}`,
      placeholder: "##########-########",
      defaultValue: currentOrg.connectors?.adobeCommerce?.accessToken,
      validations: { ...fieldRequired },
    },
  ]

  return (
    <div className='pr-4'>
      <Form
        className="text-left"
        errors={errors}
        fields={fields}
        control={control}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Button
          variant="primary"
          className="btn btn--primary"
          type="submit"
          block
        >
          <FormattedMessage
            id="components.adobeCommerce.adobeCommerceTokenForm.button"
            defaultMessage="Save"
          />
        </Button>
      </Form>
      <div className="mt-2 mb-0 px-2 font-size-sm leading-tight text-gray-500">
        <p>
          <FormattedMessage
            id="components.adobeCommerce.adobeCommerceTokenForm.paragraph1"
            defaultMessage={`To make your {name} resources
            available in your Locale account, you need to create an
            Access Token.`}
            values={{ name }}
          />
        </p>
        <p>
          <FormattedMessage
            id="components.adobeCommerce.adobeCommerceTokenForm.paragraph2"
            defaultMessage="Make sure to select the API scope
            which gives Locale read/write access to the API
            including all products, catalogs, etc.."
          />
        </p>
        <p>
          <Alert.Link target="_blank" href="https://experienceleague.adobe.com/docs/commerce-admin/systems/integrations/integrations.html?lang=en#:~:text=Add%20an%20integration,using%20OAuth%20for%20token%20exchange">
            <FormattedMessage
              id="components.adobeCommerce.adobeCommerceTokenForm.alertLink"
              defaultMessage="Read the full setup instructions"
            />
          </Alert.Link>
        </p>
      </div>
    </div>
  )
}

export default AdobeCommerceTokenForm