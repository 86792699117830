import React from "react"
import ConnectorList from "../../connectors/ConnectorList"
import Connector from "../../connectors/Connector"

const ConnectorSelect = () => {
  return (
    <ConnectorList
      renderConnector={({ connector, setShowToast, setRequested }) => (
        <Connector
          createProject
          connector={connector}
          setShowToast={setShowToast}
          setRequested={setRequested}
        />
      )}
    />
  )
}

export default ConnectorSelect
