import ProjectLocale from "./projectLocale"

export default class Project {
  constructor(attrs) {
    attrs = attrs || {}
    this.connector = attrs.connector || {}
    this.resourcesExcluded = attrs.resourcesExcluded || null
    this.formatID = attrs.formatID || null
    this.id = attrs.id || null
    this.liveApiKey = attrs.liveApiKey || null
    this.name = attrs.name || null
    this.organizationID = attrs.organizationID || null
    this.owner = attrs.owner || null
    this.providerID = attrs.providerID || null
    this.autoUpdateFrequency = attrs.autoUpdateFrequency || null
    this.resourcePatterns = attrs.resourcePatterns || []
    this.resources = attrs.resources || null
    this.translationBrief = attrs.translationBrief || null
    this.testApiKey = attrs.testApiKey || null
    this.createdAt = attrs.createdAt || null
    this.updatedAt = attrs.updatedAt || null
    this.format = attrs.format || {}
    this.organization = attrs.organization || {}
    this.isActive = attrs.isActive || false
    this.integrationID = attrs.integrationID || null
    this.integrationTemplateID = attrs.integrationTemplateID || null
    this.textmasterProjectSettings = attrs.textmasterProjectSettings || {}
    this._cuistoConfig = attrs?.cuistoConfig || {};
    this.locales = attrs?.locales?.items
      ? { items: attrs.locales.items.map(locale => new ProjectLocale(locale)) }
      : { items: [] }
  }

  get allCompleted() {
    return (
      this.targetLocales.length > 0 &&
      this.targetLocales.every(locale => locale.isCompleted)
    )
  }

  get atleastOneCompleted() {
    return (
      this.targetLocales.length > 0 &&
      this.targetLocales.some(locale => locale.isCompleted)
    )
  }

  get cuistoConfig() {
    if (!this._cuistoConfig) return []
    return typeof this._cuistoConfig === "string" ? JSON.parse(this._cuistoConfig) : this._cuistoConfig;
  }

  get isCalculating() {
    return this.targetLocales.every(locale => locale.isCalculating)
  }

  get isPushable() {
    return this.targetLocales.every(locale => locale.isPushable)
  }

  get atLeastOneInProgress() {
    return this.targetLocales.some(locale => locale.isInProgress)
  }

  get allInProgress() {
    return this.targetLocales.every(locale => locale.isInProgress)
  }

  get atLeastOneTranslatable() {
    return this.locales.items.some(locale => locale.isTranslatable)
  }

  get hasQuoteInProgress() {
    return this.locales.items.every(locale => locale.hasQuoteInProgress)
  }

  get isQuoteDelivered() {
    return this.locales.items.every(locale => locale.isQuoteComplete)
  }

  get canOrderTranslation() {
    return this.isQuoteDelivered && this.atLeastOneTranslatable
  }

  get translatableLocales() {
    return this.locales.items.filter(locale => locale.isTranslatable)
  }

  get sourceLocale() {
    return this.locales.items.find(locale => locale.isSource)
  }

  get targetLocales() {
    return this.locales.items.filter(locale => !locale.isSource)
  }
}
