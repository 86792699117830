import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  items: [],

  fetch: thunk(async (actions, { repoName, organizationID }) => {
    try {
      const params = {
        queryStringParameters: {
          repoName,
          organizationID,
        },
      }
      const branches = await API.get("nepal", "/gitlab/branches", params)
      actions.set(branches)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  set: action((state, branches) => {
    state.items = branches
  }),
}
