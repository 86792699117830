import React, { useEffect } from "react"
import Form from "../../forms/Form"
import ControlFooter from "./ControlFooter"
import { CONNECTORS } from "../../../store/models/connectors"
import { useStoreActions, useStoreState } from "easy-peasy"
import { useForm } from "react-hook-form"
import { driveFilesSelectionField } from "./commonFields"
import API from "@aws-amplify/api";

const fetchFileDetails = ({
    connectorId,
    organizationID,
    fileId,
    userId
  }) => {
    return API.get("nepal", "/googledrive/file", {
      queryStringParameters: {
        connectorId,
        organizationID,
        fileId,
        userId
      },
    })
  }

const labelMapping = {
    [CONNECTORS.googleSheets]: "Sheet",
    [CONNECTORS.googleDocs]: "Document"
}

const connectorModeMapping = {
  [CONNECTORS.googleDocs]: "document",
  [CONNECTORS.googleSheets]: "project"
}
const DriveFilesForm = () => {
    let formValues = useStoreState(state => state.projectWizard.formValues)
    const currentOrg = useStoreState(state => state.currentOrg.item)
    const fetchFiles = useStoreActions(actions => actions[formValues.project.connector.id].fetch)
    const resources = useStoreState(state => state[formValues.project.connector.id].items);
    const setFormField = useStoreActions(actions => actions.projectWizard.setFormField)
    formValues.project.connector.mode = connectorModeMapping[formValues.project.connector.id];
    const { errors, control, handleSubmit } = useForm({
        mode: "onChange",
    })
    useEffect(() => {
      if (currentOrg) fetchFiles({ organizationID: currentOrg.id, userId: formValues.project.providerID.split("#")[1] })
    }, [currentOrg, fetchFiles])
    const isLoading = useStoreState(state => state.googlesheets.isLoading);
    const onSubmit = async (values) => {
        const { project: { file: { id, name, link } } } = values
        formValues.project.connector.url = link;
        formValues.project.name = name;
        formValues.project.providerID += `#${id}`;
        setFormField({
            project: formValues.project
        })
        const [ connectorId, userId, fileId] = formValues.project.providerID.split("#")
        const { webViewLink, filePath } = await fetchFileDetails({
            connectorId,
            organizationID: currentOrg.id,
            fileId,
            userId
        })
        const projectLink = new URL(webViewLink);
        const prefix = projectLink.search ? `${projectLink.search}&` : "?";
        projectLink.search = `${prefix}filePath=${filePath.join("/")}`;
        formValues.project.connector.url = projectLink.toString();
        setFormField({
          project: formValues.project
        })
    }
    const fields = driveFilesSelectionField({ resources, label: labelMapping[formValues.project.connector.id]}, isLoading);
    return (
      <Form
          fields={fields}
          errors={errors}
          control={control}
          className="project-form"
      >
      <ControlFooter
          errors={errors}
          withBack
          handleSubmit={handleSubmit}
          onNext={onSubmit}
      />
      </Form>
    )
}

export default DriveFilesForm