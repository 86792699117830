import store from "../store/index"

export default class ProjectLocaleValidator {
  constructor(projectLocale) {
    this._projectID = projectLocale.projectID
    this._localeID = projectLocale.localeID
    this._code = projectLocale.code
  }

  static async call(args) {
    return new ProjectLocaleValidator(args).call()
  }

  async call() {
    try {
      const projectLocales = await this.fetchProjectLocales()
      return projectLocales
        .some((existingLocale) => existingLocale.code === this.code())
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  async fetchProjectLocales() {
    await store
      .getActions()
      .projectLocales
      .fetch(this.projectID())

    return await store
      .getState()
      .projectLocales
      .items
  }

  projectID(){
    return this._projectID
  }

  code(){
    return this._code
  }

  localeID(){
    return this._localeID
  }
}