import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  item: {},
  chargeableWords: {},

  fetch: thunk(async (actions, { workspaceType: slug }) => {
    try {
      if (slug === undefined) return
      const params = {
        queryStringParameters: {
          slug
        }
      }
      const vendorData = await API.get("nepal", "/contentful/vendor-data", params)
      actions.setItem(vendorData)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  setItem: action((state, item) => {
    state.item = item
  }),

  setChargeableWordCount: action((state, { month, wordCount}) => {
    state.chargeableWords[month] = wordCount
  }),

  chargeableWordCount: thunk(async (actions, { vendorID: vendor, month }) => {
    try {
      const { data: { wordCount } } = await API.get("nepal", "/vendors/usage", {
        queryStringParameters: { vendor, month }
      });
      actions.setChargeableWordCount({ month, wordCount })
    } catch (e) {
      console.error(e)
    }
  }),
}
