/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminListMembers = /* GraphQL */ `
  query AdminListMembers($organizationID: ID!) {
    adminListMembers(organizationID: $organizationID) {
      email
      email_verified
      given_name
      sub
      username
    }
  }
`;
export const getAccessRequest = /* GraphQL */ `
  query GetAccessRequest($id: ID!) {
    getAccessRequest(id: $id) {
      id
      itemId
      organizationID
      owner
      type
      createdAt
      updatedAt
    }
  }
`;
export const listAccessRequests = /* GraphQL */ `
  query ListAccessRequests(
    $filter: ModelAccessRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccessRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        itemId
        organizationID
        owner
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConnector = /* GraphQL */ `
  query GetConnector($id: ID!) {
    getConnector(id: $id) {
      category
      description
      id
      metadata {
        category
        logo
        name
        rank
      }
      mode
      name
      rank
      status
      type
      url
      createdAt
      updatedAt
    }
  }
`;
export const listConnectors = /* GraphQL */ `
  query ListConnectors(
    $filter: ModelConnectorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        autoUpdateModes
        category
        description
        id
        metadata {
          category
          logo
          name
          rank
          realTimeUpdateDescription
        }
        name
        rank
        status
        type
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContentResource = /* GraphQL */ `
  query GetContentResource($id: ID!) {
    getContentResource(id: $id) {
      connectorID
      groups
      id
      organizationID
      namespace
      parentId
      path
      providerCreatedAt
      providerID
      providerType
      providerUpdatedAt
      title
      translatable
      url
      createdAt
      updatedAt
    }
  }
`;
export const listContentResources = /* GraphQL */ `
  query ListContentResources(
    $filter: ModelContentResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentResources(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connectorID
        groups
        id
        organizationID
        namespace
        parentId
        path
        providerCreatedAt
        providerID
        providerType
        providerUpdatedAt
        title
        translatable
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listContentResourcesByOrganization = /* GraphQL */ `
  query ListContentResourcesByOrganization(
    $organizationID: ID!
    $connectorIDTitle: ModelContentResourceByOrganizationConnectorTitleCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContentResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentResourcesByOrganization(
      organizationID: $organizationID
      connectorIDTitle: $connectorIDTitle
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connectorID
        groups
        id
        organizationID
        namespace
        parentId
        path
        providerCreatedAt
        providerID
        providerType
        providerUpdatedAt
        title
        translatable
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFormat = /* GraphQL */ `
  query GetFormat($id: ID!) {
    getFormat(id: $id) {
      framework
      id
      isVisible
      name
      parser
      path
      createdAt
      updatedAt
    }
  }
`;
export const listFormats = /* GraphQL */ `
  query ListFormats(
    $filter: ModelFormatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      emailAddresses
      id
      groups
      name
      templateID
      integrationTypeID
      integrationType {
        id
        metadata {
          logo
          logoSquare
          name
        }
        name
        status
        type
        configuration
      }
      isCuisto
      cuistoConfig
      memoq_apiKey
      memoq_creatorUserID
      memoq_webServiceAPIAccessPoint
      memsource_accessToken
      memsource_clientId
      memsource_domain
      memsource_secretToken
      vendorID
      organizationID
      trados_accountId
      trados_languageCloudAccountId
      trados_clientId
      trados_clientSecret
      trados_webhookSecret
      textmaster_apiKey
      textmaster_apiSecret
      plunet_baseUrl
      plunet_username
      plunet_password
      protemos_apiKey
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listIntegrationsByVendor = /* GraphQL */ `
  query ListIntegrationsByVendor(
    $vendorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationsByVendor(
      vendorID: $vendorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        emailAddresses
        id
        groups
        name
        templateID
        integrationTypeID
        integrationType {
          id
          metadata {
            logo
            logoSquare
            name
          }
          status
          type
        }
        memoq_apiKey
        memoq_creatorUserID
        memoq_webServiceAPIAccessPoint
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listIntegrationsByOrganization = /* GraphQL */ `
  query ListIntegrationsByOrganization(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationsByOrganization(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        emailAddresses
        id
        groups
        name
        templateID
        integrationTypeID
        integrationType {
          id
          metadata {
            logo
            logoSquare
            name
          }
          status
          type
          configuration
        }
        memoq_apiKey
        memoq_creatorUserID
        memoq_webServiceAPIAccessPoint
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIntegrationType = /* GraphQL */ `
  query GetIntegrationType($id: ID!) {
    getIntegrationType(id: $id) {
      configuration
      createdAt
      id
      isCuisto
      metadata {
        logo
        logoSquare
        name
      }
      status
      type
      updatedAt
    }
  }
`;
export const listIntegrationTypes = /* GraphQL */ `
  query ListIntegrationTypes(
    $filter: ModelIntegrationTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrationTypes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        configuration
        createdAt
        id
        integrationLevel
        metadata {
          logo
          logoSquare
          name
        }
        status
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLocale = /* GraphQL */ `
  query GetLocale($id: ID!) {
    getLocale(id: $id) {
      id
      language
      region
      tag
      createdAt
      updatedAt
    }
  }
`;
export const listLocales = /* GraphQL */ `
  query ListLocales(
    $filter: ModelLocaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      connectors {
        adobecommerce {
          accessToken
          domain
        }
        azurerepos {
          refreshToken
        }
        bitbucket {
          clientKey
        }
        contentful {
          environmentIDs
          spaceID
          spaceName
        }
        github {
          installationIDs
        }
        gitlab {
          accessToken
        }
        googledocs {
          refreshToken
          userId
        }
        googlesheets {
          refreshToken
          userId
        }
        hubspotcms {
          hubDomain
          hubId
          refreshToken
        }
        intercom {
          accessToken
          workspaceId
        }
        salesforceknowledge {
          accessToken
          instanceUrl
          refreshToken
        }
        shopify {
          accessToken
          storeId
        }
        mailchimp {
          accessToken
          accountId
          accountName
          serverId
        }
        zendesk {
          accessToken
          domain
        }
      }
      customerID
      groups
      id
      integrationID
      name
      onboarding {
        isCompleted
        stage
        type
      }
      owner
      type
      integrationTemplateID
      vendorID
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        connectors {
          adobecommerce {
            accessToken
            domain
          }
          akeneo {
            accessToken
            catalog
            pimUrl
          }
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          commercetools {
            accessToken
            projectKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          dropbox {
            accessToken
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          lokalise {
            refreshToken
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
        vendor {
          integrationMode
          integrationTypeIDs
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOrganizationsByVendorID = /* GraphQL */ `
  query ListOrganizationsByVendorID(
    $vendorID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizationsByVendorID(
      vendorID: $vendorID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        bmsAccountID
        connectors {
          adobecommerce {
            accessToken
            domain
          }
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        integration {
          id
          groups
          name
          templateID
          integrationTypeID
          integrationType {
            id
            metadata {
              logo
              logoSquare
              name
            }
            status
            type
          }
          memoq_apiKey
          memoq_creatorUserID
          memoq_webServiceAPIAccessPoint
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          createdAt
          updatedAt
          owner
        }
        bmsIntegration {
          id
          groups
          name
          integrationTypeID
          integrationType {
            id
            name
            metadata {
              logo
              logoSquare
              name
            }
            status
            type
            configuration
          }
          templateID
          memoq_apiKey
          memoq_creatorUserID
          memoq_webServiceAPIAccessPoint
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          protemos_apiKey
          plunet_baseUrl
          plunet_username
          plunet_password
          createdAt
          updatedAt
          owner
        }
        bmsIntegrationID
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        status
        vendorID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      connector {
        isCuisto
        branch
        mode
        organization
        project
        repository
        type
        url
        webhookURL
      }
      cuistoConfig
      resourcesExcluded
      format {
        framework
        id
        isVisible
        name
        parser
        path
        createdAt
        updatedAt
      }
      formatID
      groups
      id
      isActive
      liveApiKey
      locales {
        items {
          audit {
            completion
            duration
            missingSegments
            missingWords
            price
            segments
            words
          }
          code
          groups
          id
          inProgress
          isActive
          isSource
          locale {
            id
            language
            region
            tag
            createdAt
            updatedAt
          }
          localeID
          organizationID
          owner
          project {
            resourcesExcluded
            formatID
            groups
            id
            isActive
            liveApiKey
            name
            organizationID
            owner
            providerID
            integrationTemplateID
            translationBrief
            testApiKey
            createdAt
            updatedAt
          }
          projectID
          stateUpdatedAt
          createdAt
          updatedAt
        }
        nextToken
      }
      name
      organization {
        connectors {
          adobecommerce {
            accessToken
            domain
          }
          azurerepos {
            refreshToken
          }
          bitbucket {
            clientKey
          }
          contentful {
            environmentIDs
            spaceID
            spaceName
          }
          github {
            installationIDs
          }
          gitlab {
            accessToken
          }
          googledocs {
            refreshToken
            userId
          }
          googlesheets {
            refreshToken
            userId
          }
          hubspotcms {
            hubDomain
            hubId
            refreshToken
          }
          intercom {
            accessToken
            workspaceId
          }
          salesforceknowledge {
            accessToken
            instanceUrl
            refreshToken
          }
          shopify {
            accessToken
            storeId
          }
          mailchimp {
            accessToken
            accountId
            accountName
            serverId
          }
          zendesk {
            accessToken
            domain
          }
        }
        customerID
        groups
        id
        integrationID
        name
        onboarding {
          isCompleted
          stage
          type
        }
        owner
        type
        integrationTemplateID
        vendorID
        createdAt
        updatedAt
      }
      organizationID
      owner
      providerID
      autoUpdateFrequency
      resourcePatterns {
        path
      }
      resources {
        path
        projectLocaleID
      }
      textmasterProjectSettings {
        autoLaunch
        expertiseId
        subExpertiseId
      }
      integrationID
      integrationTemplateID
      translationBrief
      testApiKey
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        connector {
          branch
          mode
          isCuisto
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectByLiveApiKey = /* GraphQL */ `
  query GetProjectByLiveApiKey(
    $liveApiKey: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProjectByLiveApiKey(
      liveApiKey: $liveApiKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            templateID
            integrationTypeID
            memoq_apiKey
            memoq_creatorUserID
            memoq_webServiceAPIAccessPoint
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProjectsByOrganizationAndProviderID = /* GraphQL */ `
  query ListProjectsByOrganizationAndProviderID(
    $organizationID: ID!
    $providerID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrganizationAndProviderID(
      organizationID: $organizationID
      providerID: $providerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            templateID
            integrationTypeID
            memoq_apiKey
            memoq_creatorUserID
            memoq_webServiceAPIAccessPoint
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProjectsByProviderID = /* GraphQL */ `
  query ListProjectsByProviderID(
    $providerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByProviderID(
      providerID: $providerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            templateID
            integrationTypeID
            memoq_apiKey
            memoq_creatorUserID
            memoq_webServiceAPIAccessPoint
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectByTestApiKey = /* GraphQL */ `
  query GetProjectByTestApiKey(
    $testApiKey: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProjectByTestApiKey(
      testApiKey: $testApiKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            templateID
            integrationTypeID
            memoq_apiKey
            memoq_creatorUserID
            memoq_webServiceAPIAccessPoint
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectLocale = /* GraphQL */ `
  query GetProjectLocale($id: ID!) {
    getProjectLocale(id: $id) {
      audit {
        completion
        duration
        missingSegments
        missingWords
        price
        segments
        words
      }
      code
      groups
      id
      inProgress
      isActive
      isSource
      locale {
        id
        language
        region
        tag
        createdAt
        updatedAt
      }
      localeID
      organizationID
      owner
      project {
        connector {
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          integration {
            id
            groups
            name
            templateID
            integrationTypeID
            memoq_apiKey
            memoq_creatorUserID
            memoq_webServiceAPIAccessPoint
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            createdAt
            updatedAt
            owner
          }
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      projectID
      stateUpdatedAt
      createdAt
      updatedAt
    }
  }
`;
export const listProjectLocales = /* GraphQL */ `
  query ListProjectLocales(
    $filter: ModelProjectLocaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectLocales(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        audit {
          completion
          duration
          missingSegments
          missingWords
          price
          segments
          words
        }
        code
        groups
        id
        inProgress
        isActive
        isSource
        locale {
          id
          language
          region
          tag
          createdAt
          updatedAt
        }
        localeID
        organizationID
        owner
        project {
          connector {
            branch
            mode
            organization
            project
            repository
            type
            url
            webhookURL
          }
          resourcesExcluded
          format {
            framework
            id
            isVisible
            name
            parser
            path
            createdAt
            updatedAt
          }
          formatID
          groups
          id
          isActive
          liveApiKey
          locales {
            nextToken
          }
          name
          organization {
            customerID
            groups
            id
            integrationID
            name
            owner
            type
            integrationTemplateID
            vendorID
            createdAt
            updatedAt
          }
          organizationID
          owner
          providerID
          resourcePatterns {
            path
          }
          resources {
            path
            projectLocaleID
          }
          textmasterProjectSettings {
            autoLaunch
            expertiseId
            subExpertiseId
          }
          integrationTemplateID
          translationBrief
          testApiKey
          createdAt
          updatedAt
        }
        projectID
        stateUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listProjectLocalesByProject = /* GraphQL */ `
  query ListProjectLocalesByProject(
    $projectID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectLocaleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectLocalesByProject(
      projectID: $projectID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        audit {
          completion
          duration
          missingSegments
          missingWords
          price
          segments
          words
        }
        code
        groups
        id
        inProgress
        isActive
        isSource
        locale {
          id
          language
          region
          tag
          createdAt
          updatedAt
        }
        localeID
        organizationID
        owner
        project {
          connector {
            branch
            mode
            organization
            project
            repository
            type
            url
            webhookURL
          }
          resourcesExcluded
          format {
            framework
            id
            isVisible
            name
            parser
            path
            createdAt
            updatedAt
          }
          formatID
          groups
          id
          isActive
          liveApiKey
          locales {
            nextToken
          }
          name
          organization {
            customerID
            groups
            id
            integrationID
            name
            owner
            type
            integrationTemplateID
            vendorID
            createdAt
            updatedAt
          }
          organizationID
          owner
          providerID
          resourcePatterns {
            path
          }
          resources {
            path
            projectLocaleID
          }
          textmasterProjectSettings {
            autoLaunch
            expertiseId
            subExpertiseId
          }
          integrationTemplateID
          translationBrief
          testApiKey
          createdAt
          updatedAt
        }
        projectID
        stateUpdatedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVendor = /* GraphQL */ `
  query GetVendor($id: ID!) {
    getVendor(id: $id) {
      defaultIntegrationID
      defaultIntegration {
        emailAddresses
        id
        groups
        name
        templateID
        integrationTypeID
        integrationType {
          configuration
          id
          metadata {
            logo
            logoSquare
            name
          }
          status
          type
        }
        memoq_apiKey
        memoq_creatorUserID
        memoq_webServiceAPIAccessPoint
        memsource_accessToken
        memsource_clientId
        memsource_domain
        memsource_secretToken
        vendorID
        organizationID
        trados_accountId
        trados_languageCloudAccountId
        trados_clientId
        trados_clientSecret
        trados_webhookSecret
        textmaster_apiKey
        textmaster_apiSecret
        createdAt
        updatedAt
        owner
      }
      groups
      id
      integrations {
        items {
          emailAddresses
          id
          groups
          name
          templateID
          integrationTypeID
          integrationType {
            configuration
            id
            metadata {
              logo
              logoSquare
              name
            }
            status
            type
          }
          memoq_apiKey
          memoq_creatorUserID
          memoq_webServiceAPIAccessPoint
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      integrationMode
      integrationTypeIDs
      name
      createdAt
      updatedAt
    }
  }
`;
export const listVendors = /* GraphQL */ `
  query ListVendors(
    $filter: ModelVendorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVendors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        defaultIntegrationID
        defaultIntegration {
          emailAddresses
          id
          groups
          name
          templateID
          integrationTypeID
          integrationType {
            id
            metadata {
              logo
              logoSquare
              name
            }
            status
            type
          }
          memoq_apiKey
          memoq_creatorUserID
          memoq_webServiceAPIAccessPoint
          memsource_accessToken
          memsource_clientId
          memsource_domain
          memsource_secretToken
          vendorID
          organizationID
          trados_accountId
          trados_languageCloudAccountId
          trados_clientId
          trados_clientSecret
          trados_webhookSecret
          textmaster_apiKey
          textmaster_apiSecret
          createdAt
          updatedAt
          owner
        }
        groups
        id
        integrations {
          items {
            emailAddresses
            id
            groups
            name
            templateID
            integrationTypeID
            integrationType {
              id
              metadata {
                logo
                logoSquare
                name
              }
              status
              type
            }
            memoq_apiKey
            memoq_creatorUserID
            memoq_webServiceAPIAccessPoint
            memsource_accessToken
            memsource_clientId
            memsource_domain
            memsource_secretToken
            vendorID
            organizationID
            trados_accountId
            trados_languageCloudAccountId
            trados_clientId
            trados_clientSecret
            trados_webhookSecret
            textmaster_apiKey
            textmaster_apiSecret
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        integrationMode
        integrationTypeIDs
        name
        onboarding {
          isCompleted
          partner
          stage
          type
        }
        createdAt
        updatedAt
        website
      }
      nextToken
    }
  }
`;
export const listProjectsByOrganizationID = /* GraphQL */ `
  query ListProjectsByOrganizationID(
    $organizationID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrganizationID(
      organizationID: $organizationID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        connector {
          isCuisto
          branch
          mode
          organization
          project
          repository
          type
          url
          webhookURL
        }
        resourcesExcluded
        format {
          framework
          id
          isVisible
          name
          parser
          path
          createdAt
          updatedAt
        }
        formatID
        groups
        id
        integrationID
        isActive
        liveApiKey
        locales {
          items {
            code
            groups
            id
            inProgress
            isActive
            isSource
            localeID
            organizationID
            owner
            projectID
            stateUpdatedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        name
        organization {
          customerID
          groups
          id
          integrationID
          name
          onboarding {
            isCompleted
            stage
            type
          }
          owner
          type
          integrationTemplateID
          vendorID
          vendor {
            defaultIntegrationID
            groups
            id
            integrationMode
            integrationTypeIDs
            name
            createdAt
            updatedAt
            owner
          }
          createdAt
          updatedAt
        }
        organizationID
        owner
        providerID
        resourcePatterns {
          path
        }
        resources {
          path
          projectLocaleID
        }
        textmasterProjectSettings {
          autoLaunch
          expertiseId
          subExpertiseId
        }
        integrationTemplateID
        translationBrief
        testApiKey
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;