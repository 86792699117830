import { FormattedMessage } from "gatsby-plugin-intl"
import React from "react"
import {
  fieldRequired,
  validateURL,
  validatePathPattern,
} from "../../../utils/validations"

export const apiFields = (intl) => [
  {
    name: "project[liveApiKey]",
    type: "hidden",
    validations: { ...fieldRequired },
  },
  {
    name: "project[testApiKey]",
    type: "hidden",
    validations: { ...fieldRequired },
  },
]

export const formatFields = ({ intl, format, resource }) => [
  ...(format?.isHidden
    ? [
        {
          name: "project[format]",
          type: "hidden",
          validations: { ...fieldRequired },
          defaultValue: format.data,
        },
      ]
    : [
        {
          name: "project[format]",
          type: "select",
          label: `${intl.formatMessage({
            id: "components.projects.form.fields.formatLabel",
            defaultMessage: "Format",
          })}`,
          validations: { ...fieldRequired },
          options: format.data,
          isLoading: format.isLoading,
        },
      ]),
  ...(resource
    ? [
        {
          name: "project[resourcePatterns][0][path]",
          type: "text",
          label: `${intl.formatMessage({
            id: "components.projects.form.fields.pathPatternLabel",
            defaultMessage: "File path pattern",
          })}`,
          isDisabled: resource?.isDisabled,
          helperText: (
            <p className="m-0">
              <p>
                <FormattedMessage
                  id="components.projects.form.fields.pathPatternHelper"
                  defaultMessage="Path of the file to translate. Must contain {localeCode}. This will be replaced with the locale code that corresponds to your selected source language."
                  values={{ localeCode: <b className="font-monospace">{"${localeCode}"}</b> }}
                />
              </p>
              <h5 className="m-0">{"Examples:"}</h5>
              <ul className="pl-3 m-0">
                <li>{"config/locale/${localeCode}.yml"}</li>
                <li>{"src/locale/${localeCode}.json "}</li>
                <li>{"resources/lang/${localeCode}/messages.php"}</li>
              </ul>
            </p>
          ),
          validations: { ...validatePathPattern },
        },
      ]
    : []),
]

export const gitFields = (intl, state, repos, branches, installationUrl) => [
  {
    name: "project[repository]",
    type: "select",
    label: `${intl.formatMessage({
      id: "components.projects.form.fields.repositoryLabel",
      defaultMessage: "Repository",
    })}`,
    validations: { ...fieldRequired },
    ...(state
      ? {
          link: (
            <p className="m-0 text-nowrap">
              {intl.formatMessage(
                {
                  id: "components.projects.form.fields.repositoryLink",
                  defaultMessage:
                    "Your repo not showing? <a>Configure Access</a>",
                },
                {
                  a: content => (
                    <a
                      className="link"
                      href={ installationUrl || `${process.env.GITHUB_APP_URL}?state=${state}`}
                    >
                      {content}
                    </a>
                  ),
                }
              )}
            </p>
          ),
        }
      : {}),
    options: repos.data,
    isLoading: repos.isLoading,
  },
  {
    name: "project[branch]",
    type: "select",
    label: `${intl.formatMessage({
      id: "components.projects.form.fields.branchLabel",
      defaultMessage: "Source Branch",
    })}`,
    validations: { ...fieldRequired },
    options: branches.data,
    isLoading: branches.isLoading,
    isDisabled: branches.isDisabled,
  },
]

export const webhookFields = (intl) => [
  {
    name: "project[webhookURL]",
    type: "text",
    validations: { ...validateURL },
    label: `${intl.formatMessage({
      id: "components.projects.form.fields.api.webhookUrl.label",
      defaultMessage: "Webhook URL",
    })}`,
    description: "Where your translated data is sent to",
    placeholder: "https://example.com/my/webhook/endpoint",
  },
]

const commonFields = [
  {
    name: "project[connector]",
    type: "hidden",
    validations: { ...fieldRequired },
  },
  {
    name: "project[name]",
    type: "hidden",
    validations: { ...fieldRequired },
  },
]

export const checkRequiredFieldsFilled = (project, fields) => {
  const requiredFields = fields
    .filter(f => f?.validations?.required)
    .map(f => f?.name.replaceAll(/(project\[)|\]/g, ''))

  return (
    project && requiredFields.every(field => project?.[field])
  )
}

export const hubspotAdditionalFields = (
  {
    resourceType, 
    resources, 
    description, 
    intl,
    label,
    options
  }
) => [
  {
      name: "project[resourceType]",
      type: "radio",
      label: `${intl.formatMessage({
        id: "components.projects.form.fields.resourceTypeLabel",
        defaultMessage: "Select Resource Type",
      })}`,
      description: "What resource type to be translated",
      options,
      validations: { ...fieldRequired }
  },
  {
      name: "project[resourceScope]",
      type: "select",
      label,
      description,
      placeholder: `No ${label} selected`,
      options: resources,
      isDisabled: (resourceType === undefined),
      isLoading: (resources.length < 1 && resourceType !== undefined),
      validations: { ...fieldRequired }
  },
  { type: "divider" }
]

export const hubspotAccountSelectionField = (resources) => [
  {
      name: "project[account]",
      type: "select",
      label: "Accounts",
      description: 'Hubspot accounts',
      placeholder: `No account selected`,
      options: resources,
      isLoading: (resources.length < 1),
      validations: { ...fieldRequired }
  },
  { type: "divider" }
] 

export const mailchimpAccountsSelectionField = (resources) => [
  {
    name: "project[account]",
    type: "select",
    label: "Accounts",
    description: "Mailchimp account",
    placeholder: "No account selected",
    options: resources,
    isLoading: (resources.length < 1),
    validations: { ...fieldRequired }
  },
  { type: "divider" }
]
export const mailchimpCampaignFoldersField = (resources, isLoading) => [
  {
    name: "project[folder]",
    type: "select",
    label: "Campaign Folder",
    description: "Campaign Folder",
    placeholder: "No campaign folder selected",
    options: resources,
    isLoading: isLoading,
    validations: { ...fieldRequired }
  },
  { type: "divider" }
]

export const driveFilesSelectionField = ({ label, resources }) => [
  {
    name: "project[file]",
    type: "select",
    label: label,
    description: label,
    placeholder: "No file selected",
    options: resources,
    isLoading: (resources.length < 1),
    validations: { ...fieldRequired }
  },
  { type: "divider" }
]

export default commonFields
