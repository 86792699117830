import Onboarding, { TYPES } from "./onboarding"

export const ORG_TYPES = {
  demo: "demo",
  locale: "locale",
  rws: "rws",
  services: "services",
  supertext: "supertext",
  textmaster: "textmaster",
  translated: "translated",
}

export const ORGANIZATION_STATUSES = {
  ACTIVE: 'active',
  SUSPENDED: 'suspended',
}


export default class Organization {
  constructor(attrs) {
    attrs = attrs || {}
    this.connectors = attrs.connectors || {}
    this.createdAt = attrs.createdAt
    this.customerID = attrs.customerID
    this.groups = attrs.groups
    this.id = attrs.id
    this.integrationID = attrs.integrationID
    this.integration = attrs.integration
    this.name = attrs.name
    this.onboarding = new Onboarding({
      ...attrs.onboarding,
      type: attrs.type === ORG_TYPES.locale ? TYPES.languageTeam : TYPES.newUser,
      organization: this,
    })
    this.owner = attrs.owner
    this.integrationTemplateID = attrs.integrationTemplateID
    this.status = attrs.status
    this.type = attrs.type
    this.updatedAt = attrs.updatedAt
    this.vendor = attrs.vendor
    this.vendorID = attrs.vendorID
  }

  get isSuspended() {
    return this.status === ORGANIZATION_STATUSES.SUSPENDED
  }

  get isOnboard() {
    return this.onboarding.isCompleted
  }

  get isOnboarding() {
    return !this.isOnboard
  }
}
