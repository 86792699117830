import { API } from "aws-amplify"

export default class GitlabWebhookCreator {
  _connector
  _organizationID
  constructor({ connector, organizationID }) {
    this._connector = connector
    this._organizationID = organizationID
  }

  static async call(args) {
    return new GitlabWebhookCreator(args).call()
  }

  async call() {
    try {
      await API.post("nepal", "/gitlab/webhook", {
        body: {
          repository: this._connector.repository,
          organizationID: this._organizationID,
        },
      })

      return
    } catch (error) {
      return
    }
  }
}
