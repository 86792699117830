const emailRegexp = new RegExp(/\S+@\S+\.\S+/)

export const isValidEmail = (email) => {
  return emailRegexp.test(email)
}

export const getCommaSeparatedEmail = (emails) => {
  return emails.split(',').map(email => email.trim())
}

export const extractGivenName = (email) => {
  let [ name ] = email.toLowerCase().split('@')

  return name.split(/[_.]/g).map((part) => {
    let firstChar = part.charAt(0).toUpperCase()
    return firstChar + part.substr(1)
  }).join(' ')
}

export default { isValidEmail, extractGivenName }
