import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  items: [],
  isLoading: false,
  fetch: thunk(async (actions, { organizationID, accountId }) => {
    try {
      const params = {
        queryStringParameters: {
          organizationID,
          accountId
        }
      }
      actions.setLoading(true);
      const mailchimpResources = await API.get("nepal", "/mailchimp/campaign-folders", params);
      actions.setLoading(false);
      actions.setItems(mailchimpResources)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  setItems: action((state, items) => {
    state.items = items
  }),
  setLoading: action((state, payload)=>{
    state.isLoading = payload
  })
}
