import React from "react"
import Form from "../../forms/Form"
import ControlFooter from "./ControlFooter"
import { useForm } from "react-hook-form"
import { CONNECTORS } from "../../../store/models/connectors"
import { useStoreActions, useStoreState } from "easy-peasy"
import { hubspotAccountSelectionField } from "./commonFields"

const HubspotAccountsForm = () => {
    const currentOrg = useStoreState(state => state.currentOrg.item)
    const { connectors: { hubspotcms } } = currentOrg
    const resources = hubspotcms.map(({ hubId, hubDomain }) => ({ id: hubId, name: hubDomain }))
    const { errors, control, handleSubmit } = useForm({
        mode: "onChange",
    })
    const setFormField = useStoreActions(actions => actions.projectWizard.setFormField)
    let formValues = useStoreState(state => state.projectWizard.formValues)
    const onSubmit = values => {
        const { project: { account: { id } } } = values
        const providerID = `hubspotcms#${id}`
        formValues.project.providerID = providerID
        setFormField({
            project: formValues.project,
            type: CONNECTORS.hubspotcms
        })
    }
    const fields = hubspotAccountSelectionField(resources)

    return (
        <Form
            fields={fields}
            errors={errors}
            control={control}
            className="project-form"
        >
            <ControlFooter
                errors={errors}
                withBack
                handleSubmit={handleSubmit}
                onNext={onSubmit}
            />
        </Form>
    )
}

export default HubspotAccountsForm
