import { hasFramework } from "./format"

export const connectorLogoURL = (name = "default") => {
  return [imagesURL(), "connectors", `${name}.png`].join("/")
}

export const frameworkLogoURL = ({ name, type = "icon" }) => {
  return [imagesURL(), "frameworks", `${name}-${type}.svg`].join("/")
}

export const imagesURL = () => {
  return `${process.env.STATIC_SITE_URL}/images`
}

export const projectDefaultLogoURL = (type = "icon") => {
  return [imagesURL(), "projects", `default-${type}.svg`].join("/")
}

export const projectLogoURL = (project, type = "icon") => {
  if (!project) return projectDefaultLogoURL(type)
  return hasFramework(project.format)
    ? frameworkLogoURL({ name: project.format?.framework })
    : (project.connector?.logoUrl) ? project.connector?.logoUrl : connectorLogoURL()
}
