import React, { useState } from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { InputGroup } from "react-bootstrap"
import Icon from "../Icon"
import InputField from "./InputField"

let timeoutId = null;

const CopyToClip = ({
  name,
  type,
  control,
  errors,
  validations,
  defaultValue,
  placeholder,
  plaintext = false,
  readOnly = true,
}) => {
  const [copied, setCopied] = useState(false)

  const onClipBoardClick = () => {
    setCopied(true)

    navigator.clipboard.writeText(control.getValues(name))

    if(timeoutId !== null) clearTimeout(timeoutId)

    timeoutId = setTimeout(() => {
      setCopied(false)
    }, 2000)
  }

  return (
    <InputGroup onClick={onClipBoardClick}>
      <InputField
        {...{
          name,
          type,
          control,
          errors,
          validations,
          defaultValue,
          placeholder,
          plaintext,
          readOnly,
        }}
      />
      <InputGroup.Append>
        <InputGroup.Text role="button">
          {copied ? (
            <span className="text-green-500">
              <FormattedMessage
                id="components.clipToBoard.copiedText"
                defaultMessage="Copied"
              />
            </span>
          ) : (
            <Icon name="clipboard" />
          )}
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  )
}

export default CopyToClip
