import React from "react"
import classNames from "classnames"

const Icon = ({ name, className, title }) => {
  const classes = classNames("fe", `fe-${name}`, className, { title })
  return (
    <>
      <i className={classes} />
      {title}
    </>
  )
}

export default Icon
