import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"

export default {
  items: [],

  fetch: thunk(async (actions, { organizationID }) => {
    try {
      const params = {
        queryStringParameters: {
          organizationID,
        },
      }
      const githubRepos = await API.get("nepal", "/github/repos", params)
      actions.set(githubRepos)
    } catch (e) {
      console.error(e)
    }
  }),

  // actions
  set: action((state, githubRepos) => {
    state.items = githubRepos
  }),
}
