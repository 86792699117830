import path from "path"
import { LOCALE_PATTERN } from "../store/models/formats"

export const formatPath = (format, locale) => {
  return format.path.replace(LOCALE_PATTERN, locale.code)
}

export const appendIndexToPath = (formattedPath, index) => {
  const fileExt = path.extname(formattedPath)
  return formattedPath.replace(fileExt, `-${index}${fileExt}`)
}

export const hasFramework = (format) => {
  return !!format.framework
}

export const formatCreditCardBrand = brandName =>
  brandName.replace(/[^a-zA-Z\s]/g, " ").toUpperCase()
