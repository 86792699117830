import React from "react"
import { Controller } from "react-hook-form"
import PhoneInput from 'react-phone-number-input'


const PhoneField = ({
  autocomplete,
  name,
  control,
  errors,
  validations,
  defaultValue,
  placeholder,
  isDisabled = false,
}) => (
  <Controller
    autocomplete={autocomplete}
    as={PhoneInput}
    name={name}
    control={control}
    className={errors[name] ? "form-control is-invalid" : "form-control"}
    rules={validations}
    countrySelectProps={{ unicodeFlags: true }}
    defaultValue={defaultValue}
    placeholder={placeholder}
    disabled={isDisabled}
  />
)

export default PhoneField
