import { action, computed } from "easy-peasy"
import { byCode } from "../../utils/sort"
import { SERVICE_MODES, SERVICE_TYPES } from "../../models/service"

export default {
  items: [],
  mode: SERVICE_MODES.SYNCHRONIZATION,
  serviceType: SERVICE_TYPES.PRO,

  // computed
  areAllChecked: computed(state => state.items.every(item => item.isChecked)),
  checkedItems: computed(state => state.items.filter(item => item.isChecked)),
  isServiceTypePro: computed(state => state.serviceType === SERVICE_TYPES.PRO),
  isServiceTypeAI: computed(
    state => state.serviceType === SERVICE_TYPES.LOCALE_AI
  ),
  isServiceTypeConnectorCloud: computed(
    state => state.serviceType === SERVICE_TYPES.CONNECTOR_CLOUD
  ),
  isProofreadingMode: computed(
    state => state.mode === SERVICE_MODES.PROOFREADING
  ),
  isSynchronizationMode: computed(
    state => state.mode === SERVICE_MODES.SYNCHRONIZATION
  ),
  words: computed(state => {
    return state.checkedItems.reduce((acc, item) => (acc += item.words), 0)
  }),

  // actions
  checkAllItems: action(state => {
    state.items = state.items.map(item => {
      item.isChecked = true
      return item
    })
  }),

  checkItem: action((state, { id }) => {
    state.items = state.items.map(item => {
      if (item.id === id) item.isChecked = true
      return item
    })
  }),

  setItems: action((state, project) => {
    const targets = state.isSynchronizationMode
      ? project.translatableLocales
      : project.targetLocales
    state.items = targets.sort(byCode).map(target => {
      const { id, code, locale, missingWords, words } = target
      return {
        id,
        code,
        locale,
        words: state.isSynchronizationMode ? missingWords : project.sourceLocale.words,
      }
    })
  }),

  setMode: action((state, mode) => {
    state.mode = mode
  }),

  setServiceType: action((state, serviceType) => {
    state.serviceType = serviceType
  }),

  uncheckAllItems: action(state => {
    state.items = state.items.map(item => {
      item.isChecked = false
      return item
    })
  }),

  uncheckItem: action((state, { id }) => {
    state.items = state.items.map(item => {
      if (item.id === id) item.isChecked = false
      return item
    })
  }),
}
