import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { CONNECTORS } from "../store/models/connectors"

const NO_COLLECTIONS = "NoCollections"

export const isContentNamespace = (connector, item) => {
  if (connector?.startsWith(CONNECTORS.intercom))
    return item.providerType !== "article"
  else
    return !item.parentId && !item.translatable
}

export const filterContentByConnector = connector => item => !isContentNamespace(connector, item)

export const formatNamespaceTitle = (namespace) => {
  if (namespace === NO_COLLECTIONS) {
    return (
      <FormattedMessage
        id="helpers.contentResource.noCollection"
        defaultMessage="No Collection"
      />
    )
  }

  return namespace
}

export const isGroupEnabled = (resourcesExcluded, group, parentExcluded = false) =>
  !parentExcluded && !resourcesExcluded?.find(r => r === group.id || r === group.parentId)

export const groupByNamespace = (items) => {
  const nest = (items, id = null) =>
    items
      .filter(item => item.parentId === id)
      .map(item => ({
        ...item,
        children: nest(items, item.id).sort((a, b) => {
          if (a.children.length > 0) return 1
          if (b.children.length > 0) return -1

          return a.title.localeCompare(b.title, undefined, {
            numeric: true,
          })
        }),
      }))

  const groupByNamespace = nest(items).filter(i => i.children.length > 0)

  return groupByNamespace
}

export const getResourceTypeForConnector = (intl, connector, count) => {
  switch (connector) {
    case CONNECTORS.intercom:
      return intl.formatMessage(
        {
          id: "helpers.contentResource.article",
          defaultMessage: "{count, plural, one {article} other {articles}}",
        },
        { count }
      )
    default:
      return intl.formatMessage(
        {
          id: "helpers.contentResource.item",
          defaultMessage: "{count, plural, one {item} other {items}}",
        },
        { count }
      )
  }
}

export const getEnabledTotal = (items, resourcesExcluded) =>
  getTotal(items, item => isGroupEnabled(resourcesExcluded, item))

export const getTotal = (items, filter) => {
  const computed = (groupedResources, sum = 0) => {
    return groupedResources.reduce((sum, group) => {
      const nonParents = group.children.filter(c => c.children.length === 0)

      if ((filter && filter(group)) || !filter)
        return computed(group.children, sum + nonParents.length)

      return sum
    }, sum)
  }

  return computed(items)
}
