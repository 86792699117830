import React, { useEffect } from "react"
import Form from "../../forms/Form"
import ControlFooter from "./ControlFooter"
import { CONNECTORS } from "../../../store/models/connectors"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStoreActions, useStoreState } from "easy-peasy"
import { useForm, useWatch } from "react-hook-form"
import { mailchimpCampaignFoldersField } from "./commonFields"

const MailchimpCampaignFolders = () => {
    const currentOrg = useStoreState(state => state.currentOrg.item)
    const resources = useStoreState(state => state.mailchimp.items);
    const { errors, control, handleSubmit } = useForm({
        mode: "onChange",
    })
    const fetchMailchimpResources = useStoreActions(actions => actions.mailchimp.fetch)
    const setItems = useStoreActions(actions => actions.mailchimp.setItems)
    const isLoading = useStoreState(state => state.mailchimp.isLoading);
    const setFormField = useStoreActions(actions => actions.projectWizard.setFormField)
    let formValues = useStoreState(state => state.projectWizard.formValues)
    const providerIDFragments = formValues.project.providerID.split("#")
    const accountName =  formValues.project.name.split("/")[0];
    const onSubmit = values => {
        const { project: { folder: { id, name } } } = values
        const providerID = `${providerIDFragments[0]}#${providerIDFragments[1]}#${id}`
        formValues.project.connector.url = "https://admin.mailchimp.com";
        formValues.project.providerID = providerID;
        formValues.project.name = name
        setFormField({
            project: formValues.project,
            type: CONNECTORS.mailchimp
        })
    }
    const campaingFolders = useWatch({
        control,
        name: "project[folder]",
    })
    useEffect(() => {
        setItems([])
        if (providerIDFragments)
            fetchMailchimpResources({ organizationID: currentOrg.id, accountId: providerIDFragments[1] })
    },
        [campaingFolders]
    )
    const fields = mailchimpCampaignFoldersField(resources, isLoading);
    return (
        <Form
            fields={fields}
            errors={errors}
            control={control}
            className="project-form"
        >

           {resources.length === 0 && !isLoading && <p className="text-gray-500 hidden">
                <FormattedMessage
                    id="project.mailchimp.campaignfolders.notfound"
                    defaultMessage="No campaign folder found. Please create in your {accountName} mailchimp account."
                    values={{ accountName }}
                />
            </p>}

            <ControlFooter
                errors={errors}
                withBack
                handleSubmit={handleSubmit}
                onNext={onSubmit}
            />
        </Form>
    )
}

export default MailchimpCampaignFolders
