import { action, persist, computed, thunk } from "easy-peasy"
import { navigate } from "gatsby-plugin-intl"
import { shouldRedirectToSuccess } from "../../components/projects/wizard/Success"
import { redirect } from "../../config/routes"
import { WIZARD_ROOT } from "../../containers/projects/NewProject"
import { triggerSynchronization } from "../../helpers/connector"
import { triggerQuote } from "../../helpers/instances"
import ProjectCreator from "../../service/projectCreator"
import { AVAILABLE_FEATURES } from "./billing"
import {
  API_CONNECTORS,
  CONNECTORS,
  GIT_CONNECTORS,
  CONNECTOR_AUTO_UPDATE_FREQUENCIES,
  CONNECTOR_TYPES,
} from "./connectors"
import { SERVICE_TYPES } from "../../models/service.js"

const canTriggerQuote = project =>
  !shouldRedirectToSuccess(project)

export const WIZARD_ROUTES = {
  CONNECTOR_SELECT: { key: "project/new", path: "/" },
  PROJECT_TEMPLATE: {
    key: "project-template",
    path: (connector = ":connector") =>
      `/connector/${connector}/project-template`,
  },
  CONNECTOR: {
    key: "connector",
    path: (connector = ":connector") => `/connector/${connector}`,
  },
  FORMAT: {
    key: "format",
    path: (connector = ":connector") => `/connector/${connector}/format`,
  },
  LANGUAGE: {
    key: "language",
    path: (connector = ":connector") => `/connector/${connector}/language`,
  },
  SUCCESS: {
    key: "success",
    path: (projectId = ":projectId") => `/success/${projectId}`,
  },
  RESOURCE_TYPES: {
    key: "resource-types",
    path: (connector = ":connector") =>
      `/connector/${connector}/resource-types`,
  },
  MAILCHIMP_CAMPAIGN_FOLDERS: {
    key: "mailchimp-campaign-folders",
    path: (connector = ":connector") =>
      `/connector/${connector}/campaign-folders`,
  },
}

export const FORM_KEYS = {
  CONNECTOR_FIELDS: "CONNECTOR_FIELDS",
  FORMAT_FIELDS: "FORMAT_FIELDS",
  LANGUAGE_FIELDS: "LANGUAGE_FIELDS",
  PROJECT_TEMPLATE_FIELDS: "PROJECT_TEMPLATE_FIELDS",
}

export const GIT_WIZARD_STEPS = [
  WIZARD_ROUTES.CONNECTOR,
  WIZARD_ROUTES.FORMAT,
  WIZARD_ROUTES.LANGUAGE,
]

export const API_WIZARD_STEPS = [
  WIZARD_ROUTES.CONNECTOR,
  WIZARD_ROUTES.LANGUAGE,
]

export const WIZARD_LANGUAGE_STEP = [WIZARD_ROUTES.LANGUAGE]

export const WIZARD_CONNECTOR_STEP = [WIZARD_ROUTES.CONNECTOR]

export const DEFAULT_WIZARD_STEPS = [
  WIZARD_ROUTES.CONNECTOR,
  WIZARD_ROUTES.LANGUAGE,
]

export const CONNECTORS_LANGUAGE_ONLY = [
  CONNECTORS.figma,
  CONNECTORS.intercom,
  CONNECTORS.salesforceknowledge,
  CONNECTORS.shopify,
  CONNECTORS.wordpress,
  CONNECTORS.wpml,
  CONNECTORS.zapier,
  CONNECTORS.zendesk,
]

export const WITH_ACCOUNT_SELECTION_STEPS = [
  WIZARD_ROUTES.CONNECTOR,
  WIZARD_ROUTES.RESOURCE_TYPES,
  WIZARD_ROUTES.LANGUAGE,
]

export const DEFAULT_NO_ACCOUNT_SELECTION = [
  WIZARD_ROUTES.RESOURCE_TYPES,
  WIZARD_ROUTES.LANGUAGE,
]

export const CONNECTORS_WITH_ACCOUNT_SELECTION_STEP = [CONNECTORS.hubspotcms]
export const MAILCHIMP_WIZARD_STEP = [
  WIZARD_ROUTES.CONNECTOR,
  WIZARD_ROUTES.MAILCHIMP_CAMPAIGN_FOLDERS,
  WIZARD_ROUTES.LANGUAGE,
]

export const MAILCHIMP_WIZARD_SINGLE_INSTALLATION_STEP = [
  WIZARD_ROUTES.MAILCHIMP_CAMPAIGN_FOLDERS,
  WIZARD_ROUTES.LANGUAGE,
]

export const CONNECTORS_WITH_DOWNLOAD_AFTER_PROJECT_CREATION = [
  CONNECTORS.googleDocs,
  CONNECTORS.googleSheets,
]

export const CONNECTORS_WITH_SPACE_SELECTION_STEP = [CONNECTORS.contentful]

export const getStepsByConnector = (connector, fromCallback, storeState) => {
  const { currentOrg, billing } = storeState
  const {
    item: { connectors, integration },
    isPartner,
    isDedicatedMode,
  } = currentOrg || { item: {} }
  const productFamilyId = billing?.subscription?.details?.productFamilyId
  const configuration = integration?.integrationType?.configuration || {}

  const steps = (() => {
    if (CONNECTORS_LANGUAGE_ONLY.includes(connector))
      return WIZARD_LANGUAGE_STEP
    else if (connector === CONNECTORS.mailchimp) {
      if (connectors?.mailchimp?.length === 1 || fromCallback) {
        return MAILCHIMP_WIZARD_SINGLE_INSTALLATION_STEP
      }
      return MAILCHIMP_WIZARD_STEP
    } else if (GIT_CONNECTORS.includes(connector)) return GIT_WIZARD_STEPS
    else if (CONNECTORS_WITH_ACCOUNT_SELECTION_STEP.includes(connector)) {
      if (fromCallback || connectors?.hubspotcms?.length === 1) {
        return DEFAULT_NO_ACCOUNT_SELECTION
      }
      return WITH_ACCOUNT_SELECTION_STEPS
    } else if (API_CONNECTORS.includes(connector)) return API_WIZARD_STEPS
    else if (CONNECTORS_WITH_SPACE_SELECTION_STEP.includes(connector)) {
      if (connectors?.contentful?.length > 1 || connectors?.contentful?.[0]?.environmentIDs > 1) return DEFAULT_WIZARD_STEPS
      return WIZARD_LANGUAGE_STEP
    }
    else return DEFAULT_WIZARD_STEPS
  })()
  return (isDedicatedMode && configuration.hasIntegrationTemplate) ||
    (!isPartner &&
      SERVICE_TYPES.CONNECTOR_CLOUD === productFamilyId?.toLowerCase())
    ? [...steps, WIZARD_ROUTES.PROJECT_TEMPLATE]
    : steps
}

export default persist({
  formValues: null,
  selectedConnector: null,
  isCompleted: false,
  stepIdx: 0,
  lastStepIdx: -1,
  fromCallback: false,
  ignoredStepKey: null,

  resetProjectWizard: action((state, { connector, fromCallback }) => {
    state.formValues = null
    state.selectedConnector = connector
    state.isCompleted = false
    state.stepIdx = 0
    state.lastStepIdx = -1
    state.fromCallback = fromCallback || false
    state.ignoredStepKey = null
  }),

  currentStep: computed(
    state => state.steps?.[state.stepIdx]?.path(state.selectedConnector) || ""
  ),

  totalSteps: computed(state => state.steps.length),

  isLastStep: computed(state => state.stepIdx === state.steps.length - 1),

  nextPath: computed(
    state =>
      state.steps?.[state.stepIdx + 1]?.path(state.selectedConnector) || ""
  ),
  previousPath: computed(
    state =>
      state.steps?.[
        state.stepIdx -
          (state.ignoredStepKey !== state.steps?.[state.stepIdx - 1]?.key
            ? 1
            : 2)
      ]?.path(state.selectedConnector) || ""
  ),

  nextStep: action(state => {
    state.lastStepIdx = state.stepIdx
    state.stepIdx++
  }),
  previousStep: action(state => {
    state.lastStepIdx = state.stepIdx
    state.stepIdx--
  }),

  steps: computed(
    [
      state => state,
      (_, storeState) => ({
        currentOrg: storeState.currentOrg,
        billing: storeState.billing,
      }),
    ],
    (state, storeState) => {
      if (!state.selectedConnector) return []
      return getStepsByConnector(
        state.selectedConnector,
        state.fromCallback,
        storeState
      )
    }
  ),

  setFormField: action((state, payload) => {
    state.formValues = payload
  }),

  setIgnoredStepKey: action((state, key) => {
    state.ignoredStepKey = key
  }),

  resetForm: action((state, payload) => {
    state.selectedConnector = null
    state.stepIdx = -1
    state.lastStepIdx = -1
    state.formValues = null
    state.isCompleted = payload?.isCompleted || false
    state.fromCallback = false
    state.ignoredStepKey = null
  }),

  submitForm: thunk(async (actions, input, helpers) => {
    const formValues = helpers.getState().formValues
    const fetchEntitlements = helpers.getStoreActions().billing
      .fetchEntitlements
    const { isPartner, item: currentOrg } = helpers.getStoreState().currentOrg
    const { values, onSuccess } = input
    await fetchEntitlements(currentOrg.id)
    const features = helpers.getStoreState().billing.features
    const autoUpdateModes =
      formValues?.project?.connector?.autoUpdateModes || []
    const isActive =
      autoUpdateModes.length > 0 &&
      (isPartner || features[AVAILABLE_FEATURES.autoUpdate])
    let autoUpdateFrequency
    if (autoUpdateModes.length > 0) {
      autoUpdateFrequency =
        autoUpdateModes.indexOf("realtime") > -1
          ? CONNECTOR_AUTO_UPDATE_FREQUENCIES["realtime"][0].id
          : CONNECTOR_AUTO_UPDATE_FREQUENCIES["schedule"][0].id
    }

    const project = {
      ...formValues?.project,
      ...values.project,
      isActive,
      autoUpdateFrequency,
      vendorID: currentOrg.vendorID,
    }

    const projectID = await ProjectCreator.call({ project })

    if (projectID) {
      onSuccess()
      if (
        CONNECTORS_WITH_DOWNLOAD_AFTER_PROJECT_CREATION.includes(
          project?.connector?.id
        )
      ) {
        triggerSynchronization({
          connectorId: project?.connector?.id,
          organizationId: project?.organizationID,
          providerId: project?.providerID,
        })
      } else if (canTriggerQuote(project)) {
        triggerQuote({ projectID })
      }
      if (shouldRedirectToSuccess(project)) {
        actions.resetForm({ isCompleted: true })
        navigate(`${WIZARD_ROOT}${WIZARD_ROUTES.SUCCESS.path(projectID)}`)
      } else {
        redirect("PROJECT", { id: projectID }, { state: { resetForm: true } })
      }
    }
  }),
})
