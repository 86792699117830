export const FREQUENCY = {
  MONTHLY: "month",
  YEARLY: "year",
}

export const CURRENCY = {
  EUR: "EUR",
  GBP: "GBP",
  USD: "USD",
}

export const VOLUME_ENTITLEMENT = "target-words"

export const getPlanVolume = plan =>
  +(
    (plan?.features || []).find(
      ({ feature_id }) => feature_id === VOLUME_ENTITLEMENT
    )?.value || 0
  )
