import React from "react"
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import Icon from "../Icon"
import { ErrorMessage } from "@hookform/error-message"
import InputField from "./InputField"
import TextArea from "./TextArea"
import SelectField from "./SelectField"
import CopyToClip from "./CopyToClip"
import { FormattedMessage } from "gatsby-plugin-intl"
import CheckGroup from "./CheckGroup"
import PhoneField from "./Phone"

const Field = ({
  autocomplete,
  childRef,
  description,
  moreInfo,
  errors,
  label,
  multi,
  name,
  options,
  type,
  control,
  validations,
  defaultValue,
  defaultInputValue,
  plaintext,
  readOnly,
  link,
  placeholder,
  helperText,
  prependInputGroupText,
  appendInputGroupText,
  isLoading,
  isClearable,
  isDisabled,
  customComponent,
  containerClassname,
  href,
  customClass = "",
  rows,
}) => {
  const showAsterisk =
    validations?.required && !(readOnly || plaintext || type === "copyToClip")

  return (
    <Form.Group className={customClass}>
      <div className={`d-flex align-items-baseline`}>
        <Form.Label>
          {label} {showAsterisk && <span className="text-danger">*</span>}
        </Form.Label>
        {(description || moreInfo) && (
          <div className="ml-2 d-flex align-items-center">
            <OverlayTrigger
              placement="right"
              delay={{ show: 400, hide: 1000 }}
              overlay={props => (
                <Tooltip {...props}>
                  <button className="btn--transparent">
                    <p className="text-white m-0 text-left">
                      {description && `${description}` + " "}
                      {moreInfo && (
                        <a
                          className="link align-text-bottom"
                          href={moreInfo}
                        >
                          <FormattedMessage
                            id="components.forms.field.moreInfo"
                            defaultMessage="More Information"
                          />
                        </a>
                      )}
                    </p>
                  </button>
                </Tooltip>
              )}
            >
              <button className="btn--transparent" onClick={e => e.preventDefault()}>
                <Icon className="text-gray-500" name="help-circle small" />
              </button>
            </OverlayTrigger>
          </div>
        )}
      </div>

      {["text", "password"].includes(type) && (
        <InputField
          {...{
            autocomplete,
            name,
            type,
            control,
            errors,
            validations,
            defaultValue,
            placeholder,
            plaintext,
            readOnly,
            isDisabled,
            prependInputGroupText,
            appendInputGroupText,
          }}
        />
      )}

      {type === "textarea" && (
        <TextArea
          {...{
            name,
            type,
            control,
            errors,
            validations,
            defaultValue,
            placeholder,
            plaintext,
            readOnly,
            isDisabled,
            rows,
          }}
        />
      )}


      {"radio" === type && (
        <CheckGroup
          {...{
            name,
            type,
            control,
            errors,
            validations,
            defaultValue,
            isDisabled,
            options,
            containerClassname,
            customComponent
          }}
        />
      )}
      {type === "copyToClip" && (
        <CopyToClip
          {...{
            name,
            type,
            control,
            errors,
            validations,
            defaultValue,
            placeholder,
            plaintext,
            readOnly,
          }}
        />
      )}
      {type === "select" && (
        <SelectField
          {...{
            name,
            options,
            control,
            errors,
            multi,
            validations,
            defaultInputValue,
            defaultValue,
            placeholder,
            isClearable,
            isDisabled,
            isLoading,
          }}
        />
      )}
      {type === "phone" && (
        <PhoneField
          {...{
            autocomplete,
            name,
            control,
            errors,
            validations,
            defaultValue,
            placeholder,
            isDisabled,
          }}
        />
      )}
      {type === "button" && (
        <Button href={href}>{name}</Button>
      )}
      <p className="m-0">{helperText}</p>
      <div className="mt-2">{link}</div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Form.Text className="text-danger">{message}</Form.Text>
        )}
      />
    </Form.Group>
  )
}

export default Field
