import React from "react"
import { Toast as BootstrapToast } from "react-bootstrap"
import { variantClassName } from "../helpers/application"

const Toast = ({ children, delay, show, setShow, type, autohide = true }) => {
  return (
    <BootstrapToast
      onClose={() => setShow(false)}
      show={show}
      delay={delay || 12000}
      autohide={autohide}
      className={`align-end m-0 ${variantClassName(type)} px-3_5 py-2`}
    >
      {children}
    </BootstrapToast>
  )
}

export default Toast
