import React, { useEffect, useState } from "react"
import { useFlags } from "gatsby-plugin-launchdarkly"
import { useStoreActions, useStoreState } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import Connector from "../../components/connectors/Connector"
import Toast from "../../components/Toast"
import { connectorsRanked } from "../../helpers/connector"
import { Col, Row } from "react-bootstrap"
import { byName } from "../../utils/sort"

const ConnectorList = ({ renderConnector }) => {
  const ldFlags = useFlags();
  const userID = useStoreState(state => state.currentUser.item.id)

  const fetchAccessRequests = useStoreActions(
    actions => actions.accessRequests.fetch
  )

  const connectorsByCategory = useStoreState(state => state.connectors.byCategory({ ldFlags }))

  const connectors = connectorsRanked(connectorsByCategory)

  const listConnectorByStatus = (connectors, status) =>
    connectors.filter(connector => status.includes(connector.status))

  const reorderedConnectors = (connectors) => {
    if (connectors.length === 0 || !connectors) return
    connectors.forEach(connector => {
      const publicConnectors = listConnectorByStatus(connector[1], [ "public", ])
      const previewConnectors = listConnectorByStatus(connector[1], [ "preview" ])
      const betaConnectors = listConnectorByStatus(connector[1], [ "beta" ])
      const privateConnectors = listConnectorByStatus(connector[1], [ "private" ])
      connector[1] = [
        ...(publicConnectors.sort(byName)),
        ...(previewConnectors.sort(byName)),
        ...(betaConnectors.sort(byName)),
        ...(privateConnectors.sort(byName)),
      ]
    })
  }
  reorderedConnectors(connectors)
  const [showToast, setShowToast] = useState(false)
  const [requested, setRequested] = useState()

  useEffect(() => {
    fetchAccessRequests(userID)
  }, [])

  const renderConnectorsRow = (category, connectors, i) => (
    <div id={category} key={i}>
      <div key={`category-${category}`} className="pl-0 d-flex">
        <h3
          className={`mb-0 font-bold text-gray-700 ${
            i > 0 ? "mt-6" : "mt-5"
          }`}
        >
          {category}
        </h3>
      </div>
      <div className="d-flex flex-wrap" key={`${category}-connector-row`}>
        {connectors.map(connector => (
          <div
            className="px-0 w-25 connector-card__container"
            key={`connector-${connector.id}`}
          >
            {renderConnector ? (
              renderConnector({ connector, setShowToast, setRequested })
            ) : (
              <Connector
                connector={connector}
                setShowToast={setShowToast}
                setRequested={setRequested}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  )

  const categoryLinks = (
    <div className="d-flex flex-column position-sticky-top-0">
      {connectors.map(([category], i) => (
        <a key={category} href={`#${category}`}>
          <h3 className="mb-0 font-bold text-gray-600 mt-5">{category}</h3>
        </a>
      ))}
    </div>
  )

  const connectorsContent = () => {
    return (
      <>
        <Toast show={showToast} setShow={setShowToast}>
          <p className="text-gray-50 font-normal mb-0">
            <FormattedMessage
              id="containers.settings.connectors.toast"
              defaultMessage="You're on the waiting list for {connector} ✌️."
              values={{ connector: requested }}
            />
          </p>
        </Toast>
        <Row>
          <Col md={2}>
            {categoryLinks}
          </Col>
          <Col>
            {connectors.map(([category, connectors], i) =>
              renderConnectorsRow(category, connectors, i)
            )}
          </Col>
        </Row>
      </>
    )
  }

  return connectorsContent()
}

export default ConnectorList
