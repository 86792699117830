import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { WIZARD_ROOT } from "../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../store/models/projectWizard"
import { stateGenerator } from "../../helpers/application"

const Google = ({ connector, name, redirectURI, scopes }) => {
  const connectorRoute = `${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(connector)}`
  const state = stateGenerator({
    redirectURL: connectorRoute
  });
  const oauthRedirectionLink = `${process.env.GOOGLE_OAUTH_URL}?state=${state}&client_id=${process.env.GOOGLE_CLIENT_ID}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(redirectURI)}&response_type=code&prompt=consent&access_type=offline`;
  return (
    <div className="mt-4">
      <h2>
        <FormattedMessage
          id="components.google.title"
          defaultMessage={`Connect your {name} with Locale`}
          values={{ name }}
        />
      </h2>
      <FormattedMessage
          id="components.google.description"
          defaultMessage={`Click the <b>Connect With Google</b> button below.
          You will be redirected to Google to authorize Locale on your Account.
          After accepting the permissions, you are brought back to Locale to continue setup.`}
          values={{ b: msg => <b>{msg}</b> }}
      />
      <div className="text-center mt-3">
        <a href={oauthRedirectionLink} class="customGPlusSignIn btn btn-sm">
          <span class="icon"></span>
          <span class="buttonText">Connect With Google</span>
        </a>
      </div>
    </div>
  )
}

export default Google
