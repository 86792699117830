import { action, thunk, computed } from "easy-peasy"
import { API, graphqlOperation } from "aws-amplify"
import {
  getProject,
  listProjectsByOrganizationID,
} from "../../graphql/locale-queries"
import { listProjects } from "../../graphql/custom-queries"
import Project from "../../models/project"
import {
  createProject,
  updateProject,
  deleteProject,
} from "../../graphql/locale-mutations"
import { redirect } from "../../config/routes"
import { byName } from "../../utils/sort"

export default {
  items: [],

  // computed
  byId: computed(state => id =>
    state.items.find(project => String(project.id) === String(id))
  ),

  listByOrganization: thunk(async (actions, organizationID) => {
    const { data: projects } = await API.graphql(
      graphqlOperation(listProjectsByOrganizationID, { organizationID })
    )
    actions.set(projects.listProjectsByOrganizationID.items)
    return projects.listProjectsByOrganizationID.items
  }),

  // thunks
  fetch: thunk(async (actions, id, helper) => {
    try {
      if (id) {
        const projectData = await API.graphql(
          graphqlOperation(getProject, { id })
        )

        const state = helper.getState()
        state.items.length === 0
          ? actions.push(projectData.data.getProject)
          : actions.updateProject(projectData.data.getProject)
        helper.getStoreActions().project.set(state.byId(id))
      } else {
        const projects = []
        const currentOrgId = helper.getStoreState().currentOrg.item.id

        let nextToken
        if (currentOrgId) {
          do {
            const { data } = await API.graphql(
              graphqlOperation(listProjects, {
                filter: {
                  organizationID: { eq: currentOrgId },
                },
                nextToken,
              })
            )
            nextToken = data.listProjects.nextToken
            projects.push(...data.listProjects.items)
          } while (nextToken)
        }
        actions.set(projects)
      }
    } catch (e) {
      console.error(e)
    }
  }),

  create: thunk(async (actions, input) => {
    try {
      const { data } = await API.graphql(
        graphqlOperation(createProject, { input })
      )
      actions.push(data.createProject)
      return data.createProject.id
    } catch (e) {
      console.error(e)
    }
  }),

  update: thunk(async (actions, payload) => {
    const input = { ...payload }
    actions.updateProject(payload)
    const { data } = await API.graphql(
      graphqlOperation(updateProject, { input })
    )
  }),

  delete: thunk(async (actions, projectId) => {
    const input = { id: projectId }
    try {
      await API.graphql(graphqlOperation(deleteProject, { input }))
      actions.removeAndRedirect(projectId)
    } catch (e) {
      console.error(e)
    }
  }),

  addResource: thunk(async (actions, payload, helpers) => {
    const state = helpers.getState()
    let { resources, projectID: id } = payload
    const project = state.byId(id)
    if (project.resources) {
      resources = project.resources.concat(resources)
    }
    const input = { id, resources }
    const { data } = await API.graphql(
      graphqlOperation(updateProject, { input })
    )
    actions.updateProject(data.updateProject)
  }),

  editResource: thunk(async (actions, payload, helpers) => {
    const state = helpers.getState()
    let { resources: editResources, projectID: id } = payload
    const project = state.byId(id)
    const resources = project.resources
    editResources.forEach((resource, index) => (resources[index] = resource))
    const input = { id, resources }
    const { data } = await API.graphql(
      graphqlOperation(updateProject, { input })
    )
    actions.updateProject(data.updateProject)
  }),

  removeResource: thunk(async (actions, payload, helpers) => {
    const state = helpers.getState()
    const project = state.byId(payload.projectID)

    project.resources.splice(payload.resourceIndex, 1)

    const input = {
      id: payload.projectID,
      resources: project.resources,
    }
    const { data } = await API.graphql(
      graphqlOperation(updateProject, { input })
    )

    actions.updateProject(data.updateProject)
  }),

  addResourcePatterns: thunk(async (actions, payload, helpers) => {
    const state = helpers.getState()
    const { resourcePatterns: newResourcePatterns, projectID: id } = payload
    const project = state.byId(id)
    const resourcePatterns = [
      ...(project.resourcePatterns || []),
      ...newResourcePatterns,
    ]
    const input = { id, resourcePatterns }
    const { data } = await API.graphql(
      graphqlOperation(updateProject, { input })
    )
    actions.updateProject(data.updateProject)
  }),

  editResourcePatterns: thunk(async (actions, payload, helpers) => {
    const state = helpers.getState()
    const { resourcePatterns: editResourcePatterns, projectID: id } = payload
    const project = state.byId(id)
    const resourcePatterns = project.resourcePatterns
    editResourcePatterns.forEach(
      (resource, index) => (resourcePatterns[index] = resource)
    )
    const input = { id, resourcePatterns }
    const { data } = await API.graphql(
      graphqlOperation(updateProject, { input })
    )
    actions.updateProject(data.updateProject)
  }),

  removeResourcePattern: thunk(async (actions, payload, helpers) => {
    const state = helpers.getState()
    const project = state.byId(payload.projectID)
    const { resourcePatterns } = project

    resourcePatterns.splice(payload.index, 1)
    const input = {
      id: payload.projectID,
      resourcePatterns,
    }
    const { data } = await API.graphql(
      graphqlOperation(updateProject, { input })
    )

    actions.updateProject(data.updateProject)
  }),

  // actions
  set: action((state, projects) => {
    state.items = projects
      .map(project => state.byId(project.id) || new Project(project))
      .sort(byName)
  }),

  push: action((state, project) => {
    state.items = state.items.concat([new Project(project)]).sort(byName)
  }),

  updateProjectLocale: action((state, updateProjectLocale) => {
    state.items = state.items
      .map(p =>
        p.id === updateProjectLocale.projectID
          ? new Project({
              ...p,
              locales: {
                items: p.locales.items.map(locale =>
                  locale.id === updateProjectLocale.id
                    ? {
                        ...locale,
                        ...updateProjectLocale,
                        audit: {
                          ...locale.audit,
                          ...updateProjectLocale.audit,
                        },
                      }
                    : locale
                ),
              },
            })
          : p
      )
      .sort(byName)
  }),

  updateProject: action((state, updatedProject) => {
    state.items = state.items
      .map(project =>
        project.id === updatedProject.id
          ? new Project({
              ...project,
              ...updatedProject,
              connector: { ...project.connector, ...updateProject.connector },
            })
          : project
      )
      .sort(byName)
  }),

  pushAndRedirect: action((state, project) => {
    state.items = state.items.concat([new Project(project)]).sort(byName)
    redirect("PROJECT", { id: project.id })
  }),

  removeAndRedirect: action((state, projectId) => {
    window.location = "/"
    // redirect("PROJECT", { id: projectId })
    // state.items = state.items.filter(item => item.id !== projectId)
  }),
}
