import API from "@aws-amplify/api"
import { action, computed, thunk } from "easy-peasy"
import { byPrice } from "../../utils/sort"
import { CURRENCY, FREQUENCY } from "../../models/billing"
import { SERVICE_TYPES } from "../../models/service"

export const FEATURE_TYPES = {
  SWITCH: "SWITCH",
  QUANTITY: "QUANTITY",
  RANGE: "RANGE",
  CUSTOM: "CUSTOM",
}

export const AVAILABLE_FEATURES = {
  autoUpdate: "auto-update",
  localeAI: "locale-ai"
}

export default {
  creditCards: [],
  customerDetails: {},
  familyPlansCurFreq: {},
  entitlements: [],
  plans: [],
  productFamilies: {},
  quotePrice: {},
  subscription: {},
  isQuoteCalculating: false,

  // computed
  hasNoCards: computed(state => state.creditCards.length === 0),
  hasNoSubscriptions: computed(
    state => !state.subscription?.details?.productFamilyId
  ),
  isSubscriptionAI: computed(
    state =>
      state.subscription?.details?.productFamilyId === SERVICE_TYPES.LOCALE_AI
  ),
  isSubscriptionConnectorCloud: computed(
    state =>
      state.subscription?.details?.productFamilyId ===
      SERVICE_TYPES.CONNECTOR_CLOUD
  ),
  subscriptionServiceType: computed(
    state => (Object.values(SERVICE_TYPES)).find( item => item === state.subscription?.details?.productFamilyId)
  ),
  isAllPlansLoaded: computed(state => familyId => Object.values(state.familyPlansCurFreq?.[familyId] || {}).length > 0),

  // thunks
  addCreditCard: thunk(async (_actions, { organizationId, tokenId }) => {
    try {
      return API.post("nepal", "/billing/credit-cards", {
        body: {
          organizationId,
          tokenId,
        },
      })
    } catch (err) {
      return false
    }
  }),

  createCustomer: thunk(async (_actions, { organizationId }, helpers) => {
    try {
      await API.post("nepal", "/billing/customer", { body: { organizationId } })
      helpers.getStoreActions().currentOrg.fetch(organizationId)
      return true
    } catch (err) {
      return false
    }
  }),

  createSubscription: thunk(
    async (
      _actions,
      { organizationId, subscriptionItems, paymentSourceID }
    ) => {
      try {
        return API.post("nepal", "/billing/create-subscription", {
          body: {
            organizationId,
            subscriptionItems,
            paymentSourceID,
          },
        })
      } catch (err) {
        return false
      }
    }
  ),

  cancelSubscription: thunk(async (_actions, { organizationId }) => (
    API.del("nepal", "/billing/subscription", {
      queryStringParameters: {
        organizationId,
      },
    })
  )),

  calculateQuotePrice: thunk(
    async (actions, { organizationId, words, currency }) => {
      try {
        actions.setIsQuoteCalculating(true);
        const quote = await API.get("nepal", "/billing/quote", {
          queryStringParameters: {
            currency,
            words,
            organizationId,
          },
        })
        actions.setQuotePrice(quote)
        actions.setIsQuoteCalculating(false);
      } catch (err) {
        console.error(err)
      }
    }
  ),

  features: computed(state =>
    state.entitlements.reduce((acc, { feature_id, value, feature_type }) => {
      acc[feature_id] =
        feature_type === FEATURE_TYPES.SWITCH ? value === "true" : value
      return acc
    }, {})
  ),

  fetchCustomer: thunk(async (actions, { organizationId }) => {
    try {
      const data = await API.get("nepal", "/billing/customer", {
        queryStringParameters: {
          organizationId,
        },
      })
      actions.setCustomerDetails(data)
    } catch (err) {
      console.error(err)
    }
  }),

  fetchCreditCards: thunk(async (actions, { organizationId }) => {
    try {
      const data = await API.get("nepal", "/billing/credit-cards", {
        queryStringParameters: {
          organizationId,
        },
      })
      actions.setCreditCards(data)
    } catch (err) {
      console.error(err)
    }
  }),

  fetchPlans: thunk(async (actions, { currency, familyId, frequency }) => {
    const data = await API.get("nepal", "/chargebee/plans", {
      queryStringParameters: {
        currency,
        familyId,
        frequency,
      },
    })
    actions.setPlans(data)
    return data
  }),

  fetchAllFamilyPlans: thunk(async (actions, { familyId }) => {
    let plans = await Promise.all(
      Object.values(CURRENCY).map(currency =>
        Promise.all(
          Object.values(FREQUENCY).map(frequency => {
            return API.get("nepal", "/chargebee/plans", {
              queryStringParameters: {
                currency,
                familyId,
                frequency,
              },
            })
          })
        )
      )
    )
    plans = plans.flatMap(currencies =>
      currencies.flatMap(frequencies => frequencies)
    )
    actions.setFamilyPlansCurFreq({ familyId, plans })
  }),

  fetchSubscription: thunk(async (actions, { organizationId }) => {
    const data = await API.get("nepal", "/billing/subscription", {
      queryStringParameters: {
        organizationId,
      },
    })
    actions.setSubscription(data || {})
  }),

  fetchEntitlements: thunk(async (actions, organizationId) => {
    const data = await API.get("nepal", "/chargebee/entitlements", {
      queryStringParameters: {
        organizationId,
      },
    })

    actions.setEntitlements(data || [])
  }),

  fetchAllProductFamilies: thunk(async (actions, { organizationId }) => {
    const data = await API.get("nepal", "/billing/product-families", {
      queryStringParameters: {
        organizationId,
      },
    })
    actions.setProductFamilies(data || {})
  }),

  setEntitlements: action((state, entitlements) => {
    state.entitlements = entitlements
  }),

  setPlans: action((state, plans) => {
    state.plans = plans.sort(byPrice)
  }),

  setFamilyPlansCurFreq: action((state, { familyId, plans }) => {
    const newPlans = plans.reduce((acc, plan) => {
      const { currency_code, period_unit } = plan
      acc[currency_code] = acc[currency_code] || {}
      acc[currency_code][period_unit] = acc[currency_code][period_unit] || []
      const index = acc[currency_code][period_unit].findIndex(
        ({ id }) => id === plan.id
      )
      index === -1
        ? acc[currency_code][period_unit].push(plan)
        : (acc[currency_code][period_unit][index] = plan)
      acc[currency_code][period_unit] = acc[currency_code][period_unit].sort(
        byPrice
      )
      return acc
    }, state.familyPlansCurFreq[familyId] || {})
    state.familyPlansCurFreq = {
      ...state.familyPlansCurFreq,
      [familyId]: newPlans,
    }
  }),

  setProductFamilies: action((state, productFamilies) => {
    state.productFamilies = productFamilies
  }),

  setQuotePrice: action((state, quote) => {
    state.quotePrice = quote
  }),

  setCustomerDetails: action((state, customerDetails) => {
    state.customerDetails = customerDetails
  }),

  setIsQuoteCalculating: action((state, isCalculating) => {
    state.isQuoteCalculating = isCalculating
  }),

  setCreditCards: action((state, creditCards) => {
    state.creditCards = creditCards
  }),

  setSubscription: action((state, data) => {
    state.subscription = {
      details: data?.details || {},
      credits: data?.credits ||
      {
        limit: 0,
        usage: 0,
        remaining: 0,
      }
    }
  }),
}
