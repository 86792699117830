import React from "react"
import { Controller } from "react-hook-form"
import { Form } from "react-bootstrap"

const CheckGroup = ({
  name,
  type,
  control,
  errors,
  validations,
  isDisabled = false,
  defaultValue,
  options,
  containerClassname,
  customComponent
}) => (
  <div className={containerClassname || "d-flex flex-column ml-4"}>
    {options.map(option => (
      <Controller
        key={option.value}
        name={name}
        value={option.value}
        render={({ onChange, onBlur, ref, value }) =>
          customComponent ? (
            customComponent({
              defaultValue,
              name,
              onBlur,
              onChange,
              option,
              ref,
              value,
            })
          ) : (
            <div>
              <input
                ref={ref}
                id={option.value}
                className="form-check-input"
                type={type}
                name={name}
                onChange={e => onChange(option.value)}
                onBlur={onBlur}
                value={value}
                disabled={isDisabled}
              />
              <label className="form-check-label">{option.label}</label>
            </div>
          )
        }
        control={control}
        className={errors[name] ? "is-invalid" : ""}
        rules={validations}
        defaultValue={defaultValue}
      />
    ))}
  </div>
)

export default CheckGroup
