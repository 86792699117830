import { by } from "../utils/sort" ;
import { extractGivenName } from "../helpers/email";
export default class Team {
  #ownerID = null;
  #members = [];

  constructor (members, ownerID) {
    this.#members = members;
    this.#ownerID = ownerID;
  }

  get owner() {
    return this.#members.find((member) => member.sub === this.#ownerID);
  }

  get invitees () {
    return this.#members
      .filter((member) => member.sub !== this.#ownerID)
      .map((row) => {
        row.given_name = row.given_name || extractGivenName(row.email)
        return row;
      })
      .sort(by, "given_name");
  }

  get members () {
    return this.#members;
  }
}
