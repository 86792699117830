import API from "@aws-amplify/api";
import { byName } from "../../utils/sort"
import { action, thunk } from "easy-peasy";
import { INTEGRATION_IDS } from "../../models/integrationType";

export default {
  items: [],

  fetch: thunk(async (actions, integration) => {
    let data = [];
    switch (integration?.integrationTypeID) {
      case INTEGRATION_IDS.protemos:
        data = await actions.fetchProtemosClients(integration)
        data = data.sort(byName)
        break;
      case INTEGRATION_IDS.plunet:
        data = await actions.fetchPlunetCustomers(integration)
        data = data.sort(byName)
        break;
      default:
        data = [];
    }
    actions.set(data)
    return data
  }),

  fetchProtemosClients: thunk((_, integration) => (
    API.get("nepal", `/protemos/${integration.id}/clients`)
  )),

  fetchPlunetCustomers: thunk((_, integration) => (
    API.get("nepal", `/plunet/${integration.id}/customers`)
  )),

  set: action((state, payload) => {
    state.items = payload
  })
}
