import React, { useEffect, useState } from "react"
import Form from "../../forms/Form"
import ControlFooter from "./ControlFooter"
import { useForm, useWatch } from "react-hook-form"
import { fieldRequired } from "../../../utils/validations"
import { useStoreActions, useStoreState } from "easy-peasy"
import { CONNECTORS } from "../../../store/models/connectors"

const ContentfulSpaceForm = () => {
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [environmentIDOptions, setEnvironmentIDOptions] = useState([])
  const {
    connectors: { contentful: contentfulInstallations },
  } = currentOrg

  const spaceIDOptions = contentfulInstallations.map(installation => ({
    name: installation.spaceID,
    value: installation.spaceID,
  }))

  const { errors, control, handleSubmit, setValue } = useForm({
    mode: "onChange",
  })

  const setFormField = useStoreActions(
    actions => actions.projectWizard.setFormField
  )
  let formValues = useStoreState(state => state.projectWizard.formValues)
  const onSubmit = values => {
    const {
      spaceID: { value: spaceIDValue },
      environmentID: { value: environmentIDValue },
    } = values
    const providerID = `${CONNECTORS.contentful}#${spaceIDValue}:${environmentIDValue}`
    formValues.project.providerID = providerID
    setFormField({
      project: formValues.project,
      type: CONNECTORS.contentful,
    })
  }

  const spaceID = useWatch({
    control,
    name: "spaceID",
  })

  useEffect(() => {
    if (spaceID) {
      const options = contentfulInstallations
        .find(
          ({ spaceID: installationSpaceID }) =>
            installationSpaceID === spaceID.value
        )
        .environmentIDs.map(item => ({ name: item, value: item }))
      setEnvironmentIDOptions(options)
      setValue("environmentID", options[0])
    }
  }, [spaceID])

  const fields = [
    {
      label: "Space ID",
      name: `spaceID`,
      type: "select",
      options: spaceIDOptions,
      validations: { ...fieldRequired },
    },
    {
      label: "Environment ID",
      name: `environmentID`,
      type: "select",
      options: environmentIDOptions,
      validations: { ...fieldRequired },
    },
  ]
  return (
    <Form
      fields={fields}
      errors={errors}
      control={control}
      className="project-form"
    >
      <ControlFooter
        errors={errors}
        withBack
        handleSubmit={handleSubmit}
        onNext={onSubmit}
      />
    </Form>
  )
}

export default ContentfulSpaceForm
