import React, { useState } from "react"
import { useStoreState } from "easy-peasy"
import { navigate } from "gatsby-plugin-intl"
import InfoModal from "../layout/modal/InfoModal"
import { Button } from "react-bootstrap"
import { CONNECTORS } from "../../store/models/connectors"
import ContentfulTokenForm from "../contentful/TokenForm"
import GitlabTokenForm from "../gitlab/GitlabTokenForm"
import AdobeCommerceTokenForm from "../adobeCommerce/AdobeCommerceTokenForm"
import ZendeskDomainForm from "../zendesk/DomainForm"
import AkeneoPimUrlForm from "../akeneo/PimUrlForm"
import CommerceToolsProjectForm from "../commercetools/ProjectForm"
import { STATUSES } from "../layout/FormContainer"
import { WIZARD_ROOT } from "../../containers/projects/NewProject"
import { WIZARD_ROUTES } from "../../store/models/projectWizard"
import Shopify from "../shopify/Shopify"
import Google from "../google/Google"
import Lokalise from "../lokalise/Lokalise"

const ConfigureModal = ({ createProject, connector, buttonText, isOpen, onExit }) => {
  const { id, name } = connector
  const orgConnectors = useStoreState(state => state.currentOrg.item.connectors)
  const currentOrg = useStoreState(state => state.currentOrg.item)
  const [status, setStatus] = useState()
  let component

  const onSuccess = async (options) => {
    if (currentOrg.isOnboarding) await currentOrg.onboarding.moveToNextStage()
    setStatus(STATUSES.success)

    navigate(`${WIZARD_ROOT}${WIZARD_ROUTES.CONNECTOR.path(id)}`, options)
  }

  switch (id) {
    case CONNECTORS.adobeCommerce:
      component = <AdobeCommerceTokenForm name={name} onSuccess={onSuccess} />
      break
    case CONNECTORS.akeneo:
      component = <AkeneoPimUrlForm name={name} />
      break
    case CONNECTORS.commercetools:
      component = <CommerceToolsProjectForm name={name} onSuccess={onSuccess}/>
      break
    case CONNECTORS.contentful:
      component = <ContentfulTokenForm name={name} />
      break
    case CONNECTORS.gitlab:
      component = <GitlabTokenForm name={name} onSuccess={onSuccess} />
      break
    case CONNECTORS.googleDocs:
      component = <Google name={name} connector={id} scopes={process.env.GOOGLEDOCS_SCOPES} redirectURI={process.env.GOOGLEDOCS_REDIRECT_URI} />
      break
    case CONNECTORS.googleSheets:
      component = <Google name={name} connector={id} scopes={process.env.GOOGLESHEETS_SCOPES} redirectURI={process.env.GOOGLESHEETS_REDIRECT_URI} />
      break
    case CONNECTORS.lokalise:
      component = <Lokalise name={name} connector={id} scopes={process.env.LOKALISE_SCOPES} redirectURI={process.env.LOKALISE_REDIRECT_URI} />
      break
    case CONNECTORS.shopify:
      component = <Shopify name={name} />
      break
    case CONNECTORS.zendesk:
      component = <ZendeskDomainForm name={name} connector={id} />
      break
    default:
      break
  }

  const Trigger = ({ onClick }) => (
    buttonText ? <Button
      onClick={onClick}
      className={`py-1 btn btn-block ${
        orgConnectors[id] && !createProject
          ? "btn-outline--secondary"
          : "btn--primary"
      }`}
    >
      {buttonText()}
    </Button> : <></>
  )

  return (
    <InfoModal
      Trigger={Trigger}
      title={name}
      status={status}
      setStatus={setStatus}
      isOpen={isOpen}
      onExit={onExit}
    >
      {component}
    </InfoModal>
  )
}

export default ConfigureModal
