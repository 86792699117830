import store from "../store/index"
import ProjectLocaleValidator from "./projectLocaleValidator"
import ProjectLocaleResourcesCreator from "./projectLocaleResourcesCreator"

export default class ProjectLocaleCreator {
  constructor(projectLocale) {
    this._projectLocale = projectLocale
  }

  static async call(args) {
    return new ProjectLocaleCreator(args).call()
  }

  async call() {
    try {
      if(await ProjectLocaleValidator.call(this.projectLocale())) return false
      const projectLocaleData = await this.createProjectLocale()
      if (!await ProjectLocaleResourcesCreator.call(projectLocaleData)) return false
      await this.fetchProject()
      return projectLocaleData
    } catch (err) {
      return null
    }
  }

  // PRIVATE METHODS
  createProjectLocale() {
    return store
      .getActions()
      .projectLocales
      .create(this.projectLocale())
  }

  fetchProject() {
    return store
      .getActions()
      .projects
      .fetch(this.projectID())
  }

  projectID() {
    return this.projectLocale().projectID
  }

  projectLocale() {
    return this._projectLocale
  }
}
