import store from "../store/index"
import ProjectResourceValidator from "./projectResourcesValidator"

export default class ProjectResourcesCreator {
  constructor(projectResources) {
    this._projectResources = projectResources
  }

  static async call(args) {
    return new ProjectResourcesCreator(args).call()
  }

  async call() {
    try {
      if (await ProjectResourceValidator.call(this.projectResources())) return false
      await this.createProjectResources()
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  createProjectResources() {
    return store
      .getActions()
      .projects
      .addResource(this.projectResources())
  }

  projectResources() {
    return this._projectResources
  }
}
