import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"

const Spinner = ({ message, isSmall, className }) => (
  <>
    <div
      className={`${className} spinner-border ${isSmall && "spinner-border-sm"}`}
      role="status"
    >
      <span className="sr-only">
        <FormattedMessage
          id="components.common.spinner.loading"
          defaultMessage="Loading..."
          description="Loading Spinner"
        />
      </span>
    </div>
    <span className="pl-2">{message}</span>
  </>
)

export default Spinner
