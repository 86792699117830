import { API } from "aws-amplify"
import {
  CONNECTORS,
  API_CONNECTORS,
  CONNECTOR_CATEGORIES,
  CONNECTOR_STATUSES,
  GIT_CONNECTORS,
} from "../store/models/connectors"
import { byRank } from "../utils/sort"

export const isGitConnector = project => {
  return GIT_CONNECTORS.includes(project.connector.type)
}

export const isApiConnector = project => {
  return API_CONNECTORS.includes(project.connector.type)
}

export const isLokaliseConnector = (project) => (CONNECTORS.lokalise === project.connector.type)

export const isDocumentTypeConnector = project => {
  return project?.connector?.mode === "document"
}

export const connectorsRanked = connectors => {
  const codeFirst = Object.entries(connectors).reduce(
    (acc, [category, connectors]) => {
      category.toLowerCase() === CONNECTOR_CATEGORIES.code.toLocaleLowerCase()
        ? acc.unshift([category, connectors])
        : acc.push([category, connectors])
      return acc
    },
    []
  )
  return codeFirst.length > 0
    ? [
        codeFirst[0],
        ...codeFirst
          .slice(1)
          .sort((a, b) => a[0].toLocaleLowerCase() > b[0].toLocaleLowerCase()),
      ]
    : []
}

export const connectorsByCategory = (connectors, ldFlags) => {
  ldFlags = ldFlags || {}
  const openStatues = [
    CONNECTOR_STATUSES.beta,
    CONNECTOR_STATUSES.preview,
    CONNECTOR_STATUSES.public,
  ]
  const connectorsFiltered = connectors.filter(connector => {
    if (
      ldFlags[`${connector.id}ConnectorAccess`] === true ||
      openStatues.includes(connector.status)
    )
      return true
    return false
  })
  const connectorsByCat = connectorsFiltered.reduce((acc, connector) => {
    acc[connector.category] = acc[connector.category] || []
    acc[connector.category].push(connector)
    return acc
  }, {})
  Object.values(connectorsByCat).forEach(connectors => connectors.sort(byRank))
  return connectorsByCat
}

export const generateRedirectUrl = (id) => {
  let url = ""
  switch (id) {
    case CONNECTORS.azurerepos:
      url = `https://app.vssps.visualstudio.com/oauth2/authorize/&client_id=${process.env.AZURE_CLIENT_ID}&scope=${encodeURIComponent(process.env.AZURE_SCOPES)}&redirect_uri=${encodeURIComponent(process.env.AZURE_REDIRECT_URI)}&response_type=Assertion`
      break
    case CONNECTORS.bitbucket:
      url = process.env.BITBUCKET_APP_URL
      break
    case CONNECTORS.dropbox:
      url = `https://www.dropbox.com/oauth2/authorize?client_id=${process.env.DROPBOX_CLIENT_ID}&token_access_type=offline&redirect_uri=${encodeURIComponent(process.env.DROPBOX_REDIRECT_URI)}&response_type=code`
      break
    case CONNECTORS.github:
      url = process.env.GITHUB_APP_URL
      break
    case CONNECTORS.hubspotcms:
      url = `${process.env.HUBSPOT_CMS_APP_URL}/authorize?&client_id=${process.env.HUBSPOT_CMS_CLIENT_ID}&scope=${encodeURIComponent(process.env.HUBSPOT_CMS_SCOPES)}&redirect_uri=${encodeURIComponent(process.env.HUBSPOT_CMS_REDIRECT_URI)}`
      break
    case CONNECTORS.intercom:
      url = process.env.INTERCOM_APP_URL
      break;
    case CONNECTORS.mailchimp:
      url = `https://login.mailchimp.com/oauth2/authorize?redirect_url=${process.env.MAILCHIMP_REDIRECT_URL}&client_id=${process.env.MAILCHIMP_CLIENT_ID}&response_type=code`
      break;
    case CONNECTORS.salesforceknowledge:
      url = `https://login.salesforce.com/services/oauth2/authorize?client_id=${process.env.SALESFORCE_CLIENT_ID}&redirect_uri=${process.env.SALESFORCE_REDIRECT_URL}&response_type=code`
        break;
  }

  return url
}

export const triggerSynchronization = ({
  connectorId,
  organizationId,
  providerId,
}) => {
  API.post("nepal", "/synchronization/trigger", {
    body: {
      connectorId,
      organizationId,
      providerId,
    },
  })
}
