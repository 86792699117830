import React from "react"
import { Card } from "react-bootstrap"
import Field from "./Field"
import InputField from "./InputField"

const Divider = () => <hr className="my-4" />

const FormFields = ({ fields, errors, control }) => {
  return (
    <>
      {fields.map((field, i) => {
        switch (field.type) {
          case "card":
            return (
              <Card key={`card-${i}`} className={`mb-0 border-0 shadow-none ${field?.customClass}`}>
                <Card.Body className="p-0">
                  <Card.Title as="h4">{field.title}</Card.Title>
                  <FormFields fields={field.fields} errors={errors} control={control} />
                </Card.Body>
              </Card>
            )
          case "divider":
            return <Divider key={`divider-${i}`} />
          case "hidden":
            return (
              <InputField
                {...field}
                errors={errors}
                key={`field-${field.name}`}
                control={control}
              />
            )
          default:
            return (
              <Field
                {...field}
                errors={errors}
                key={`field-${field.name}`}
                control={control}
                customClass={field?.customClass}
              />
            )
        }
      })}
    </>
  )
}

export default FormFields
