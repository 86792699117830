import { appendIndexToPath, formatPath } from "../helpers/format"
import store from "../store/index"
import ProjectResourcesUpdater from "./projectResourcesUpdater"

export default class ProjectLocaleResourcesCreator {
  constructor(projectLocale) {
    this._projectLocale = projectLocale
  }

  static async call(args) {
    return new ProjectLocaleResourcesCreator(args).call()
  }

  async call() {
    try {
      await ProjectResourcesUpdater.call({
        projectID: this.projectID(),
        resources: this.resources(),
      })
      return true
    } catch (err) {
      return false
    }
  }

  // PRIVATE METHODS
  projectID() {
    return this.projectLocale().projectID
  }

  projectLocale() {
    return this._projectLocale
  }

  projectResources() {
    return store.getState().projects.byId(this.projectID()).resources || []
  }

  format() {
    return store.getState().projects.byId(this.projectID()).format
  }

  resource(index) {
    let path = formatPath(this.format(), this.projectLocale())
    if (index > 0) path = appendIndexToPath(path, index)
    return {
      path,
      projectLocaleID: this.projectLocale().id,
    }
  }

  resources() {
    return this.projectResources().map((resources, index) => {
      resources.push(this.resource(index))
      return resources
    })
  }
}
