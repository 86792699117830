import React from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { FormattedMessage } from "gatsby-plugin-intl"
import { Button, Image } from "react-bootstrap"
import { connectorLogoURL } from "../../helpers/assets"
import { CONNECTORS, CONNECTOR_TYPES } from "../../store/models/connectors"
import getZapUrl from "../../helpers/zapUrl"


const BetaConnector = ({ connector, setShowToast, setRequested }) => {
  const { id, name, type, logo } = connector
  const organization = useStoreState(state => state.currentOrg.item)
  const currentUser = useStoreState(state => state.currentUser.item)
  const accessRequests = useStoreState(state => state.accessRequests.items)
  const createAccessRequest = useStoreActions(
    actions => actions.accessRequests.create
  )

  const requestAccess = async () => {
    const payload = {
      type: "connector",
      itemId: id,
      organizationID: organization.id,
      owner: organization.owner,
    }
    await createAccessRequest(payload)
    setRequested(name)
    setShowToast(true)
  }

  const renderButton = () => {
    const accessRequested = accessRequests.some(
      request => request.itemId === id
    )
    return (
      <>
        {type === CONNECTOR_TYPES.zapier ? (
          <Button
            href={getZapUrl(id)}
            className="btn btn-block py-1 btn-outline--secondary"
          >
            <FormattedMessage
              id="components.connectors.zap.button"
              defaultMessage="Go to Zap"
            />
          </Button>
        ) : accessRequested ? (
          <button
            className="btn btn-block bg-gray-100 text-gray-500 py-1"
            disabled
          >
            <FormattedMessage
              id="components.connectors.requestAccess.button.requested"
              defaultMessage="Access Requested"
            />
          </button>
        ) : (
          <button
            onClick={requestAccess}
            className="btn btn-block bg-gray-100 text-gray-700 py-1"
          >
            <FormattedMessage
              id="components.connectors.requestAccess.button"
              defaultMessage="Request Access"
            />
          </button>
        )}
      </>
    )
  }

  const isZap = type === CONNECTOR_TYPES.zapier
  const zapierIcon = connectorLogoURL(CONNECTORS.zapier)

  return (
    <div className="connector-card">
      <div
        className={`d-flex align-items-center mt-2 ${
          isZap ? "position-relative" : ""
        }`}
      >
        <Image
          className="connector-card--image"
          src={(logo) ? logo : connectorLogoURL()}
          onError={e => {
            if (e.target.src !== connectorLogoURL())
              e.target.src = connectorLogoURL()
          }}
        />
        {isZap && (
          <Image
            className="connector-card--zap rounded-circle"
            src={zapierIcon}
          />
        )}
        <div className="d-flex align-items-center justify-content-between mb-0 ml-3 font-bold text-gray-800">
          <h4 className="mr-2 mb-0">{name}</h4>
          {!isZap && (
            <span className="text-gray-500">
              (<FormattedMessage
                id="components.connectors.requestAccess.betaLabel"
                defaultMessage="(Beta)"
              />)
            </span>
          )}
        </div>
      </div>
      <div className="mt-3">{renderButton()}</div>
    </div>
  )
}

export default BetaConnector
