import { action, thunk } from "easy-peasy"
import { API } from "aws-amplify"
import Team from "../../models/team"

export default {
  item: null,
  loading: true,

  // thunks
  fetch: thunk(async (actions, input) => {
    try {
      const data = await API.get(
        "nepal", `/team/organizations/${input.organizationID}/members`,
      );
      actions.set(new Team(data, input.ownerID));
      return true;
    } catch (err) {
      console.error(err)
      return false;
    }
  }),

  create: thunk(async (_actions, input) => {
    try {
      await API.post(
        "nepal", `/team/organizations/${input.organizationID}/members`,
        { body: { ...input } }
      );
      return true;
    } catch (_err) {
      return false;
    }

  }),

  delete: thunk(async (actions, input) => {
    try {
      await API.del(
        "nepal", `/team/organizations/${input.organizationID}/members/${input.userId}`,
      );
      actions.remove(input.userId)
      return true;
    } catch (_err) {
      return false;
    }
  }),

  remove: action ((state, sub) => {
    state.item = state.item.filter((member) => {
      return member.sub !== sub;
    });
  }),

  // actions
  set: action((state, team) => {
    state.item = [team.owner, ...team.invitees].filter(Boolean);
    state.loading = false;
  })
}
